
const appConfig = {
    appId: 'wx43b17d910f5e25f4',
    proxyFly: {
        addresses: [
            '上海直辖市',
            '浙江省',
            '江苏省',
            '安徽省',
            '山东省',
            '河南省',
            '湖北省',
            '江西省',
            '福建省',
            '陕西省',
            '辽宁省',
            '河北省',
            '吉林省',
            '广东省',
            '天津直辖市',
            '重庆直辖市',
            '北京直辖市',
            '湖南省',
            '四川省',
            '海南省',
            '内蒙古自治区',
            '宁夏回族自治区',
        ]
    }
};

export default appConfig;




