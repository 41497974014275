
import React, { useEffect, useState } from 'react';

import {
    Form,
    AutoComplete,
    Row,
    Col,
    Input,
    Button,
    Table,
    Tag,
    Upload,
    Space,
    Breadcrumb,
    Menu,
    Modal,
    Select,
    message,
    Checkbox,
    Switch as ASwitch
} from 'antd';
import './index.less';
import Header from "../../components/Header";
import { HomeOutlined, PhoneOutlined, CloseOutlined, BlockOutlined, ExclamationCircleOutlined, CompassOutlined, PlusOutlined, ApartmentOutlined } from '@ant-design/icons';
import { usePageViews } from "../../App";
import { apiPartnerAdd, apiPartnerDel, apiPartnerList, apiPartnerUpdate } from "../../api/partner";
import { apiBidAdd, apiBidDel, apiBidDELETE, apiBidUpdate } from "../../api/bid";
import { Link } from "react-router-dom";
import { apiUserAdd, apiUserList, apiUserUpdate } from "../../api/user";
import {
    apiCustomerLatentBatch, apiCustomerLatentDelete,
    apiCustomerLatentDispatch,
    apiCustomerLatentList,
    apiCustomerList, apiLatentBatch, apiLatentBatchInfo
} from "../../api/customer";
import user from "../../store/reducers/user";
import { apiTagList } from "../../api/settings";
import { apiComboDel } from "../../api/combo";
// import {apiWorkerList} from "../../api";

const { Column, ColumnGroup } = Table;

const Option = Select.Option;

const CustomerList = () => {

    usePageViews();

    const [tableData, setTableData] = useState([]);

    const [expand, setExpand] = useState(false);

    const [form] = Form.useForm();

    const [formNew] = Form.useForm();

    const [isClear, setIsClear] = useState(false);

    const [editId, setEditId] = useState('');

    const [pageIndex, setPageIndex] = useState(1);

    const [total, setTotal] = useState(0);

    const [modalVisible, setModalVisible] = useState(false);
    const [clearToday, setClearToday] = useState(false);
    const [modalConfirmLoading, setModalConfirmLoading] = useState(false);

    const [fileList, setFileList] = useState([]);

    const [selectList, setSelectList] = useState([]);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [marketerList, setMarketerList] = useState([]);

    const [tagList, setTagList] = useState([]);

    const [selectPanelShow, setSelectPanelShow] = useState(false);

    const [options, setOptions] = useState([]);

    const [batchInfoShow, setBatchInfoShow] = useState(false);

    const [batchInfoList, setBatchInfoList] = useState([]);

    const [loading, setLoading] = useState(false);

    const onCheckChange = (e) => {
        // console.log(e.target.checked);

        setClearToday(e.target.checked);
    };

    useEffect(() => {

        // getCustomerLatentList({
        //     name: '',
        //     pageIndex: 1,
        // });

        // getMarketerList();

        // getTagList();

        // getOptions();

        argsInit();
    }, []);

    useEffect(() => {
        if (!selectList.length) {
            setSelectPanelShow(false);
        }
    }, [selectList]);

    useEffect(() => {

        if (!editId) {
            return;
        }

        const { customerName, realName, mobile } = tableData.find(item => item.customerId == editId);

        formNew.setFieldsValue({
            name: realName,
            alias: customerName,
            phone: mobile
        });

    }, [editId]);

    const argsInit = async () => {
        var clearTimer = message.loading("正在请求页面参数...", 0);

        try {
            const [res1, res2, res3] = await Promise.all([
                apiTagList({ code: 'phone' }),
                apiUserList({ roleName: 'sale', pageSize: 100 }),
                apiTagList({})
            ]);

            setOptions(res1.records);

            setMarketerList(res2.records.filter(item => item.enabled == '1'));

            setTagList(res3.records.filter(tag => tag.code === 'latent'));

        } catch (e) {

        }

        clearTimer && clearTimer();

    };

    // const getOptions= async () => {
    //     const res = await apiTagList({code:'phone'});

    //     console.log(res.records);

    //     setOptions(res.records);
    // };

    // const getMarketerList = async () => {
    //     const res = await apiUserList({roleName: 'sale', pageSize: 100});

    //     setMarketerList(res.records.filter(item => item.enabled == '1'));
    // };

    // const getTagList = async () => {
    //     const res = await apiTagList({});

    //     setTagList(res.records.filter(tag => tag.code === 'latent'));
    // };

    const uploadProps = {
        fileList,
        beforeUpload: file => {
            const reader = new FileReader();

            console.log(file);

            reader.onload = async function () {
                // 对标号信息进行校验

                let csv = this.result;

                console.log(csv);
                console.log(file.name);

                const hide = message.loading('正在导入', 0);

                await apiCustomerLatentBatch({ name: file.name, csv });

                message.success('导入成功');
                hide();
            }

            reader.readAsText(file);

            return true;
        },

        onChange: info => {
            // console.log(info.fileList);
            setFileList(info.fileList);
        },
    };

    const getCustomerLatentList = async (
        {
            pageIndex = 1,
            hasAssign,
            channel,
            resource,
            phoneCode,
            name,
            userId,
            tagStr,
            hasExchange,
        }
    ) => {

        if (loading) {
            message.error('请等待上一次请求的内容返回后再进行操作');

            return;
        }

        console.log(userId);
        setLoading(true);

        try {
            const res = await apiCustomerLatentList({
                pageIndex,
                hasAssign,
                channel,
                resource,
                phoneCode,
                name,
                userId,
                tagStr,
                hasExchange,
            });

            setPageIndex(res.pageIndex);
            setTotal(res.total);

            setTableData(res.records.map((item, index) => ({ ...item, key: index })));
        } catch (e) {

        }

        setLoading(false);

    };

    const onFinish = ({ hasAssign = '', channel = '', resource = '', phoneCode = '', name = '', hasExchange = '', userId = '', tagStr = '', }) => {
        // console.log(hasAssign, channel, resource, phoneCode, name);

        getCustomerLatentList({
            hasAssign: hasAssign == 'all' ? '' : hasAssign,
            channel: channel == 'all' ? '' : channel,
            resource: resource && resource.length ? resource.join(',') : '',
            phoneCode: phoneCode.trim(),
            name: name.trim(),
            pageIndex: 1,
            hasExchange: hasExchange == 'all' ? '' : hasExchange,
            userId: userId == 'all' ? '' : userId,
            tagStr: tagStr == 'all' ? '' : tagStr,
        });
    };

    const pageChange = (page) => {
        const hasAssign = form.getFieldValue('hasAssign');
        const channel = form.getFieldValue('channel');
        const resource = form.getFieldValue('resource');
        const phoneCode = form.getFieldValue('phoneCode') || '';
        const name = form.getFieldValue('name') || '';
        const userId = form.getFieldValue('userId') || '';
        const hasExchange = form.getFieldValue('hasExchange');
        const tagStr = form.getFieldValue('tagStr');

        getCustomerLatentList({
            hasAssign: hasAssign == 'all' ? '' : hasAssign,
            channel: channel == 'all' ? '' : channel,
            resource: resource && resource.length ? resource.join(',') : '',
            phoneCode: phoneCode.trim(),
            name: name.trim(),
            userId: userId == 'all' ? '' : userId,
            hasExchange: hasExchange == 'all' ? '' : hasExchange,
            tagStr: tagStr == 'all' ? '' : tagStr,
            pageIndex: page,
        });
    };

    const handleModalOk = async () => {
        try {
            const { name, alias, phone } = await formNew.validateFields();

            setModalConfirmLoading(true);

            if (editId) {
                await apiUserUpdate({
                    id: editId,
                    mobile: phone,
                    realName: name,
                    customerName: alias,
                });
            } else {
                await apiUserAdd({
                    mobile: phone,
                    realName: name,
                    customerName: alias,
                });
            }

            formNew.resetFields();
            setModalVisible(false);
            setModalConfirmLoading(false);

            message.success(`用户${editId ? '更新' : '添加'}成功`);

            setEditId(null);

            getCustomerLatentList({
                pageIndex: 1,
            });



        } catch (e) {
            message.error(e.message);
            setModalConfirmLoading(false);
        }
    };

    const handleModalCancel = () => {
        setEditId(null);
        setModalVisible(false);
        formNew.resetFields();
    };

    const handleBtnNewClick = () => {
        setModalVisible(true);
    };

    const handleEditBtnClick = (id) => async () => {
        console.log(id);

        // const res = await apiBidUpdate()
        //

        setEditId(id);

        setModalVisible(true);
    };

    const handleDispatch = (userId) => async (e) => {
        e.stopPropagation();
        console.log(selectList, userId);

        const res = await apiCustomerLatentDispatch({
            lcIds: selectList.map(item => item.lcId),
            userId,
        });

        message.success("分配成功");

        setSelectPanelShow(false);

        setSelectList([]);

        setSelectedRowKeys([]);

        pageChange(1);

    };

    const handleClear = async () => {
        Modal.confirm({
            title: '清除分配记录',
            icon: <ExclamationCircleOutlined />,
            content: '是否要清除选中的分配记录?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                await apiCustomerLatentDelete({
                    lcIds: selectList.map(item => item.lcId),
                });

                message.success("分配记录已清除");

                pageChange(pageIndex);

                setSelectList([]);

                setSelectedRowKeys([]);
            },
            // onCancel() {
            // },
        });
    };

    const getCodeListWithTime = async (csvText, page, totalPage) => {
        const hasAssign = form.getFieldValue('hasAssign');
        const channel = form.getFieldValue('channel');
        const resource = form.getFieldValue('resource');
        const phoneCode = form.getFieldValue('phoneCode') || '';
        const name = form.getFieldValue('name') || '';
        const userId = form.getFieldValue('userId');
        const hasExchange = form.getFieldValue('hasExchange');
        const tagStr = form.getFieldValue('tagStr');

        const res = await apiCustomerLatentList({
            hasAssign: hasAssign == 'all' ? '' : hasAssign,
            channel: channel == 'all' ? '' : channel,
            resource: resource && resource.length ? resource.join(',') : '',
            phoneCode: phoneCode.trim(),
            name: name.trim(),
            userId: userId == 'all' ? '' : userId,
            hasExchange: hasExchange == 'all' ? '' : hasExchange,
            tagStr: tagStr == 'all' ? '' : tagStr,
            pageIndex: page,
            pageSize: 200,
        });

        const text = csvText + '\n' + res.records.map(item => `${item.phoneCode},${item.createTime}`).join('\n');

        if (page === totalPage) {
            return text;
        } else {
            return await getCodeListWithTime(text, page + 1, totalPage);
        }
    };

    // 导出 csv
    const handleSaveCsv = () => {
        Modal.confirm({
            title: '导出编号表',
            icon: <ExclamationCircleOutlined />,
            content: '是否要导出编号表?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                const totalPage = Math.ceil(total / 200);

                const csvText = await getCodeListWithTime('', 1, totalPage);

                const blobText = new Blob([csvText], { type: 'text/plain;charset=utf-8' });

                const downLoadLink = document.createElement('a');

                const tagStr = form.getFieldValue('tagStr');

                console.log(tagStr);

                console.log(tagList);

                downLoadLink.download = tagStr === 'all' ? '电话编号与日期[全部].csv' : `电话编号与日期[${tagList.find(tag => tag.tagId === tagStr).name}].csv`;

                downLoadLink.href = window.URL.createObjectURL(blobText);

                document.body.appendChild(downLoadLink);

                downLoadLink.click();

                document.body.removeChild(downLoadLink);

            },
            // onCancel() {
            // },
        });
    };

    const handleGetBatchInfo = async () => {
        const res = await apiLatentBatchInfo();

        console.log(res);
        // num: 0, userId: '1328254671043174402', realName: '夏鹏飞'

        const _res = res.map(item => ({ ...item, needNum: null }));

        setClearToday(false);
        setBatchInfoList(_res);
    };

    const handleBatch = async () => {
        const _targetList = batchInfoList.filter(item => (item.needNum && (item.needNum !== '0'))).map(item => ({
            size: parseInt(item.needNum),
            userId: item.userId,
        }));

        if (_targetList.length) {
            console.log(_targetList);

            const res = await apiLatentBatch(clearToday, _targetList);

            console.log(res);
            message.success('分配成功');

            setBatchInfoList([]);

        } else {
            message.error('未设置分配数量');
        }
    };

    return <div className="page-customer-latent-list">
        <Header>
            <div className="flex items-center justify-between">
                <Breadcrumb>
                    <Breadcrumb.Item href="/#/">
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="">
                        <PhoneOutlined />
                        <span>潜在客户管理</span>
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div>

                    <Button onClick={handleGetBatchInfo} >
                        <BlockOutlined />
                        统一分配
                    </Button>

                    <ASwitch
                        checked={isClear}
                        onChange={(e) => {
                            setIsClear(e);
                        }}
                        style={{ marginRight: 15, marginLeft: 40 }}
                        checkedChildren="清理"
                        unCheckedChildren="分配"
                    />

                    <a
                        href="https://code-sprite.oss-cn-shanghai.aliyuncs.com/wx-app/contact-list.csv"
                        style={{ marginRight: 20 }}
                    >
                        <Button shape="round" >
                            <CompassOutlined />
                            示例下载
                        </Button>
                    </a>

                    <Upload
                        {...uploadProps}
                    >
                        <Button
                            type="primary"
                            // style={{margin: '0 15px'}}
                            shape="round"
                            icon={<PlusOutlined />}
                        >
                            导入潜在客户列表
                        </Button>
                    </Upload>
                </div>

            </div>

        </Header>

        <div className="content-panel-common">
            <div className="filter-pannel-common">
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>

                        <Col span={8}>
                            <Form.Item
                                name="hasAssign"
                                label="是否分配"
                                initialValue="all"
                            >
                                <Select>
                                    <Option value="all">全部</Option>
                                    <Option value={false}>未分配</Option>
                                    <Option value={true}>已分配</Option>
                                </Select>
                            </Form.Item>
                        </Col>


                        {/*<Col span={8}>*/}
                        {/*    <Form.Item*/}
                        {/*        name="hasAssign"*/}
                        {/*        label="是否沟通"*/}
                        {/*        initialValue="all"*/}
                        {/*    >*/}
                        {/*        <Select onChange={() => {*/}
                        {/*            pageChange(1)*/}
                        {/*        }}>*/}
                        {/*            <Option value="all">全部</Option>*/}
                        {/*            <Option value={false}>未沟通</Option>*/}
                        {/*            <Option value={true}>已沟通</Option>*/}
                        {/*        </Select>*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}

                        <Col span={8}>
                            <Form.Item
                                name="channel"
                                label="渠道"
                                initialValue="all"
                            >
                                <Select>
                                    <Option value="all">全部</Option>
                                    <Option value="移动">移动</Option>
                                    <Option value="联通">联通</Option>
                                    <Option value="电信渠道联通号码">电信渠道联通号码</Option>
                                    <Option value="混合">混合</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="resource"
                                label="来源"
                            >
                                {/*<AutoComplete*/}
                                {/*    options={options}*/}

                                {/*    onSelect={() => {*/}
                                {/*        pageChange(1)*/}
                                {/*    }}*/}
                                {/*    onSearch={() => {*/}
                                {/*        pageChange(1)*/}
                                {/*    }}*/}
                                {/*    placeholder="选择选择来源"*/}
                                {/*/>*/}

                                <Select
                                    mode="multiple"
                                    allowClear
                                    placeholder="选择来源"
                                >
                                    {
                                        options.map(option => <Option key={option.name} value={option.name}>{option.name}</Option>)
                                    }

                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="phoneCode"
                                label="电话编号"
                            >
                                <Input placeholder="输入电话编号" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="name"
                                label="微信昵称"
                            >
                                <Input placeholder="输入微信昵称" />
                            </Form.Item>
                        </Col>

                        {/*{*/}
                        {/*    marketerList.map(marketer => <div key={marketer.userId} className="item">*/}
                        {/*        <div>{marketer.realName || marketer.userName}</div>*/}
                        {/*        <div className="btn-separate" onClick={handleDispatch(marketer.userId)}>分配</div>*/}
                        {/*    </div>)*/}
                        {/*}*/}

                        <Col span={8}>
                            <Form.Item
                                name="userId"
                                label="所属销售"
                                initialValue="all"
                            >
                                <Select>
                                    <Option value="all">全部</Option>

                                    {
                                        marketerList.map(marketer => <Option key={marketer.userId} value={marketer.userId}>
                                            <div className="flex justify-between" style={{maxWidth: 180}} >
                                                <div className="text-primary">{marketer.realName}</div>{marketer.userName}
                                            </div>
                                        </Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="hasExchange"
                                label="是否沟通"
                                initialValue="all"
                            >
                                <Select>
                                    <Option value="all">全部</Option>
                                    <Option value={false}>未沟通</Option>
                                    <Option value={true}>已沟通</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="tagStr"
                                label="标签"
                                initialValue="all"
                            >
                                <Select>
                                    <Option value="all">全部</Option>
                                    {
                                        tagList.map(tag => <Option key={tag.tagId} value={tag.tagId}>{tag.name}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                            <Button
                                style={{ marginLeft: 8 }}
                                onClick={() => {
                                    form.resetFields();
                                    getCustomerLatentList({ pageIndex: 1 });
                                }}
                            >
                                重置
                            </Button>

                            <Button
                                className="float-right"
                                danger
                                type="primary"
                                onClick={handleSaveCsv}
                            >
                                导出编号表
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div >
                <Table
                    // loading={true}
                    rowSelection={{
                        type: 'checkbox',
                        selectedRowKeys: selectedRowKeys,
                        onChange: (selectedRowKeys, selectedRows) => {
                            setSelectList(selectedRows);
                            setSelectedRowKeys(selectedRowKeys);
                        },
                        getCheckboxProps: record => ({
                            disabled: isClear ? false : record.saleName,
                        }),
                    }}

                    pagination={{
                        defaultCurrent: 1,
                        showQuickJumper: true,
                        current: pageIndex,
                        total,
                        showSizeChanger: false,
                        pageSize: 50,
                        onChange: pageChange,
                    }}
                    dataSource={tableData}
                >
                    <Column width={90} title="电话编号" dataIndex="phoneCode" key="phoneCode" />
                    <Column width={90} title="旧电话编号" dataIndex="oldPhoneCodes" key="oldPhoneCodes" />
                    <Column title="关联客户" width={120} dataIndex="customerNickName" key="customerNickName" render={(nickName, item) => nickName ? <>
                        <div className="flex items-center">
                            <div
                                className="avatar"
                                style={{ backgroundImage: `url('${item.customerAvatarUrl}')` }}
                            ></div>
                            <div style={{ marginLeft: 6 }}>{item.customerNickName}</div>
                        </div>
                    </> : ''} />

                    <Column width={90} title="微信号" dataIndex="wechatNo" key="wechatNo" />
                    <Column width={90} title="微信昵称" dataIndex="nickName" key="nickName" />

                    <Column width={80} title="销售" dataIndex="saleName" key="saleName" render={(saleName, item) => item.saleRealName || item.saleName} />

                    <Column width={90} title="标签" dataIndex="tags" key="tags" />

                    <Column width={90} title="渠道" dataIndex="channel" key="channel" />
                    <Column width={200} title="来源" dataIndex="resource" key="resource" />
                    <Column width={200} title="省份" dataIndex="province" key="province" />
                    <Column width={200} title="城市" dataIndex="city" key="city" />
                    <Column width={90} title="沟通次数" dataIndex="exchangeCount" key="exchangeCount" />
                    <Column width={100} title="备注" dataIndex="remark" key="remark" />
                    <Column title="时间" width={200} dataIndex="cDate" key="cDate" render={(cdate, item) => <>
                        {item.frozenTime ? <div>冻结：<span className="text-error">{item.frozenTime}</span></div> : ''}
                        <div>分配：<span className="text-primary">{item.assignedTime}</span></div>
                        <div>入库：<span className="text-primary">{item.createTime}</span></div>
                        <div>采集：<span className="text-primary">{cdate ? /\d{4}-\d{2}-\d{2}/.exec(cdate)[0] : ''}</span></div>
                    </>} />
                    {/*<Column width={180} title="沟通记录" dataIndex="createTime" key="createTime" render={() => <div>*/}
                    {/*    <div>20-11-1</div>*/}
                    {/*    <div>20-12-1</div>*/}
                    {/*</div>} />*/}

                    {/*<Column title="中标数" dataIndex="totalHit" key="totalHit" />*/}

                    {/*<Column*/}
                    {/*    title="操作"*/}
                    {/*    key="action"*/}
                    {/*    width={130}*/}
                    {/*    render={(text, record) => (*/}
                    {/*        <Space size="middle">*/}
                    {/*            <p className="pointer" >详情</p>*/}
                    {/*            <p className="text-primary pointer" >编辑</p>*/}
                    {/*      </Space>*/}
                    {/*    )}*/}
                    {/*/>*/}
                </Table>
            </div>
        </div>

        <Modal
            title={editId ? '用户编辑' : '新增用户'}
            visible={modalVisible}
            onOk={handleModalOk}
            confirmLoading={modalConfirmLoading}
            onCancel={handleModalCancel}
            okText="确定"
            cancelText="取消"
            maskClosable={false}
        >
            <Form
                form={formNew}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 17 }}
                name="basic"
            // onFinish={onFinish}
            >
                <Form.Item
                    label="手机号"
                    name="phone"
                    rules={[{ required: true, message: '请输入手机号' }]}

                >
                    <Input maxLength={20} placeholder="请输入手机号" />
                </Form.Item>

                <Form.Item
                    label="昵称"
                    name="alias"
                    rules={[{ required: true, message: '请输入昵称' }]}

                >
                    <Input maxLength={20} placeholder="请输入昵称" />
                </Form.Item>

                <Form.Item
                    label="姓名"
                    name="name"
                    rules={[{ required: true, message: '请输入真实姓名' }]}

                >
                    <Input maxLength={20} placeholder="请输入真实姓名" />
                </Form.Item>


                {/*<Form.Item*/}
                {/*    label="手机号"*/}
                {/*    name="phone"*/}
                {/*    rules={[{ required: true, message: '请输入友商手机号' }]}*/}
                {/*>*/}
                {/*    <Input />*/}
                {/*</Form.Item>*/}

            </Form>
        </Modal>

        <div className="separate-panel">
            {
                selectList.length && !isClear ? <Button type="primary" shape="round" danger onClick={() => {
                    setSelectPanelShow(true);
                }}>
                    <ApartmentOutlined />
                    分配客户
                </Button> : ''
            }

            {
                selectPanelShow ? <div className="separate-select-panel">
                    <div className="item items-center">
                        <div>选择销售</div>

                        <CloseOutlined className="text-black-body pointer" onClick={() => {
                            setSelectPanelShow(false);
                        }} />
                    </div>

                    <div className="overflow-auto" style={{ maxHeight: 450 }}>
                        {
                            marketerList.map(marketer => <div key={marketer.userId} className="item">
                                <div>{marketer.realName}&nbsp;&nbsp;<span className="text-primary">{marketer.userName}</span></div>
                                <div className="btn-separate" onClick={handleDispatch(marketer.userId)}>分配</div>
                            </div>)
                        }
                    </div>

                </div> : ''
            }

        </div>

        {
            selectList.length && isClear ? <Button type="danger" className="btn-clear" onClick={handleClear}>清除分配记录</Button> : ''
        }


        <Modal
            className="batch-modal"
            width={700}
            title="统一分配潜在客户"
            visible={batchInfoList.length}
            okText="执行分配"
            cancelText="取消"
            onCancel={() => {
                setBatchInfoList([]);
            }}

            onOk={handleBatch}
        >
            <Checkbox onChange={onCheckChange} checked={clearToday}>清除当天已分配记录（分配过程中请通知销售停止沟通，以免造成正在沟通的客户被清除）</Checkbox>
            <div className="batch-list">
                {
                    batchInfoList.map((marketer, index) => <div key={marketer.userId} style={{ padding: '6px 0' }} className="item flex items-center">
                        <div style={{ width: 180, paddingRight: 15 }} className="item-user tr flex justify-between"><span className="text-primary">{marketer.userName ? `${marketer.userName}` : ''}</span><span >&nbsp;{marketer.realName || marketer.userName}</span></div>
                        <Input type="number" placeholder="输入分配数量" style={{ width: 330 }} onChange={(e) => {
                            setBatchInfoList(prev => {
                                // const arr = [...prev];
                                //
                                // console.log(e, e.target);

                                prev[index].needNum = e.target.value;

                                return prev;
                            });
                        }} className="item-input" allowClear={true} ></Input>
                        <div style={{ paddingLeft: 15 }} className="text-success tr flex-grow-1 flex justify-between">
                            <span style={{width: 50}} className="text-primary">已分配</span>
                            <span className="flex-grow-1 w-0">{marketer.num}</span>
                            <span className="text-error flex-grow-1 w-0">{marketer.recentNum}</span>
                        </div>
                    </div>)
                }
            </div>

        </Modal>

        {/*{*/}
        {/*    selectList.length ? <Button type="primary" shape="round" danger className="btn-separate">*/}
        {/*    <ApartmentOutlined />*/}
        {/*    分配客户*/}
        {/*    </Button> : ''*/}
        {/*}*/}

    </div>;
};

export default CustomerList;