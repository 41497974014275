import $fetch from "./fetch";

// export const apiLogin = ({code}) => {
//     return $fetch('/admin/login', {
//         method: 'POST',
//         params: {
//             code,
//         }
//     });
// };

export const apiLogin = ({userName, password}) => {
    return $fetch('/api/cms/users/login/user_pwd', {
        method: 'POST',
        params: {
            password,
            userName,
        }
    });
};

export const apiChangePwd = (
    {
        newPwd,
        originPwd,
    }
) => {
    return $fetch('/api/cms/users/password', {
        method: 'PUT',
        params: {
            newPwd,
            originPwd,
        }
    });
};
