
import React, { useEffect, useState, Fragment } from 'react';

import { Button, Col, Card, DatePicker, Form, Input, Layout, message, Modal, Row, Select, Space, Table, Collapse, Tag } from 'antd';

import { Link, useLocation, withRouter } from 'react-router-dom';

import { Breadcrumb, Typography } from 'antd';
import { HomeOutlined, DollarCircleOutlined, CaretRightOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import SiderMenu from "../../components/SiderMenu";

import Header from "../../components/Header";

import logo from '../../logo.svg';

import './index.less';

import { apiSalaryClean, apiSalaryDeliver, apiStatisticEmployee } from "../../api/statistic";
import locale from "antd/es/date-picker/locale/zh_CN";

import { apiTagList } from "../../api/settings";

import moment from 'moment';

const { Title } = Typography;

const { Panel } = Collapse;

const { Column, ColumnGroup } = Table;

const { Option } = Select;

let stickyDateArr = [];

const Salary = (props) => {

    const [form] = Form.useForm();

    const [pageIndex, setPageIndex] = useState(1);

    const [total, setTotal] = useState(0);

    const [dateValue, setDateValue] = useState([]);

    const [tableData, setTableData] = useState([]);

    useEffect(() => {

        handleGetData();

    }, []);

    const handleGetData = async () => {

        const res = await apiStatisticEmployee();

        // console.log(res);

        setTableData(res);

    };

    const handleGotoDetail = (item, type, {
        start
    }) => () => {
        // const [startTime, endTime] = stickyDateArr;

        const end = moment(start).endOf('month').format('YYYY-MM-DD');

        window.localStorage.setItem('orderFilter', JSON.stringify({
            employee: item.employee,
            ...(type === 1 ? {
                startTime: start.match(/\d{4}-\d{1,2}-\d{1,2}/)[0],
                endTime: end.match(/\d{4}-\d{1,2}-\d{1,2}/)[0],
            } : {
                cancelStartTime: start.match(/\d{4}-\d{1,2}-\d{1,2}/)[0],
                cancelEndTime: end.match(/\d{4}-\d{1,2}-\d{1,2}/)[0],
            })
        }));

        props.history.push('/order-list');

    };

    const handleCheck = () => {
        Modal.confirm({
            title: '确认结算',
            icon: <ExclamationCircleOutlined />,
            content: '确认结算，相关提成核算无误?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                const res = await apiSalaryDeliver();

                if (res) {
                    message.success("已结算");

                    handleGetData();
                } else {
                    message.error("结算失败，请稍后重试~");

                    handleGetData();
                }

            },
            // onCancel() {
            // },
        });
    };

    const handleCheckAgain = () => {
        Modal.confirm({
            title: '重新结算',
            icon: <ExclamationCircleOutlined />,
            content: '重新结算，相关提成核算无误?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                const res = await apiSalaryClean();

                if (res) {
                    message.success("已重新结算");

                    handleGetData();
                } else {
                    message.error("重新结算失败，请稍后重试~");

                    handleGetData();
                }

            },
            // onCancel() {
            // },
        });
    };

    // diliverd
    const getMonthCn = (val) => {
        const res = val.match(/(\d{4})-(\d{1,2})/);

        if (res) {
            const [, year, month] = res;

            return `${year} 年 ${parseInt(month)} 月`;
        } else {
            return '';
        }
    }

    return <div className="page-salary">
        <Header>
            <div className="flex items-center justify-between">
                <Breadcrumb>
                    <Breadcrumb.Item href="/#/">
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/#/">
                        <DollarCircleOutlined />
                        <span style={{ marginLeft: 8 }}>提成结算</span>
                    </Breadcrumb.Item>
                </Breadcrumb>

                <Space >
                    <Button onClick={handleCheckAgain}>重新结算</Button>

                    <Button type="primary" onClick={handleCheck}>确认结算</Button>
                </Space>

            </div>
        </Header>

        <div className="content-panel-common">

            <div className="salary-collapse-list">

                {
                    tableData.map(item => <Collapse
                        key={item.employee}
                        bordered={false}
                        // defaultActiveKey={['1']}
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        className={`salary-collapse${item.balanceVos?.length ? '' : ' empty'}${item.inCharge ? ' incharge' : ''}`}
                        ghost
                        expandIconPosition="right"
                    >
                        <Panel header={
                            <div className="salary-title flex items-center justify-between">
                                <p className="flex-grow-1 w-0 tc text-primary bold relative">{item.employee} {item.inCharge ? <Tag className="tag-incharge" color="volcano">主管</Tag> : null}</p>
                                <p className="flex-grow-1 w-0 tc">应发： {item.total}</p>
                                <p className="flex-grow-1 w-0 tc">上月： {item.lastMonth}</p>
                                <p className="flex-grow-1 w-0 tc">历史： {item.history}</p>
                                <p className="flex-grow-1 w-0 tc">待发： {item.account}</p>
                            </div>
                        } key="1" className="site-collapse-custom-panel">

                            {
                                item.balanceVos?.length ? <Table
                                    className="salary-table"
                                    pagination={false}
                                    dataSource={item.balanceVos}
                                    rowClassName={
                                        (record, index) => {
                                            return record.delivered ? 'delivered' : '';
                                        }
                                    }
                                    rowKey={
                                        (record, index) => {
                                            return index;
                                        }
                                    }
                                >
                                    <Column title="月份" dataIndex="workMonth" key="workMonth" render={(val) => val ? getMonthCn(val) : ''} />
                                    <Column title="结算周期" dataIndex="checkMonthName" key="checkMonthName" />
                                    <Column title="阶梯单数" dataIndex="ladderCount" key="ladderCount" />
                                    <Column title="低价单数" dataIndex="lowPriceCount" key="lowPriceCount" />
                                    <Column title="单价" dataIndex="ladderBase" key="ladderBase" />
                                    <Column title="提成单位" dataIndex="unit" key="unit" />
                                    <Column title="提成" dataIndex="ladderSalary" key="ladderSalary" />
                                    <Column title="高/低单价提成" dataIndex="extraSalary" key="extraSalary" />
                                    <Column title="阶段奖金" dataIndex="ladderReward" key="ladderReward" />
                                    <Column title="总提成" dataIndex="salary" key="salary" />
                                    <Column title="应发" dataIndex="payment" key="payment" />

                                    <Column
                                        title="成交数"
                                        dataIndex="count"
                                        key="count"
                                        render={(val, record) => <div>
                                            <span className="dib" style={{ width: 50 }}>{val}</span>
                                            <span className="text-primary pointer" onClick={
                                                handleGotoDetail(record, 1, {
                                                    start: record.workMonth,
                                                    end: record.checkMonth
                                                })
                                            }>&nbsp;&nbsp;详情</span>
                                        </div>}
                                    />
                                    <Column
                                        title="取消数"
                                        dataIndex="cancelCount"
                                        key="cancelCount"
                                        render={(val, record) => <div>
                                            <span className="dib" style={{ width: 50 }}>{val}</span>
                                            <span className="text-primary pointer" onClick={
                                                handleGotoDetail(record, 2, {
                                                    start: record.workMonth,
                                                    end: record.checkMonth
                                                })
                                            }>&nbsp;&nbsp;详情</span>
                                        </div>}
                                    />
                                </Table> : ''
                            }
                        </Panel>
                    </Collapse>)
                }

            </div>

        </div>

    </div>;
};

export default withRouter(Salary);
