
import React, { useEffect, useState, Fragment, useRef } from 'react';

import { Button, Col, Tabs, DatePicker, Form, Input, Layout, message, Modal, Row, Select, Space, Table, Switch, Checkbox, Drawer } from 'antd';

import { withRouter } from "react-router";

import { Link, useLocation } from 'react-router-dom';

import { Breadcrumb, Typography } from 'antd';
import { HomeOutlined, LineChartOutlined, ExclamationCircleOutlined, BarChartOutlined } from '@ant-design/icons';

import SiderMenu from "../../components/SiderMenu";

import Header from "../../components/Header";

import logo from '../../logo.svg';

import './index.less';

import { usePageViews } from "../../App";

import imgCircle from '../../assets/imgs/circle.svg';
import imgCircleSpec from '../../assets/imgs/circle-spec.svg';
import { apiOverview } from "../../api";
import { apiRoleAdd, apiRoleDel, apiRoleUpdate } from "../../api/role";
import { apiQaAdd, apiQaDel, apiQaList, apiQaUpdate } from "../../api/qa";
import { apiMachineList } from "../../api/machine";
import { apiPayList, apiPayUpdate } from "../../api/pay";
import { apiStatisticCustomerLatent } from "../../api/statistic";
import locale from "antd/es/date-picker/locale/zh_CN";
import { apiUserList } from "../../api/user";

import { apiTagList } from "../../api/settings";
import { apiOrdersEmployee } from "../../api/order";

import moment from 'moment';

import { Chart } from '@antv/g2';

const { Title } = Typography;

const { Column, ColumnGroup } = Table;

const { Option } = Select;

const tagsNotShowInSimple = [
    '未接通',
    '不需要',
    '已委托他人',
    '准备申请',
    '未交保证金',
    '未激活',
    '已中标',
    '自己拍',
    '有意向',
    '后期考虑',
    '公牌',
    '复议',
    '同行',
    '停/空/恶劣'
];

const InfoAnalysis = (props) => {

    const [form] = Form.useForm();

    const [pageIndex, setPageIndex] = useState(1);

    const [total, setTotal] = useState(0);

    const [dateValue, setDateValue] = useState([moment(), moment()]);

    const [tableData, setTableData] = useState([]);

    const [sourcesData, setSourcesData] = useState([]);

    const [datesData, setDatesData] = useState([]);

    const [options, setOptions] = useState([]);

    const [resource, setResource] = useState('');

    const [oldPhone, setOldPhone] = useState('0');

    const [activeTab, setActiveTab] = useState('1');

    // const [marketerList, setMarketerList] = useState([]);

    const [showPercent, setShowPercent] = useState(localStorage.getItem('showPercent') || false);

    const chart = useRef(null);

    const barChart = useRef(null);

    const [barData, setBarData] = useState(null);

    const [tagNameArr, setTagNameArr] = useState(['关注公众号', '成交', '+V', '本子', '+V通过']);

    const [reverse, setReverse] = useState(false);

    const [open, setOpen] = useState(false);

    const [dataList, setDataList] = useState(JSON.parse(localStorage.getItem('dataList') || '[]'));

    const [userId, setUserId] = useState('');

    const [marketerList, setMarketerList] = useState([]);


    useEffect(() => {
        getOptions();

        getMarketerList();

        getData();

    }, []);

    useEffect(() => {
        if (showPercent) {
            localStorage.setItem('showPercent', 'true');
        } else {
            localStorage.removeItem('showPercent');
        }
    }, [showPercent]);

    useEffect(() => {
        localStorage.setItem('dataList', JSON.stringify(dataList || []));
    }, [dataList]);

    useEffect(() => {
        if (barData) {
            setTimeout(() => {
                !barChart.current && (barChart.current = new Chart({
                    container: 'bar-chart',
                    width: 920,
                    height: 500,
                    padding: [10, 50, 40, 150]
                }));


                barChart.current.data(barData.data);

                barChart.current.axis('percent', {
                    label: {
                        formatter: (val) => {
                            return val + ' %';
                        },
                    },
                });

                barChart.current.scale('percent', {
                    alias: '占比',
                });

                barChart.current.interval().position('name*percent').label('percent', {
                    style: {
                        fill: '#8d8d8d',
                    },
                    offset: 10,
                });

                barChart.current.legend(false);

                barChart.current.coordinate().transpose();

                barChart.current.interaction('active-region');

                barChart.current.render();

            }, 0);
        } else {
            if (barChart.current) {
                barChart.current.data([]);
                barChart.current.render();
            }

        }
    }, [barData]);

    useEffect(() => {

        const _data = {
            '1': tableData,
            '2': sourcesData,
            '3': datesData
        }[activeTab];

        if (_data) {

            if (!chart.current) {
                // 渲染图表
                chart.current = new Chart({
                    // paddingLeft: 60,
                    // paddingRight: 60,
                    container: 'chart',
                    width: 1400,
                    height: 600,
                    padding: [40, 50, 80, 200]
                });

            }

            const {
                data,
                userSelect
            } = mapData(_data);

            // console.log(data);

            chart.current.data(data);

            chart.current.axis('percent', {
                label: {
                    formatter: (val) => {
                        return val + ' %';
                    },
                },
            });

            chart.current.scale('percent', {
                alias: '占比',
            });

            chart.current.line().position('tagName*percent').color('name');

            chart.current.legend('name', {
                position: 'left',
                flipPage: false,
                selected: userSelect,
            });

            chart.current.render();

        }
    }, [tableData, sourcesData, datesData, tagNameArr, activeTab]);

    const mapData = () => {
        let _data = [];

        let _userSelect = {
            汇总: true,
        };

        // 横坐标为指标  纵坐标为比例 条目为 user

        ({
            '1': tableData,
            '2': sourcesData,
            '3': datesData
        })[activeTab].forEach(item => {
            const { name, info, id } = item;

            info.forEach(({ tagName, percent }) => {

                if (tagNameArr.indexOf(tagName) > -1 && (name.indexOf('马婷') === -1)) {

                    if (_userSelect[name] === undefined) {
                        _userSelect[name] = (name === '汇总');
                    }

                    _data.push({
                        tagName,
                        percent: Math.floor(percent * 10000) / 100,
                        name,
                    });

                }

            });

        });

        return {
            data: _data,
            userSelect: _userSelect
        };

    };

    const getMarketerList = async () => {
        const res = await apiUserList({ roleName: 'sale', pageSize: 100 });

        setMarketerList(res.records.filter(item => item.enabled == '1'));
    };

    const getData = async (date) => {
        const [_startTime, _endTime] = date || dateValue || [];

        // console.log(reverse);

        const res = await apiStatisticCustomerLatent({
            startTime: _startTime ? _startTime.format('YYYY-MM-DD') : null,
            endTime: _endTime ? _endTime.format('YYYY-MM-DD') : null,
            oldPhone: oldPhone,
            reverse,
            userId,
            resource: resource && resource.length ? encodeURIComponent(resource.join(',')) : '',
        });

        const { users, resources, dates } = res;

        [users, resources, dates].forEach((item, index) => {

            if (item && item.length) {

                let totalInfo = item[0].info.map(it => ({
                    ...it,
                    count: 0,
                }));

                let _totalCount = 0;

                item.forEach(_data => {

                    _data.info.forEach(_tagItem => {
                        const { tagName, count, percent } = _tagItem;

                        const index = totalInfo.findIndex(_it => _it.tagName === tagName);

                        totalInfo[index].count += count;

                    });

                    _totalCount += _data.total;

                });

                const chatCount = totalInfo.find(_it => _it.tagName === "已沟通").count || 0;

                totalInfo.forEach((_item, index) => {
                    // console.log(_item);

                    let percent = chatCount ? (totalInfo[index].count / (chatCount)).toFixed(4) : 0;

                    if (['已沟通', '未沟通'].indexOf(_item.tagName) > -1) {
                        percent = _totalCount ? (totalInfo[index].count / (_totalCount)).toFixed(4) : 0;
                    }

                    totalInfo[index] = {
                        ...totalInfo[index],
                        percent
                    };
                });

                if (index === 0) {
                    setDataList(prev => {
                        let arr = [...prev];

                        prev.length >= 20 && arr.pop();

                        let userName = '';

                        if (userId) {
                            const user = marketerList.find(it => it.userId === userId);

                            userName = `${user.realName} ${user.userName}`;
                        }

                        arr.unshift({
                            // 日期 来源 反选 老号码复用 
                            startTime: _startTime ? _startTime.format('YYYY-MM-DD') : null,
                            endTime: _endTime ? _endTime.format('YYYY-MM-DD') : null,
                            userName,
                            resource: (resource && resource.length ? resource.join(',') : '全部来源'),
                            reverse,
                            oldPhone: { '0': '全部号码', '1': '老号码', '-1': '新号码' }[oldPhone || '0'],
                            total: _totalCount,
                            info: totalInfo,
                            createTime: moment().format('YYYY-MM-DD HH:mm:SS')
                        });

                        return arr;

                    });
                }

                if (index === 0) {
                    setTableData([{
                        id: 'mock',
                        name: '汇总',
                        total: _totalCount,
                        info: totalInfo,
                    }, ...item]);
                }

                if (index === 1) {
                    setSourcesData(
                        [{
                            id: 'mock',
                            name: '汇总',
                            total: _totalCount,
                            info: totalInfo,
                        }, ...item]
                    );
                }

                if (index === 2) {
                    setDatesData(
                        [{
                            id: 'mock',
                            name: '汇总',
                            total: _totalCount,
                            info: totalInfo,
                        }, ...item]
                    );
                }

            } else {

                if (index === 0) {
                    setTableData([]);
                }

                if (index === 1) {
                    setSourcesData(
                        []
                    );
                }

                if (index === 2) {
                    setDatesData(
                        []
                    );
                }
            }

        });

        // if (users.length) {
        //     // let totalInfo = JSON.parse(JSON.stringify(res[0].info));

        //     // let totalInfo = JSON.parse(JSON.stringify(res[0].info));

        //     let totalInfo = users[0].info.map(it => ({
        //         ...it,
        //         count: 0,
        //     }));

        //     let _totalCount = 0;

        //     users.forEach(_data => {

        //         _data.info.forEach(_tagItem => {
        //             const { tagName, count, percent } = _tagItem;

        //             const index = totalInfo.findIndex(_it => _it.tagName === tagName);

        //             totalInfo[index].count += count;

        //         });

        //         _totalCount += _data.total;

        //     });

        //     totalInfo.forEach((_item, index) => {
        //         totalInfo[index] = {
        //             ...totalInfo[index],
        //             percent: _totalCount ? (totalInfo[index].count / _totalCount).toFixed(4) : 0
        //         };
        //     });

        //     setDataList(prev => {
        //         let arr = [...prev];

        //         prev.length >= 20 && arr.pop();

        //         arr.unshift({
        //             // 日期 来源 反选 老号码复用 
        //             startTime: _startTime.format('YYYY-MM-DD'),
        //             endTime: _endTime.format('YYYY-MM-DD'),
        //             resource: (resource && resource.length ? resource.join(',') : '全部来源'),
        //             reverse,
        //             oldPhone: { '0': '全部号码', '1': '老号码', '-1': '新号码' }[oldPhone || '0'],
        //             total: _totalCount,
        //             info: totalInfo,
        //             createTime: moment().format('YYYY-MM-DD HH:mm:SS')
        //         });

        //         return arr;

        //     });

        //     setTableData([{
        //         userId: 'mock',
        //         name: '汇总',
        //         total: _totalCount,
        //         info: totalInfo,
        //     }, ...users]);
        // }

    };

    const handleDateChange = async (e) => {
        setDateValue(e);
    };

    const getOptions = async () => {
        const res = await apiTagList({ code: 'phone' });

        setOptions(res.records);
    };

    const handleGetData = (date) => () => {

        if (date) {
            getData(date);
        } else {
            getData();
        }
    };

    const handleToggleShowPercent = (e) => {
        setShowPercent(e);
    }

    const handleShowChart = (tagName, type = 0) => () => {

        // 准备数据
        const _data = [];

        const _targetData = [tableData, sourcesData, datesData][type];

        _targetData.forEach(({ name, id, info }) => {

            if (name.indexOf('马婷') === -1) {
                const tagItem = info.find(it => it.tagName === tagName);

                _data.push({
                    name,
                    percent: Math.floor(tagItem.percent * 10000) / 100,
                });
            }

        });

        _data.sort((a, b) => a.percent - b.percent);

        setBarData({
            title: tagName,
            data: _data,
        });

    };

    const handleTagNameArrChange = (res) => {
        setTagNameArr(res);
    };

    const onCheckChange = (e) => {
        // console.log(e.target.checked);

        setReverse(e.target.checked);
    };

    const handleTabChange = (value) => {
        setActiveTab(value);
    };

    return <div className="page-coupon-settings">
        <Header>
            <div className="flex items-center justify-between">
                <Breadcrumb>
                    <Breadcrumb.Item href="/#/">
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/#/">
                        <LineChartOutlined />
                        <span style={{ marginLeft: 8 }}>信息统计分析</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>

        </Header>

        <div className="content-panel-common">
            <div className="lh-lg flex items-center justify-between">
                <div className="flex items-center">
                    <Space>

                        <DatePicker.RangePicker
                            locale={locale}
                            value={dateValue}
                            allowEmpty={[true, true]}
                            disabledDate={
                                (current) => {
                                    // Can not select days before today and today
                                    return current && current > moment();
                                }
                            }
                            onChange={handleDateChange}
                        />

                        <Button onClick={() => {
                            handleDateChange([moment().subtract('days', 6), moment()]);
                            // handleGetData([moment().subtract('days', 6), moment()])();
                        }}>最近一周</Button>

                        <Button onClick={() => {
                            handleDateChange([moment().subtract('days', 13), moment()]);
                            // handleGetData([moment().subtract('days', 13), moment()])();
                        }}>最近两周</Button>

                        <Button onClick={() => {
                            handleDateChange([moment().subtract('days', 29), moment()]);
                            // handleGetData([moment().subtract('days', 29), moment()])();
                        }}>最近一个月</Button>

                        <Select
                            onChange={(v) => {
                                setResource(v);
                            }}
                            mode="multiple"
                            allowClear
                            placeholder="选择来源"
                            style={{ width: 200, marginLeft: 15 }}
                        >
                            {
                                options.map(option => <Option key={option.name} value={option.name}>{option.name}</Option>)
                            }
                        </Select>

                        <Checkbox onChange={onCheckChange} checked={reverse}>反选</Checkbox>

                        <Select
                            onChange={(v) => {
                                v ? setOldPhone(v) : setOldPhone('0');
                            }}
                            allowClear
                            placeholder="号码复用"
                            style={{ width: 100, marginLeft: 15 }}
                        >
                            <Option value="1">老号码</Option>
                            <Option value="-1">新号码</Option>
                        </Select>

                        <Select
                            onChange={(v) => {
                                setUserId(v);
                            }}
                            allowClear
                            placeholder="选择销售"
                            style={{ width: 200, marginLeft: 15 }}
                        >
                            {
                                marketerList.map(marketer => <Option key={marketer.userId} value={marketer.userId}>
                                    <div className="flex justify-between">
                                        {marketer.realName} <span className="text-primary">{marketer.userName}</span>
                                    </div>
                                </Option>)
                            }
                        </Select>

                        <Button type='primary' style={{ marginLeft: 15, marginRight: 10 }} onClick={handleGetData()}>查询</Button>

                    </Space>

                </div>

                <div className='flex items-center'>
                    <Space>
                        <p className="text-primary">模式</p>
                        <Switch checkedChildren="完整" unCheckedChildren="简洁" checked={showPercent} onChange={handleToggleShowPercent} />
                    </Space>

                    <Button style={{ marginLeft: 15 }} onClick={() => {
                        setOpen(true);
                    }}>历史记录</Button>
                </div>

            </div>

            {
                <Tabs className="tabs-info" defaultActiveKey={activeTab} centered={true} onChange={handleTabChange} >
                    <Tabs.TabPane tab="销售" key="1">
                        <div style={{ marginTop: 20 }}>

                            {
                                tableData.length ? <Table
                                    // loading={true}
                                    pagination={false}
                                    dataSource={tableData}
                                    // scroll={{ x: 1600 }}
                                    rowKey={(record) => {
                                        return record.name;
                                    }}
                                    locale={{
                                        cancelSort: "点击取消排序",
                                        triggerAsc: "点击升序",
                                        triggerDesc: "点击降序"
                                    }}
                                    rowClassName={
                                        (record, index) => {
                                            if (record.name === '汇总') {
                                                return 'row-total';
                                            } else {
                                                return '';
                                            }
                                        }
                                    }
                                >
                                    <Column width={190} title="销售" dataIndex="name" key="name" render={(val, record) => {

                                        if (val === "汇总") {
                                            return "汇总";
                                        }

                                        const _spaceIndex = val.indexOf(" ");

                                        const _name = val.slice(0, _spaceIndex);

                                        const _nickName = val.slice(_spaceIndex + 1);

                                        return <div className="w-100 flex justify-between" style={{ paddingRight: 10 }}>
                                            <span className="text-primary">{_nickName}</span>
                                            <span>&nbsp;{_name}</span>
                                        </div>
                                    }} />

                                    <Column
                                        title="总量"
                                        dataIndex="total"
                                        key="total"
                                        render={(val) => (val || 0)}
                                        sorter={(a, b) => {
                                            return a.total - b.total;
                                        }}
                                        sortDirections={['descend']}
                                    />

                                    {
                                        tableData[0] ? tableData[0].info.map(({ tagName }) => {

                                            if (!showPercent && tagsNotShowInSimple.indexOf(tagName) > -1) {
                                                return null;
                                            }

                                            return <Column
                                                key={tagName}
                                                title={tagName}
                                                dataIndex="info"
                                                render={(info, record) =>
                                                    <>
                                                        {info.find(item => item.tagName === tagName).count} {tagName === '最近沟通' ? '分钟' : ''}
                                                        {
                                                            showPercent && tagName !== '最近沟通' ? <div className="tip-percent">{(info.find(item => item.tagName === tagName).percent * 100).toFixed(2)}%</div> : null
                                                        }
                                                        {
                                                            record.name === '汇总' && tagName !== '最近沟通' ? <BarChartOutlined className="text-primary db tl pointer" onClick={handleShowChart(tagName, 0)} /> : null
                                                        }
                                                    </>
                                                }
                                                sorter={(a, b) => {

                                                    if (showPercent && tagName !== '最近沟通') {
                                                        return a.info.find(it => it.tagName === tagName).percent - b.info.find(it => it.tagName === tagName).percent;
                                                    }

                                                    return a.info.find(it => it.tagName === tagName).count - b.info.find(it => it.tagName === tagName).count;
                                                }}
                                                sortDirections={['descend']}
                                            />;
                                        }) : null
                                    }

                                </Table> : <div style={{ margin: 30 }} className="tc">暂无数据</div>
                            }

                        </div>
                    </Tabs.TabPane>

                    <Tabs.TabPane tab="来源" key="2">
                        <div style={{ marginTop: 20 }}>

                            {
                                sourcesData && sourcesData.length ? <Table
                                    // loading={true}
                                    pagination={false}
                                    dataSource={sourcesData}
                                    // scroll={{ x: 1600 }}
                                    rowKey={(record) => {
                                        return record.name;
                                    }}
                                    locale={{
                                        cancelSort: "点击取消排序",
                                        triggerAsc: "点击升序",
                                        triggerDesc: "点击降序"
                                    }}
                                    rowClassName={
                                        (record, index) => {
                                            if (record.name === '汇总') {
                                                return 'row-total';
                                            } else {
                                                return '';
                                            }
                                        }
                                    }
                                >
                                    <Column width={190} title="来源" dataIndex="name" key="name" />
                                    <Column
                                        width={80}
                                        title="总量"
                                        dataIndex="total"
                                        key="total"
                                        render={(val) => (val || 0)}
                                        sorter={(a, b) => {
                                            return a.total - b.total;
                                        }}
                                    />

                                    {
                                        sourcesData[0] ? sourcesData[0].info.map(({ tagName }) => {

                                            if (!showPercent && tagsNotShowInSimple.indexOf(tagName) > -1) {
                                                return null;
                                            }

                                            return <Column
                                                key={tagName}
                                                title={tagName}
                                                dataIndex="info"
                                                render={(info, record) =>
                                                    <>
                                                        {info.find(item => item.tagName === tagName).count} {tagName === '最近沟通' ? '分钟' : ''}
                                                        {
                                                            showPercent && tagName !== '最近沟通' ? <div className="tip-percent">{(info.find(item => item.tagName === tagName).percent * 100).toFixed(2)}%</div> : null
                                                        }
                                                        {
                                                            record.name === '汇总' && tagName !== '最近沟通' ? <BarChartOutlined className="text-primary db tl pointer" onClick={handleShowChart(tagName, 0)} /> : null
                                                        }
                                                    </>
                                                }
                                                sorter={(a, b) => {

                                                    if (showPercent && tagName !== '最近沟通') {
                                                        return a.info.find(it => it.tagName === tagName).percent - b.info.find(it => it.tagName === tagName).percent;
                                                    }

                                                    return a.info.find(it => it.tagName === tagName).count - b.info.find(it => it.tagName === tagName).count;
                                                }}
                                                sortDirections={['descend']}
                                            />;
                                        }) : null
                                    }

                                </Table> : <div style={{ margin: 30 }} className="tc">暂无数据</div>
                            }

                        </div>
                    </Tabs.TabPane>

                    <Tabs.TabPane tab="日期" key="3">
                        <div style={{ marginTop: 20 }}>

                            {
                                datesData && datesData.length ? <Table
                                    // loading={true}
                                    pagination={false}
                                    dataSource={datesData}
                                    // scroll={{ x: 1600 }}
                                    rowKey={(record) => {
                                        return record.name;
                                    }}
                                    locale={{
                                        cancelSort: "点击取消排序",
                                        triggerAsc: "点击升序",
                                        triggerDesc: "点击降序"
                                    }}
                                    rowClassName={
                                        (record, index) => {
                                            if (record.name === '汇总') {
                                                return 'row-total';
                                            } else {
                                                return '';
                                            }
                                        }
                                    }
                                >
                                    <Column
                                        width={190}
                                        title="日期"
                                        dataIndex="name"
                                        key="name"
                                        sortDirections={['descend']}
                                        sorter={(a, b) => {
                                            return new Date(a.name).getTime() - new Date(b.name).getTime();
                                        }}
                                    />
                                    <Column
                                        width={80}
                                        title="总量"
                                        dataIndex="total"
                                        key="total"
                                        render={(val) => (val || 0)}
                                        sorter={(a, b) => {
                                            return a.total - b.total;
                                        }}
                                    />

                                    {
                                        datesData[0] ? datesData[0].info.map(({ tagName }) => {

                                            if (!showPercent && tagsNotShowInSimple.indexOf(tagName) > -1) {
                                                return null;
                                            }

                                            return <Column
                                                key={tagName}
                                                title={tagName}
                                                dataIndex="info"
                                                render={(info, record) =>
                                                    <>
                                                        {info.find(item => item.tagName === tagName).count} {tagName === '最近沟通' ? '分钟' : ''}
                                                        {
                                                            showPercent && tagName !== '最近沟通' ? <div className="tip-percent">{(info.find(item => item.tagName === tagName).percent * 100).toFixed(2)}%</div> : null
                                                        }
                                                        {
                                                            record.name === '汇总' && tagName !== '最近沟通' ? <BarChartOutlined className="text-primary db tl pointer" onClick={handleShowChart(tagName, 0)} /> : null
                                                        }
                                                    </>
                                                }
                                                sorter={(a, b) => {

                                                    if (showPercent && tagName !== '最近沟通') {
                                                        return a.info.find(it => it.tagName === tagName).percent - b.info.find(it => it.tagName === tagName).percent;
                                                    }

                                                    return a.info.find(it => it.tagName === tagName).count - b.info.find(it => it.tagName === tagName).count;
                                                }}
                                                sortDirections={['descend']}
                                            />;
                                        }) : null
                                    }

                                </Table> : <div style={{ margin: 30 }} className="tc">暂无数据</div>
                            }

                        </div>
                    </Tabs.TabPane>

                </Tabs>
            }

            <div id="chart" style={{
                marginTop: 35, display: ({
                    '1': tableData,
                    '2': sourcesData,
                    '3': datesData
                })[activeTab].length ? '' : 'none'
            }} className="" >
                <section className="flex">
                    <p className="bold">数据统计图表</p>

                    <div className="flex-grow-1 flex items-center justify-center">
                        <p style={{ paddingRight: 15 }}>选择标签</p>
                        <Select
                            style={{ width: 900 }}
                            mode="multiple"
                            allowClear
                            placeholder="请选择纳入统计的标签"
                            value={tagNameArr}
                            onChange={handleTagNameArrChange}
                            options={tableData.length ? tableData[0].info.map(it => ({
                                label: it.tagName,
                                value: it.tagName
                            })) : []}
                        />
                    </div>

                </section>
            </div>

        </div>

        <Modal
            width={1000}
            title={barData?.title || ''}
            visible={!!barData}
            footer={null}
            onCancel={() => {
                setBarData(null);
            }}
        >
            <div id="bar-chart">

            </div>
        </Modal>

        <Drawer
            title="历史记录"
            placement="right"
            className="history-drawer"
            width={'90%'}
            onClose={() => {
                setOpen(false);
            }}
            visible={open}
        >
            {
                dataList && dataList.length ? <Table
                    // loading={true}
                    pagination={{
                        defaultCurrent: 1,
                        current: 1,
                        total: 1,
                        showSizeChanger: false,
                        pageSize: 20,
                        // onChange: pageChange,
                    }}
                    dataSource={dataList}
                    // scroll={{ x: 1600 }}
                    rowKey={(record) => {
                        return record.name;
                    }}
                >
                    <Column width={190} title="记录名称" dataIndex="name" key="name" render={(val, record) => {
                        return <div>
                            {
                                record.startTime || record.endTime ? <div >{record.startTime} 至 {record.endTime}</div> : null
                            }

                            <div className={record.resource === '全部来源' ? 'text-success' : (record.reverse ? 'text-error underlined' : 'text-success')}>{record.resource}</div>
                            <div className="text-sec">{record.oldPhone}</div>
                            {
                                record.userName ? <div className='text-user'>{record.userName}</div> : null
                            }
                            <div className='text-primary'>{record.createTime}</div>
                        </div>
                    }} />
                    <Column width={80} title="总量" dataIndex="total" key="total" render={(val) => (val || 0)} />

                    {
                        dataList[0].info.map(({ tagName }) => <Column
                            key={tagName}
                            title={tagName}
                            dataIndex="info"
                            render={(info, record) => info.find(item => item.tagName === tagName) ?
                                <>
                                    {info.find(item => item.tagName === tagName).count}
                                    <div className="tip-percent">{(info.find(item => item.tagName === tagName).percent * 100).toFixed(2)}%</div>
                                </> : <></>
                            }
                        />)
                    }

                </Table> : null
            }

        </Drawer>

    </div>;
};

export default InfoAnalysis;
