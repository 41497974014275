// 价格管理接口
import $fetch from "./fetch";

// 导入价格表
export const apiPriceBatch = ({ csv }) => {
    return $fetch('/prices/batch', {
        method: 'POST',
        params: {
            csv,
        }
    });
}

// 获取价格表
export const apiPriceList = ({ pageIndex = 1, pageSize = 50 } = {pageIndex: 1, pageSize: 50}) => {
    return $fetch('/prices/page', {
        method: 'GET',
        params: {
            pageIndex,
            pageSize,
        }
    });
};