// 赔付金管理
import $fetch from "./fetch";

// 定时任务列表
export const apiTaskPage = (
    {
        jobId,
        jobName,
        pageIndex,
        pageSize = 20,
    } = {
        pageIndex: 1,
        pageSize: 20
    }
) => {
    return $fetch('/api/cms/schedules/page', {
        method: 'GET',
        params: {
            jobId,
            jobName,
            pageIndex,
            pageSize,
        }
    });
}


// 定时任务列表
export const apiSchedulesPage = (
    {
        jobId,
        jobName,
        pageIndex,
        pageSize = 20,
    } = {
        pageIndex: 1,
        pageSize: 20
    }
) => {
    return $fetch('/api/cms/schedules/page', {
        method: 'GET',
        params: {
            jobId,
            jobName,
            pageIndex,
            pageSize,
        }
    });
}


// 立即执行指定 ID 的任务
export const apiSchedulesExecution = (
    {
        id,
    }
) => {
    return $fetch(`/api/cms/schedules/${id}/execution`, {
        method: 'POST',
        params: {
            id,
        }
    });
}

// 查询定时任务日志列表
export const apiSchedulesLog = (
    {
        jobId,
        pageIndex = 1,
    }
) => {
    return $fetch('/api/cms/schedules/log/page', {
        method: 'GET',
        params: {
            jobId,
            pageIndex,
            pageSize: 20,
        }
    });
}
