
import React, { useEffect, useState } from 'react';

import './index.less';
import Header from "../../components/Header";
import { HomeOutlined, PictureOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Form, Breadcrumb, Col, Row, DatePicker, InputNumber, message } from 'antd';
import html2canvas from 'html2canvas';
import { faker } from '@faker-js/faker';
import locale from "antd/es/date-picker/locale/zh_CN";

import { usePageViews } from "../../App";

import moment from 'moment';

faker.locale = 'zh_CN';

const firstNameArr = ['王', '李', '赵', '夏', '陈', '徐', '刘', '苏', '朱', '姚', '卢', '魏', '郑', '孙', '马', '唐'];

const randomBid = () => {
    return Math.floor(1000000 * Math.random()) + 58000000;
};

const randomName = () => {
    let _nameLast = faker.name.lastName() + faker.name.lastName();

    _nameLast = _nameLast.slice(0, 2);

    return firstNameArr[Math.floor(Math.random() * 16)] + _nameLast;
}

const Pictures = () => {
    usePageViews();

    // const [bidNum, setBidNum] = useState(randomBid());

    // const [bidName, setBidName] = useState(randomName());

    // const [count, setCount] = useState(Math.floor(Math.random() * 6));

    const [form] = Form.useForm();

    const [preview, setPreview] = useState(false);

    const [randomInfo, setRandomInfo] = useState({
        bidNum: randomBid(),
        bidName: randomName(),
        count: Math.floor(Math.random() * 6),
        actualSec: -1,
        priceAdd: 0,
        padding: `${Math.floor(3 * Math.random())}px ${Math.floor(3 * Math.random())}px ${Math.floor(3 * Math.random())}px ${Math.floor(3 * Math.random())}px`
    });

    const [bidInfo, setBidInfo] = useState({
        bidDate: localStorage.getItem("bidDate") || '',
        version: localStorage.getItem("version") || '',
        publicInfo: localStorage.getItem("publicInfo") || '',
        dealPrice: localStorage.getItem("dealPrice") || '',
        startSec: localStorage.getItem("startSec") || '',
        endSec: localStorage.getItem("endSec") || '',
        picCount: 30,
    });

    useEffect(() => {
        localStorage.setItem("bidDate", bidInfo.bidDate || '');
    }, [bidInfo.bidDate]);

    useEffect(() => {
        localStorage.setItem("publicInfo", bidInfo.publicInfo || '');
    }, [bidInfo.publicInfo]);

    useEffect(() => {
        localStorage.setItem("version", bidInfo.version || '');
    }, [bidInfo.version]);

    useEffect(() => {
        localStorage.setItem("dealPrice", bidInfo.dealPrice || '');
    }, [bidInfo.dealPrice]);

    useEffect(() => {
        localStorage.setItem("startSec", bidInfo.startSec || '');
    }, [bidInfo.startSec]);

    useEffect(() => {
        localStorage.setItem("endSec", bidInfo.endSec || '');
    }, [bidInfo.endSec]);

    const getExpiredDate = () => {
        const remainCount = 6 - randomInfo.count - 1;

        const _time = moment().add(remainCount, 'months').endOf('month');

        return `${_time.year()}年${_time.month() + 1}月${_time.date()}号`;
    };

    const dataURLtoBlob = (dataurl) => {
        const arr = dataurl.split(',');

        const mime = arr[0].match(/:(.*?);/)[1];

        const bstr = atob(arr[1]);

        let n = bstr.length;

        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new Blob([u8arr], {
            type: mime
        });
    }


    const downloadFile = (fileName, blob) => { //创建文件内容
        const aLink = document.createElement('a');

        const evt = document.createEvent("MouseEvents");

        evt.initEvent("click", false, false);

        aLink.download = fileName; //下载文件名

        aLink.href = URL.createObjectURL(blob); //根据二进制文件生成对象

        aLink.dispatchEvent(evt); //触发点击
    }

    const onFinish = async () => {

        if (!preview) {
            message.error('请先生成预览图，确认无误后再批量生成图片');

            return;
        }

        const randomPreview = (count) => {
            // 批量生成并下载
            randomParams();

            if (count > 0) {
                setTimeout(() => {
                    html2canvas(document.querySelector('.preview-container')).then(function (canvas) {
                        // document.body.appendChild(canvas);
                        // 直接下载
                        const imgSrc = canvas.toDataURL(`image/jpeg`, 1);

                        const blob = dataURLtoBlob(imgSrc);

                        downloadFile(`bid-${bidInfo.picCount - count + 1}.jpeg`, blob);

                        randomPreview(count - 1);
                    });
                }, 500);
            }
        };

        randomPreview(bidInfo.picCount);

    };

    const handleGenPreview = async () => {
        // 校验字段是否合法
        await form.validateFields();

        console.log(form.getFieldsValue());

        setBidInfo({
            ...form.getFieldsValue(),
        });

        setPreview(true);

        randomParams();

    };

    const randomParams = () => {
        setRandomInfo({
            bidNum: randomBid(),
            bidName: randomName(),
            count: Math.floor(Math.random() * 6),
            actualSec: form.getFieldsValue().startSec + Math.floor(Math.random() * (form.getFieldsValue().endSec + 1 - form.getFieldsValue().startSec)),
            priceAdd: Math.floor(Math.random() * 4) * 100,
            padding: `${Math.floor(3 * Math.random())}px ${Math.floor(3 * Math.random())}px ${Math.floor(3 * Math.random())}px ${Math.floor(3 * Math.random())}px`
        });
    };

    return <div className="page-pictures">
        <Header>
            <Breadcrumb>
                <Breadcrumb.Item href="/#/">
                    <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item href="">
                    <PictureOutlined />
                    <span>中标图片</span>
                </Breadcrumb.Item>
            </Breadcrumb>
        </Header>

        <div className="content-panel-common">

            <p className="main-title" style={{ margin: '8px 0' }}>
                参数配置
            </p>

            <div className="args-container">
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                    onValuesChange={() => {
                        setPreview(false);
                    }}
                >


                    <Row gutter={24} >
                        <Col span={8}>
                            <Form.Item
                                name="bidDate"
                                label="拍牌日期"
                                rules={[
                                    {
                                        type: 'object',
                                        required: true,
                                        message: '请选择拍牌日期 !'
                                    }
                                ]}

                                initialValue={bidInfo.bidDate ? moment(bidInfo.bidDate) : null}
                            >
                                <DatePicker
                                    locale={locale}
                                    className="w-100"
                                    placeholder="请选择拍牌日期"
                                    allowClear
                                    disabledDate={
                                        (current) => {
                                            // Can not select days before today and today
                                            return current && current > moment();
                                        }
                                    }

                                    onChange={(e) => {
                                        setBidInfo(prev => ({ ...prev, bidDate: e ? e.format('YYYY-MM-DD') : '' }))
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="version"
                                label="版本号"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入软件版本号 !'
                                    }
                                ]}
                                initialValue={bidInfo.version}
                            >
                                <Input placeholder="输入软件版本号" allowClear onChange={(e) => { setBidInfo(prev => ({ ...prev, version: e.target.value })) }} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="publicInfo"
                                label="公开信息"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入公开信息 !'
                                    }
                                ]}
                                initialValue={bidInfo.publicInfo}
                            >
                                <Input.TextArea placeholder="输入公开信息" onChange={(e) => { setBidInfo(prev => ({ ...prev, publicInfo: e.target.value })) }} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                name="dealPrice"
                                label="最低成交价"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入最低成交价 !'
                                    },
                                    {
                                        type: 'number',
                                        max: 200000,
                                        min: 50000,
                                        message: '需在 50000-200000 之间 !'
                                    }
                                ]}
                                initialValue={bidInfo.dealPrice}
                            >
                                <InputNumber className="w-100" placeholder="输入最低成交价" allowClear onChange={(e) => { setBidInfo(prev => ({ ...prev, dealPrice: e })) }} />
                            </Form.Item>
                        </Col>

                        <Col span={6} className="flex" style={{ alignItems: 'flex-start' }}>
                            <Form.Item
                                name="startSec"
                                label="出价时间范围"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入开始秒数 !'
                                    },
                                    {
                                        type: 'number',
                                        max: 59,
                                        min: 0,
                                        message: '需在 0-59 之间!'
                                    }
                                ]}
                                initialValue={bidInfo.startSec}

                            >
                                <InputNumber addonAfter="s" style={{ width: 120 }} placeholder="开始秒数" allowClear onChange={(e) => { setBidInfo(prev => ({ ...prev, startSec: e })) }} />
                            </Form.Item>

                            <div style={{ margin: '4px 10px 0' }}>至</div>

                            <Form.Item
                                name="endSec"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入结束秒数 !'
                                    },
                                    {
                                        type: 'number',
                                        max: 59,
                                        min: 0,
                                        message: '需在 0-59 之间!'
                                    }
                                ]}
                                initialValue={bidInfo.endSec}

                            >
                                <InputNumber addonAfter="s" style={{ width: 120 }} placeholder="结束秒数" allowClear onChange={(e) => { setBidInfo(prev => ({ ...prev, endSec: e })) }} />

                            </Form.Item>

                        </Col>

                        <Col span={6}>
                            <Form.Item
                                name="picCount"
                                label="图片数量"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入图片数量 !'
                                    },
                                    {
                                        type: 'number',
                                        max: 50,
                                        min: 1,
                                        message: '需在 1-50 之间 !'
                                    }
                                ]}
                                initialValue={bidInfo.picCount}
                            >
                                <InputNumber className="w-100" placeholder="输入图片数量" />
                            </Form.Item>
                        </Col>

                        <Col md={6} lg={6} className="flex">
                            <Button onClick={handleGenPreview}>
                                生成预览图
                            </Button>
                            <Button type="primary" htmlType="submit" style={{ marginLeft: 15 }} >
                                批量生成图片
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>

            {/* <Button onClick={(() => {
                html2canvas(document.querySelector('.preview-container')).then(function (canvas) {
                    // document.body.appendChild(canvas);
                    // 直接下载
                    const imgSrc = canvas.toDataURL(`image/jpeg`, 1);

                    const blob = dataURLtoBlob(imgSrc);

                    downloadFile(`test.jpeg`, blob);
                });
            })}>下载</Button> */}

            {
                preview ? <>
                    <p className="main-title">
                        图片预览
                    </p>

                    <div className="preview-container" style={{ padding: randomInfo.padding }}>
                        <div className="inner">

                            <div className="mask-container">
                                <div className='mask-mayi'>
                                </div>
                                <div className='mask-mayi'>
                                </div>
                                <div className='mask-mayi'>
                                </div>
                                <div className='mask-mayi'>
                                </div>
                                <div className='mask-mayi'>
                                </div>
                                <div className='mask-mayi'>
                                </div>
                                <div className='mask-mayi'>
                                </div>
                                <div className='mask-mayi'>
                                </div>
                                <div className='mask-mayi'>
                                </div>
                                <div className='mask-mayi'>
                                </div>
                                <div className='mask-mayi'>
                                </div>
                                <div className='mask-mayi'>
                                </div>
                                <div className='mask-mayi'>
                                </div>
                                <div className='mask-mayi'>
                                </div>
                                <div className='mask-mayi'>
                                </div>
                                <div className='mask-mayi'>
                                </div>
                                <div className='mask-mayi'>
                                </div>
                                <div className='mask-mayi'>
                                </div>
                                <div className='mask-mayi'>
                                </div>
                                <div className='mask-mayi'>
                                </div>
                            </div>

                            {/* <div className="logo-mayi"></div> */}

                            <div className="btn-logout">
                                退出登录
                            </div>
                            <div className="header">
                                <div className="auction">
                                    <p>上海市个人非营业性客车额度拍卖</p>
                                </div>
                                <div className="info">
                                    <div className="tip flex">
                                        投标号：<div className="text-mask" >{randomInfo.bidNum}</div>
                                    </div>
                                    <div className="tip flex" style={{ paddingLeft: 14 }}>
                                        客户名称：<div className="text-mask" >{randomInfo.bidName}</div>
                                    </div>
                                </div>
                                <div className="info" style={{ marginTop: 15 }}>
                                    <div className="tip">
                                        有效期至：{getExpiredDate()}
                                    </div>
                                    <div className="tip" style={{ paddingLeft: 14 }}>
                                        已参拍次数：{randomInfo.count}次（不包括本场拍卖会）
                                    </div>
                                </div>

                            </div>

                            <div className="panel-wrap flex">

                                <div className="panel-left ">
                                    <div className="title">
                                        <div style={{ marginRight: 190 }}>公开信息</div>
                                    </div>
                                    <div className="content">
                                        {bidInfo.publicInfo}
                                        <br />
                                        网上支付、金融分期结算请点击
                                        <a href="https://www.alltobid.com/contents/16/1365.html?from=paimai" target="_blank" rel="noopener noreferrer">【在线支付】</a>
                                        <br />
                                        <a href="https://www.alltobid.com/contents/152/4158.html?from=paimai" target="_blank" rel="noopener noreferrer">【查看服务点地址】</a>
                                    </div>
                                    <div className="footer"></div>
                                </div>

                                <div className="panel-right ">
                                    <div className="mask">
                                        <div className="modal">
                                            <div className="modal-title">系统提示</div>

                                            <div className="modal-content">
                                                恭喜您成交了，          <br />
                                                成交价格：{bidInfo.dealPrice}元，     <br />
                                                本场出价时间：11:29:{randomInfo.actualSec}
                                            </div>

                                            <div className="btn-confirm">确定</div>

                                        </div>
                                    </div>

                                    <div className="title">
                                        <div>投标拍卖</div>
                                    </div>

                                    <div className="content">
                                        <br />
                                        {bidInfo.bidDate.year()}年{bidInfo.bidDate.month() + 1}月{bidInfo.bidDate.date()}日上海市个人非营业性客车额度拍卖 <br />
                                        拍卖会结果公布 <br />
                                        投标拍卖额度数量：10200 <br />
                                        参加拍卖人数：243435 <br />
                                        最低成交价：89900 <br />
                                        最低成交价截止时间： 15:09:55第204位 <br />
                                        平均成交价：89920 <br />
                                    </div>

                                    <div className="footer">
                                        <div className="inner">
                                            <div className="footer-title">出价情况</div>

                                            <div className="footer-content">
                                                您第2次出价 <br />
                                                出价金额:{bidInfo.dealPrice + randomInfo.priceAdd} <br />
                                                出价时间:{bidInfo.bidDate.year()}-{bidInfo.bidDate.month() + 1}-{bidInfo.bidDate.date()} 11:29:{randomInfo.actualSec} <br />
                                                出价方式:网络出价
                                            </div>
                                        </div>

                                        <div className="tip">
                                            <div className="version">版本：{bidInfo.version}</div>
                                            <div className="btn-query">查询本人最新出价情况</div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div className="gpfooter"><span>上海国际商品拍卖有限公司</span></div>

                        </div>
                    </div>


                </> : null
            }


        </div>
    </div>;
};

export default Pictures;