


import React, {useEffect, useState, Fragment} from 'react';

import {Button, Col, Form, Input, Layout, message, Modal, Row, Select, Space, Table} from 'antd';

import { withRouter } from "react-router";

import { Link, useLocation } from 'react-router-dom';

import { Breadcrumb, Typography } from 'antd';
import { HomeOutlined, ExclamationCircleOutlined, RedEnvelopeOutlined } from '@ant-design/icons';

import SiderMenu from "../../components/SiderMenu";

import Header from "../../components/Header";

import logo from '../../logo.svg';

import './index.less';

import {usePageViews} from "../../App";

import imgCircle from '../../assets/imgs/circle.svg';
import imgCircleSpec from '../../assets/imgs/circle-spec.svg';
import {apiOverview} from "../../api";
import {
    apiCouponsKeyAdd, apiCouponsKeyDel,
    apiCouponsKeyList,
    apiCouponsTempAdd, apiCouponsTempDel,
    apiCouponsTempList,
    apiCouponsTempUpdate
} from "../../api/coupon";
import {apiBidList} from "../../api/bid";
import {apiQaAdd, apiQaDel, apiQaUpdate} from "../../api/qa";
import {apiPartnerDel} from "../../api/partner";

const { Title } = Typography;

const { Column, ColumnGroup } = Table;

const { Option } = Select;

const CouponSettings = (props) => {

    const [tempData, setTempData] = useState([]);

    const [tableData, setTableData] = useState([]);

    const [form] = Form.useForm();

    const [formNew] = Form.useForm();

    const [formKey] = Form.useForm();

    const [pageIndex, setPageIndex] = useState(1);

    const [total, setTotal] = useState(0);

    const [tempPageIndex, setTempPageIndex] = useState(1);

    const [tempTotal, setTempTotal] = useState(0);

    const [editId, setEditId] = useState('');

    const [modalVisible, setModalVisible] = useState(false);

    const [modalConfirmLoading, setModalConfirmLoading] = useState(false);

    const [couponDefId, setCouponDefId] = useState('');

    useEffect(() => {
        getCouponTempList();

        getCouponList();
    }, []);

    useEffect(() => {

        if (!editId) {
            return;
        }

        const {
            couponName,
            money,
            priceCondition,
            remark,
            validPeriod
        } = tempData.find(item => item.cdId == editId);

        formNew.setFieldsValue({
            couponName,
            money,
            priceCondition,
            remark,
            validPeriod
        });

    }, [editId]);

    const getCouponTempList = async (page = 1, pageSize = 100) => {
        const res = await apiCouponsTempList({
            pageIndex: page,
            pageSize,
        });

        console.log(res);

        setTempPageIndex(res.pageIndex);
        setTempTotal(res.total);

        const _tempData = res.records.map(item => {

            return {
                ...item,
                key: item.cdId,
                priority: localStorage.getItem(item.cdId) || 'common',
            };
        });

        _tempData.sort((a, b) => {
            const _weightA = ({'regular': 1, 'common': 0, 'not-common': -1})[a.priority || 'common'];
            const _weightB = ({'regular': 1, 'common': 0, 'not-common': -1})[b.priority || 'common'];

            return _weightB - _weightA;
        });

        setTempData(_tempData);
    };

    const getCouponList = async (
        {
            cdkey,
            hasUsed,
            cdId,
            pageIndex = 1,
            pageSize = 20,
        } = {
            pageIndex: 1,
            pageSize: 20,
        }
    ) => {
        const res = await apiCouponsKeyList({
            cdId,
            cdkey,
            hasUsed,
            pageIndex,
            pageSize,
        });

        console.log(res);

        setPageIndex(res.pageIndex);
        setTotal(res.total);

        setTableData(res.records.map((item, index) => ({...item, key: index})));
    };

    const handleModalOk = async () => {
        try {
            const { couponName, money, priceCondition, remark, validPeriod } = await formNew.validateFields();

            setModalConfirmLoading(true);

            if (editId) {
                await apiCouponsTempUpdate({
                    id: editId,
                    couponName,
                    money,
                    priceCondition,
                    remark,
                    validPeriod
                });

                getCouponTempList();
            } else {
                await apiCouponsTempAdd({
                    couponName,
                    money,
                    priceCondition,
                    remark,
                    validPeriod
                });

                getCouponTempList(tempPageIndex);
            }

            formNew.resetFields();
            setModalVisible(false);
            setModalConfirmLoading(false);

            message.success(`优惠券模板${editId ? '更新' : '添加'}成功`);

            setEditId(null);

        } catch (e) {
            message.error(e.message);
            setModalConfirmLoading(false);
        }
    };

    const handleModalCancel = () => {
        setEditId(null);
        setModalVisible(false);
        formNew.resetFields();
    };

    const handleEditBtnClick = (id) => async () => {
        console.log(id);

        setEditId(id);

        setModalVisible(true);
    };

    const handleDelBtnClick = (id) => async () => {

        Modal.confirm({
            title: '删除优惠券模板',
            icon: <ExclamationCircleOutlined />,
            content: '是否要删除该优惠券模板?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                await apiCouponsTempDel({id});

                message.success("优惠券模板已删除");

                getCouponTempList(tempPageIndex);

            },
            // onCancel() {
            // },
        });

    };

    const pageChange = (page) => {
        console.log(page);
        const cdId = form.getFieldValue('cdId') || '';
        const cdkey = form.getFieldValue('cdkey') || '';
        const hasUsed =  form.getFieldValue('hasUsed') || '';

        getCouponList({
            cdId: cdId.trim(),
            cdkey: cdkey.trim(),
            hasUsed,
            pageIndex: page,
        });
    };

    const handleCouponDel = (cdkey) => async () => {

        Modal.confirm({
            title: '删除策略',
            icon: <ExclamationCircleOutlined />,
            content: '是否要删除该优惠券?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                await apiCouponsKeyDel({
                    cdkey,
                });

                message.success('该优惠券已删除');

                pageChange(pageIndex)
            },
            // onCancel() {
            // },
        });

    }

    const tempPageChange = (page) => {
        getCouponTempList(page);
    };

    const typeRender = (type, dom) => {
        return <div className={`type-${type}`}>{dom}</div>;
    };

    return <div className="page-coupon-settings">
        <Header>
            <Breadcrumb>
                <Breadcrumb.Item href="/#/">
                    <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item href="/#/">
                    <RedEnvelopeOutlined />
                    <span style={{marginLeft: 8}}>优惠券管理</span>
                </Breadcrumb.Item>
                {/*<Breadcrumb.Item href="">*/}
                {/*    <UserOutlined />*/}
                {/*    <span>Application List</span>*/}
                {/*</Breadcrumb.Item>*/}
                {/*<Breadcrumb.Item>Application</Breadcrumb.Item>*/}
            </Breadcrumb>
        </Header>

        <div className="content-panel-common">
            <div className="panel-top">
                <div className="flex justify-between items-center">
                    <div className="lh-lg fs-18">优惠券模板管理</div>
                    <Button type="primary" onClick={() => {
                        setModalVisible(true)
                    }}>新增优惠券模板</Button>
                </div>

                {/*cdId: "1314500723798577152"*/}
                {/*couponCode: "customer:new"*/}
                {/*couponName: "500元优惠券"*/}
                {/*createTime: "2020-10-10 10:54:41"*/}
                {/*deleted: "0"*/}
                {/*expireTime: null*/}
                {/*money: 500*/}
                {/*priceCondition: -1*/}
                {/*remainCount: -1*/}
                {/*remark: "新用户优惠, 无限制"*/}
                {/*totalCount: -1*/}
                {/*updateTime: "2020-10-10 10:54:41"*/}
                {/*validPeriod: 30*/}
                {/*validUnit: "day"*/}

                <div className="coupon-temp-list">
                    <Table
                        dataSource={tempData}
                        pagination={{
                            defaultCurrent: 1,
                            current: tempPageIndex,
                            total: tempTotal,
                            showSizeChanger: false,
                            pageSize: 100,
                            onChange: tempPageChange,
                        }}
                    >
                        <Column title="ID" dataIndex="cdId" key="cdId" render={(value, item) => typeRender(item.priority || 'common', value)} />
                        <Column title="名称" dataIndex="couponName" key="couponName" render={(value, item) => typeRender(item.priority || 'common', value)} />
                        <Column title="描述" dataIndex="remark" key="remark" render={(value, item) => typeRender(item.priority || 'common', value)} />
                        <Column title="门槛" dataIndex="priceCondition" key="priceCondition" render={(value, item) => typeRender(item.priority || 'common', value <= 0 ? '无门槛' : value)} />
                        <Column title="折扣" dataIndex="money" key="money" render={(value, item) => typeRender(item.priority || 'common', value)} />
                        <Column title="总量" dataIndex="totalCount" key="totalCount" render={(value, item) => typeRender(item.priority || 'common',value <= 0 ? '无限制' : value)} />
                        <Column title="余量" dataIndex="remainCount" key="remainCount" render={(value, item) => typeRender(item.priority || 'common',value <= 0 ? '无限制' : value)} />
                        <Column title="有效期" dataIndex="validPeriod" key="validPeriod" render={(value, item) => typeRender(item.priority || 'common', `${value}天`)} />
                        <Column title="优先级" dataIndex="priority" key="priority" render={(value, item) => <Select defaultValue={value || 'common'} onChange={(selectValue) => {
                            setTempData(prev => {
                                const _arr = [...prev];

                                const index = _arr.findIndex(_i => _i.cdId === item.cdId);

                                _arr[index].priority = selectValue;

                                localStorage.setItem(item.cdId, selectValue);

                                // 重排序
                                _arr.sort((a, b) => {
                                    const _weightA = ({'regular': 1, 'common': 0, 'not-common': -1})[a.priority || 'common'];
                                    const _weightB = ({'regular': 1, 'common': 0, 'not-common': -1})[b.priority || 'common'];

                                    return _weightB - _weightA;
                                });

                                return _arr;

                            });
                        }}>
                            <Option value={'regular'}>常用</Option>
                            <Option value={'common'}>一般</Option>
                            <Option value={'not-common'}>偶尔</Option>
                        </Select>}  />

                        <Column title="创建时间" dataIndex="createTime" key="createTime" render={(value, item) => typeRender(item.priority || 'common', value)} />

                        <Column title="操作"
                                key="action"
                                width={200}
                                render={(field, item) => <Space size="middle">
                                    <p className="pointer text-primary" onClick={() => {
                                        setCouponDefId(item.cdId);
                                    }} >生成优惠券</p>
                                    <p className="pointer text-primary" onClick={() => {
                                        setEditId(item.cdId);
                                        setModalVisible(true);
                                    }}>编辑</p>
                                    <p className="pointer text-error" onClick={handleDelBtnClick(item.cdId)}>删除</p>
                                </Space>}
                        />
                    </Table>
                </div>

            </div>

            <div className="panel-bottom">
                <div className="lh-lg fs-18" style={{marginBottom: 10}}>优惠券管理</div>

                <div className="filter-pannel-common">
                    <Form
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={() => {
                            pageChange(1);
                        }}
                        form={form}
                    >
                        <Row gutter={24}>

                            <Col span={6}>
                                <Form.Item
                                    name="cdId"
                                    label="模板ID"
                                >
                                    <Input placeholder="输入模板ID" />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item
                                    name="hasUsed"
                                    label="是否兑换"
                                    initialValue={''}
                                >
                                    <Select onChange={() => {
                                        pageChange(1)
                                    }}>
                                        <Option value={''}>全部</Option>
                                        <Option value={'false'}>未兑换</Option>
                                        <Option value={'true'}>已兑换</Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item
                                    name="cdkey"
                                    label="兑换码"
                                >
                                    <Input placeholder="输入兑换码" />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Button type="primary" htmlType="submit">
                                    搜索
                                </Button>
                                <Button
                                    style={{ marginLeft: 8 }}
                                    onClick={() => {
                                        form.resetFields();
                                        pageChange(1);
                                    }}
                                >
                                    重置
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>

                <div className="coupon-list">
                    <Table
                        // loading={true}
                        pagination={{
                            defaultCurrent: 1,
                            current: pageIndex,
                            total,
                            showSizeChanger: false,
                            pageSize: 20,
                            onChange: pageChange,
                        }}
                        dataSource={tableData}
                    >
                        <Column title="兑换码" dataIndex="cdKey" key="cdKey" />
                        <Column title="名称" dataIndex="couponName" key="couponName" />

                        <Column title="描述" dataIndex="cdId" key="desc" render={(val) => {
                            if (tempData.length) {
                                return tempData.find(it => it.cdId === val)?.remark || '--';
                            } else {
                                return '';
                            }
                        }} />
                        <Column title="门槛" dataIndex="cdId" key="priceCondition" render={(val) => {
                            
                            if (tempData.length) {
                                let _target = tempData.find(it => it.cdId === val);

                                if (_target) {
                                    let _disCount = _target.priceCondition;

                                    if (_disCount <= 0) {
                                        return '无门槛';
                                    } else {
                                        return _disCount;
                                    }

                                } else {
                                    return '--';
                                }
                             
                            } else {
                                return '';
                            }
                        }} />

                        <Column title="是否兑换" dataIndex="hasUsed" key="hasUsed" render={(value) => value ? '已兑换' : '未兑换'} />
                        <Column title="创建时间" dataIndex="createTime" key="createTime" />
                        <Column title="操作"
                                key="action"
                                width={140}
                                render={(field, item) => item.hasUsed ? '' : <Space size="middle">
                                    <p className="pointer text-primary" onClick={handleCouponDel(item.cdKey)} >删除</p>
                                </Space>}
                        />
                    </Table>
                </div>
            </div>

        </div>

        <Modal
            title={editId ? '优惠券模板编辑' : '新增优惠券'}
            visible={modalVisible}
            onOk={handleModalOk}
            confirmLoading={modalConfirmLoading}
            onCancel={handleModalCancel}
            okText="确定"
            cancelText="取消"
            maskClosable={false}
        >
            <Form
                form={formNew}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 17 }}
                name="basic"
            >
                <Form.Item
                    label="名称"
                    name="couponName"
                    rules={[{ required: true, message: '请输入名称' }]}
                >
                    <Input maxLength={20} placeholder="请输入名称，如 '500元优惠券'" />
                </Form.Item>

                <Form.Item
                    label="描述"
                    name="remark"
                    rules={[{ required: true, message: '请输入描述语' }]}
                >
                    <Input maxLength={30} placeholder="请输入描述语，如 '新用户优惠, 满8千元可用'" />
                </Form.Item>

                <Form.Item
                    label="门槛"
                    name="priceCondition"
                >
                    <Input maxLength={20} placeholder="请输入价格门槛，无门槛不用输" />
                </Form.Item>

                <Form.Item
                    label="折扣"
                    name="money"
                    rules={[{ required: true, message: '请输入折扣金额' }]}
                >
                    <Input maxLength={20} placeholder="请输入折扣金额" />
                </Form.Item>

                <Form.Item
                    label="有效期"
                    name="validPeriod"
                    rules={[{ required: true, message: '请输入有效期' }]}
                >
                    <Input maxLength={20} placeholder="请输入有效期" addonAfter="天"  />
                </Form.Item>

            </Form>
        </Modal>

        <Modal
            title="生成优惠券"
            visible={!!couponDefId}
            onOk={async () => {

                const { size } = await formKey.validateFields();

                if (size > 0) {
                    const res = await apiCouponsKeyAdd({
                        couponDefId,
                        size,
                    });

                    getCouponList();

                    message.success(`已生成${size}个兑换码`);

                    setCouponDefId('');

                } else {
                    message.error("数量不能为空");
                }
            }}
            confirmLoading={modalConfirmLoading}
            onCancel={() => {

                setCouponDefId('');
            }}
            okText="确定"
            cancelText="取消"
            maskClosable={false}
        >
            <Form
                form={formKey}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 17 }}
                name="basic"
            >
                <Form.Item
                    label="数量"
                    name="size"
                    rules={[{ required: true, message: '请输入数量' }]}
                >
                    <Input type="number" maxLength={20} placeholder="请输入数量" />
                </Form.Item>
            </Form>

        </Modal>

    </div>;
};

export default CouponSettings;
