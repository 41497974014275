export const SET_TOKEN = 'SET_TOKEN';

const INITIAL_STATE = "";

export default function token (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_TOKEN:
      return action.payload;
    default:
      return state;
  }
}
