import {
    useLocation
} from "react-router-dom";


export const useQuery = () => new URLSearchParams(useLocation().search);


export const getQueryObj = () => {
    const search = window.location.hash; //获取url中"?"符后的字串

    const queryObj = {};

    if (search.indexOf("?") != -1) {

        const targetStr = search.split("?")[1];

        const targetArr = targetStr.split("&");

        for(let i = 0; i < targetArr.length; i ++) {
            queryObj[targetArr[i].split("=")[0]] = unescape(targetArr[i].split("=")[1]);
        }
    }
    return queryObj;
};

export const getIsXinghen = () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');

    return userInfo.user ? (userInfo.user.userName === 'xinghen') : false;
};
