
import React, { useEffect, useRef, useState } from 'react';

import {
    Form,
    Row,
    Col,
    Input,
    Button,
    Card,
    Table,
    Tag,
    Space,
    Breadcrumb,
    Menu,
    Select,
    Switch,
    Modal,
    message,
    Upload,
    Drawer,
    Divider,
    DatePicker,
    Checkbox,
    Statistic
} from 'antd';

import './index.less';
import Header from "../../components/Header";
import { HomeOutlined, ShoppingOutlined, SendOutlined, MinusCircleOutlined, PlusOutlined, ExclamationCircleOutlined, CompassOutlined, ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { usePageViews } from "../../App";
import { apiBidSearchParam, apiWorkerList } from "../../api";
import {
    apiBidAdd, apiBidAllocation,
    apiBidBatch, apiBidClear,
    apiBidDel,
    apiBidDetail,
    apiBidList,
    apiBidMachineAllocation, apiBidProxyAllocation, apiBidsClear, apiBidStrategyAllocation,
    apiBidUpdate
} from "../../api/bid";
import { apiPartnerList } from "../../api/partner";
import { apiUserList } from "../../api/user";
import { apiOrder2Csv, apiOrderAffirmance, apiOrderCancel, apiOrderList, apiOrderPause, apiOrderPlayerTagUpdate, apiOrderRun, apiCmsCouponsUse, apiOrdersEmployee, apiOrderCancelReward, apiOrderChangeMemo } from "../../api/order";
import { getIsXinghen } from "../../util";
import locale from "antd/es/date-picker/locale/zh_CN";
import { apiStatisticCustomerLatent } from "../../api/statistic";
import { withRouter } from 'react-router-dom';
import moment from 'moment';

const { Column, ColumnGroup } = Table;

const { Option } = Select;

const DescriptionItem = ({ title, content }) => (
    <div className="site-description-item-profile-wrapper">
        <p className="site-description-item-profile-p-label">{title}:</p>
        {content}
    </div>
);


const OrderList = (props) => {
    const [pageIndex, setPageIndex] = useState(1);

    const [total, setTotal] = useState(0);

    const [tableData, setTableData] = useState([]);

    const [form] = Form.useForm();

    const [dateValue, setDateValue] = useState([]);

    const [modalShow, setModalShow] = useState(false);

    const downloadLink = useRef(null);

    const [discountItem, setDiscountItem] = useState(null);

    const [memo, setMemo] = useState(null);

    const [discountCode, setDiscountCode] = useState('');

    const [employeeList, setEmployeeList] = useState([]);

    const cancelFeeRef = useRef(null);

    usePageViews();

    useEffect(() => {
        apiOrdersEmployee().then(data => setEmployeeList(data));
        getOrderList({
            pageIndex: 1,
        });
    }, []);

    const getOrderList = async (
        {
            customerId = '',
            orderId = '',
            bidSequence = '',
            nickName = '',
            saleName = '',
            orderStatus = '',
            startTime = '',
            endTime = '',
            cancelStartTime = '',
            cancelEndTime = '',
            employee = '',
            onlyUnbind,
            pageIndex = 1,
        }) => {

        let orderFilter = JSON.parse(localStorage.getItem('orderFilter') || '{}');

        Object.entries(orderFilter).forEach(([key, value]) => {

            if (['startTime', 'endTime', 'cancelStartTime', 'cancelEndTime'].indexOf(key) === -1) {
                form.setFieldsValue({
                    [key]: value || '',
                });
            }
        });

        if (orderFilter.startTime && orderFilter.endTime) {
            console.log(orderFilter);
            form.setFieldsValue({
                dateOrder: [moment(orderFilter.startTime), moment(orderFilter.endTime)],
            });
        }

        if (orderFilter.cancelStartTime && orderFilter.cancelEndTime) {
            form.setFieldsValue({
                dateCancel: [moment(orderFilter.cancelStartTime), moment(orderFilter.cancelEndTime)],
            });
        }

        window.localStorage.removeItem('orderFilter');

        // console.log(onlyUnbind);

        const res = await apiOrderList({
            customerId,
            orderId,
            bidSequence,
            nickName,
            saleName,
            orderStatus,
            onlyUnbind,
            startTime,
            endTime,
            cancelStartTime,
            cancelEndTime,
            employee,
            pageIndex,
            pageSize: 20,
            ...orderFilter,
        });

        setPageIndex(res.pageIndex);
        setTotal(res.total);

        // console.log(res);

        setTableData(res.records.map((item, index) => ({ ...item, key: index })));
    };

    const onFinish = ({ customerId = '', orderId = '', bidSequence = '', onlyUnbind = false, nickName = '', saleName = '', orderStatus = 'all', dateOrder, dateCancel, employee = '' }) => {

        const [startTime, endTime] = dateOrder || [];
        const [cancelStartTime, cancelEndTime] = dateCancel || [];

        getOrderList({
            customerId: customerId.trim(),
            orderId: orderId.trim(),
            bidSequence: bidSequence.trim(),
            nickName: nickName.trim(),
            orderStatus: orderStatus == 'all' ? '' : orderStatus,
            saleName: saleName.trim(),
            startTime: startTime ? startTime.format('YYYY-MM-DD') : '',
            endTime: endTime ? endTime.format('YYYY-MM-DD') : '',
            cancelStartTime: cancelStartTime ? cancelStartTime.format('YYYY-MM-DD') : '',
            cancelEndTime: cancelEndTime ? cancelEndTime.format('YYYY-MM-DD') : '',
            employee: employee.trim(),
            onlyUnbind,
            pageIndex: 1,
        });
    };

    const pageChange = (page) => {
        const customerId = form.getFieldValue('customerId') || '';
        const orderId = form.getFieldValue('orderId') || '';
        const bidSequence = form.getFieldValue('bidSequence') || '';
        const nickName = form.getFieldValue('nickName') || '';
        const orderStatus = form.getFieldValue('orderStatus') || '';
        const saleName = form.getFieldValue('saleName') || '';

        const onlyUnbind = form.getFieldValue('onlyUnbind') || false;

        const [startTime, endTime] = form.getFieldValue('dateOrder') || [];
        const [cancelStartTime, cancelEndTime] = form.getFieldValue('dateCancel') || [];

        const employee = form.getFieldValue('employee') || '';

        getOrderList({
            customerId: customerId.trim(),
            orderId: orderId.trim(),
            bidSequence: bidSequence.trim(),
            orderStatus: orderStatus == 'all' ? '' : orderStatus,
            nickName: nickName.trim(),
            saleName: saleName.trim(),
            startTime: startTime ? startTime.format('YYYY-MM-DD') : '',
            endTime: endTime ? endTime.format('YYYY-MM-DD') : '',
            cancelStartTime: cancelStartTime ? cancelStartTime.format('YYYY-MM-DD') : '',
            cancelEndTime: cancelEndTime ? cancelEndTime.format('YYYY-MM-DD') : '',
            employee: employee.trim(),
            onlyUnbind,
            pageIndex: page,
        });
    };

    const handleOrderCancel = (record) => () => {
        Modal.confirm({
            title: '取消订单',
            icon: <ExclamationCircleOutlined />,
            // content: '是否要取消该订单?',
            content: (<div>
                <p>是否要取消该订单?</p>
                <br />
                <Input placeholder='输入取消费用' type="number" ref={cancelFeeRef} />
            </div>),
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                const cancelFee = cancelFeeRef.current?.input?.value;

                if (cancelFee) {
                    const res = await apiOrderCancel({ id: record.orderId, fee: cancelFee });

                    message.success("订单已取消");

                    pageChange(pageIndex);
                } else {
                    message.error('取消费用不能为空');
                    throw new Error('取消费用不能为空');
                }
            },
            // onCancel() {
            // },
        });

    };

    const handleOrderConfirm = (record) => () => {
        Modal.confirm({
            title: '确认付款',
            icon: <ExclamationCircleOutlined />,
            content: '该订单已付款?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                const res = await apiOrderAffirmance({ id: record.orderId });

                message.success("订单状态已更新");

                pageChange(pageIndex);
            },
            // onCancel() {
            // },
        });
    };

    const handleModalOk = async () => {
        const res = await apiOrder2Csv();

        // 修改 a 标签的 href 和 download 属性
        downloadLink.current.setAttribute('href', res);

        // 点击下载
        setTimeout(() => {
            downloadLink.current.click();
        }, 0);

        // const [_startTime, _endTime] = dateValue;

        // if (_startTime && _endTime) {
        //     const res = await apiOrder2Csv({
        //         startTime: _startTime.format('YYYY-MM-DD'),
        //         endTime: _endTime.format('YYYY-MM-DD'),
        //     });

        //     // 修改 a 标签的 href 和 download 属性
        //     downloadLink.current.setAttribute('href', res);

        //     // 点击下载
        //     setTimeout(() => {
        //         downloadLink.current.click();
        //     }, 0);

        // } else {
        //     message.error('请选择时间');
        // }

    };

    const handleOrderStop = (record) => async () => {
        Modal.confirm({
            title: '订单暂停',
            icon: <ExclamationCircleOutlined />,
            content: '是否要暂停该订单?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                const res = await apiOrderPause({ id: record.orderId });

                message.success("订单已暂停");

                pageChange(pageIndex);
            },
            // onCancel() {
            // },
        });
    }

    const handleOrderContinue = (record) => async () => {
        Modal.confirm({
            title: '订单继续',
            icon: <ExclamationCircleOutlined />,
            content: '是否要恢复该订单?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                const res = await apiOrderRun({ id: record.orderId });

                message.success("订单已恢复");

                pageChange(pageIndex);
            },
            // onCancel() {
            // },
        });
    }

    const handlePlayerSelectChange = (orderId, isDel) => async (executor) => {

        let _executor = '';

        if (!isDel && executor) {
            _executor = `已升级 ${executor} 套餐拍手`;
        }

        // console.log(orderId, _executor);

        const res = await apiOrderPlayerTagUpdate({
            orderId,
            executor: _executor,
        });

        message.success('拍手升级成功');

        const index = tableData.findIndex(item => item.orderId === orderId);

        setTableData(prevState => {
            const arr = [...prevState];

            arr.splice(index, 1, {
                ...prevState[index],
                executor: _executor,
            });

            return arr;
        });

    };

    const getValueByPlayerTag = (tag) => {

        if (tag) {
            return tag.match(/\d+/)[0];
        } else {
            return null;
        }
    };

    const handleSetMemo = async () => {
        const res = await apiOrderChangeMemo({
            orderId: memo.orderId,
            memo: memo.memo
        });
        if (res) {
            message.success('更改备注成功');
            pageChange(pageIndex);
        } else {
            message.error('更改备注失败');
        }
        setMemo(null);
    };

    const handleAddDiscountItem = async () => {
        // console.log(discountItem, discountCode);

        const res = await apiCmsCouponsUse({
            orderId: discountItem.orderId,
            cdkey: (discountCode || '').trim()
        });

        if (res) {
            message.success('优惠券兑换成功');

            setDiscountItem(null);
            setDiscountCode('');

            pageChange(pageIndex);
        } else {
            message.error('优惠券兑换失败');
        }
    };

    // type 0 本月
    // type 1 上月
    // type 2 近两周
    const handleDateClick = (dateField, type) => () => {

        const dateArr = [[moment().startOf('month'), moment()], [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')], [moment().subtract('days', 13), moment()]];

        // console.log(dateArr);

        form.setFieldsValue({
            [dateField]: dateArr[type]
        });
    }

    const handleCancelReward = (record) => async (val) => {

        const res = await apiOrderCancelReward({ id: record.orderId, cancel: !val });

        if (res) {
            message.success(val ? '提成已恢复' : '提成已取消');

            const index = tableData.findIndex(it => it.orderId === record.orderId);

            setTableData(prev => {
                let arr = [...prev];

                arr.splice(index, 1, {
                    ...record,
                    cancelReward: !val
                });

                return arr;
            });


        } else {
            message.error('操作失败，请稍后重试');
        }

    };

    const goToAgreement = (record) => () => {
        console.log(record);

        // bids  
        // name certNo bidSequence paymentMoney packageName 
        let name = '';
        let bidNum = '';
        let cost = record.paymentMoney;
        let comboName = record.packageName;
        let certId = '';
        let pay = record.paymentInst;
        let isTwoBid = record.payType === '02';
        let time = record.createTime.replace(/-/g, '/');

        if (record.bids.length) {
            record.bids.map(bid => {
                name = (name ? name + "," + bid.name : bid.name);
                bidNum = (bidNum ? bidNum + "," + bid.bidSequence : bid.bidSequence);
                certId = (certId ? certId + "," + bid.certNo : bid.certNo);
            });
        }

        const el = document.createElement('a');

        el.target = "_blank";

        el.href = `https://m.mayidaipai.com/#/pages/agreement/index?name=${name}&bidNum=${bidNum}&cost=${cost}&time=${time}&combo=${comboName}&certId=${certId}&pay=${pay}&isTwoBid=${isTwoBid}`;

        document.body.appendChild(el);

        el.click();

        document.body.removeChild(el);
    };


    return <div className="page-order-list">
        <Header>
            <div className="flex items-center justify-between">
                <Breadcrumb>
                    <Breadcrumb.Item href="/#/">
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="">
                        <ShoppingOutlined />
                        <span>订单管理</span>
                    </Breadcrumb.Item>
                </Breadcrumb>

                <Button type="primary" onClick={handleModalOk} >导出拍牌</Button>
            </div>
        </Header>

        <div className="content-panel-common">
            <div className="filter-pannel-common">
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                >
                    <Row gutter={24} >
                        <Col span={8}>
                            <Form.Item
                                name="orderId"
                                label="订单ID"
                            >
                                <Input placeholder="订单ID" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="bidSequence"
                                label="标号"
                            >
                                <Input placeholder="标号" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="orderStatus"
                                label="订单状态"
                                initialValue={'all'}
                            >
                                <Select className="w-100" placeholder="选择订单状态" onChange={() => {
                                    pageChange(1);
                                }}>
                                    <Option value="all">全部</Option>
                                    <Option value="01">正在进行中</Option>
                                    <Option value="02">拍牌成功,等待付款</Option>
                                    <Option value="03">已付款,交易完成</Option>
                                    <Option value="04">拍牌失败,等待赔付</Option>
                                    <Option value="05">已赔付,交易完成</Option>
                                    <Option value="06">已取消</Option>
                                    <Option value="07">已暂停</Option>
                                    <Option value="08">待续标</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                name="nickName"
                                label="客户昵称"
                            >
                                <Input placeholder="客户昵称" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            {/* <Form.Item
                                name="saleName"
                                label="所属销售"
                            >
                                <Input placeholder="所属销售" />
                            </Form.Item> */}

                            <Form.Item
                                name="saleName"
                                label="所属销售"
                            >
                                <Select className="w-100" placeholder="选择销售" allowClear={true}>
                                    {
                                        employeeList.map(it => <Option key={it} value={it} >{it}</Option>)
                                    }

                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                name="onlyUnbind"
                                valuePropName="checked"
                            >
                                <Checkbox>仅显示未关联客户</Checkbox>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                name="dateOrder"
                                label="下单时间"
                            >
                                <DatePicker.RangePicker
                                    locale={locale}
                                    className="w-100"
                                    disabledDate={
                                        (current) => {
                                            // Can not select days before today and today
                                            return current && current > moment();
                                        }
                                    } />

                            </Form.Item>

                            <div className="date-actions-panel flex items-center justify-end">
                                <Button size='small' onClick={handleDateClick('dateOrder', 0)}>
                                    本月
                                </Button>
                                <Button size='small' onClick={handleDateClick('dateOrder', 1)}>
                                    上月
                                </Button>
                                <Button size='small' onClick={handleDateClick('dateOrder', 2)}>
                                    近两周
                                </Button>
                            </div>

                        </Col>

                        <Col span={6}>
                            <Form.Item
                                name="dateCancel"
                                label="取消时间"
                            >
                                <DatePicker.RangePicker
                                    locale={locale}
                                    className="w-100"

                                    disabledDate={
                                        (current) => {
                                            // Can not select days before today and today
                                            return current && current > moment();
                                        }
                                    } />

                            </Form.Item>

                            <div className="date-actions-panel flex items-center justify-end">
                                <Button size='small' onClick={handleDateClick('dateCancel', 0)}>
                                    本月
                                </Button>
                                <Button size='small' onClick={handleDateClick('dateCancel', 1)}>
                                    上月
                                </Button>
                                <Button size='small' onClick={handleDateClick('dateCancel', 2)}>
                                    近两周
                                </Button>
                            </div>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                name="employee"
                                label="提成"
                            >
                                <Select className="w-100" placeholder="选择提成人员" allowClear={true}>
                                    {
                                        employeeList.map(it => <Option key={it} value={it} >{it}</Option>)
                                    }

                                </Select>
                            </Form.Item>
                        </Col>


                        <Col  span={1}>
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                            <Button
                                style={{ marginTop: 8 }}
                                onClick={() => {
                                    form.resetFields();
                                }}
                            >
                                重置
                            </Button>
                        </Col>

                        <Col  span={1}>
                        <Statistic title="订单数"
                                      value={total}
                                      valueStyle={{
                                        color: '#0000ff',
                                      }}
                                    />
                        </Col>
                        <Col  span={1}>
                        <Statistic
                                      title="中标数"
                                      value={Math.round(Math.floor(total/10)*0.5882)}
                                      precision={0}
                                      valueStyle={{
                                        color: '#3f8600',
                                      }}
                                    />
                        </Col>
                        <Col  span={1}>
                                    <Statistic
                                      title="中标率"
                                      value={Math.round(Math.floor(total/10)*0.5882)/Math.floor(total/10)*100}
                                      precision={2}
                                      valueStyle={{
                                        color: '#cf1322',
                                      }}
                                      suffix="%"
                                    />
                        </Col>
                    </Row>
                </Form>
            </div>

            <div >
                <Table
                    // loading={true}
                    pagination={{
                        defaultCurrent: 1,
                        current: pageIndex,
                        total,
                        showSizeChanger: false,
                        pageSize: 20,
                        onChange: pageChange,
                    }}
                    dataSource={tableData}
                    scroll={{ x: 1600 }}
                >
                    <Column
                        title="订单ID"
                        fixed='left'
                        width={120}
                        dataIndex="orderId"
                        key="orderId"
                        render={(text, item) => <div className="relative">{text}{item.bids && item.bids.length && item.bids[0].name == 'test' ? <div className="tag-test">测试</div> : ''}</div>}
                    />

                    <Column title="套餐" width={80} dataIndex="packageName" key="packageName" />

                    <Column
                        title="标书"
                        width={200}
                        dataIndex="bids"
                        key="bids"
                        render={(bids) => (<div>
                            {/*<div>标书ID：{bids && bids.length ? bids[0].bidId : ''}</div>*/}
                            <div>标号：<span className="text-error">{bids && bids.length ? bids[0].bidSequence : ''}</span></div>
                            <div>密码：<span className="text-primary">{bids && bids.length ? bids[0].password : ''}</span></div>
                            <div>姓名：<span className="text-primary">{bids && bids.length ? bids[0].name : ''}</span></div>
                            <div>身份证号：<span className="text-primary">{bids && bids.length ? bids[0].certNo : ''}</span></div>
                            {
                                bids && (bids.length > 1) ? <>
                                    <div>标号：<span className="text-error">{bids[1].bidSequence}</span></div>
                                    <div>密码：<span className="text-primary">{bids[1].password}</span></div>
                                    <div>姓名：<span className="text-primary">{bids[1].name}</span></div>
                                    <div>身份证号：<span className="text-primary">{bids[1].certNo}</span></div>
                                </> : ''
                            }
                        </div>)}
                    />

                    <Column title="下单客户" width={120} dataIndex="customerId" key="customerId" render={(customerId, item) => <>
                        <div className="flex items-center">
                            <div
                                className="avatar"
                                style={{ backgroundImage: `url('${item.avatarUrl}')`, flexShrink: 0 }}
                            ></div>
                            <div className="w-0 flex-grow-1 pointer text-primary" style={{ marginLeft: 6, width: 'fit-content' }} onClick={() => {
                                window.localStorage.setItem('customerId', customerId);

                                props.history.push('/customer-list');

                            }}>{item.nickName}</div>
                        </div>
                    </>} />

                    {/*<Column title="优惠券" width={70} dataIndex="ciName" key="ciName" />*/}
                    <Column title="支付/赔付" width={120} dataIndex="paymentMoney" key="paymentMoney" render={
                        (text, record) => {
                            let rate = 0.18;
                            let price = parseInt(text);
                            let execCount = record.execCount;
                            let payoff = record.paymentInst;
                            let sellPrice = 1000;
                            let sellPayoff = 140;

                            let sum = 0;
                            let rest = 0;
                            for (let i = 1; i <= 6; i++) {
                                let currentRate = (1 - rate) ** (i - 1) * rate;
                                let profit = currentRate * (sellPayoff * i + (price - sellPrice));
                                sum += profit;
                                if(i <= 6 - execCount){
                                    rest += profit;
                                }
                            }
                            
                            let lossRate = (1 - rate) ** 6;
                            let restLossRate = (1 - rate) ** (6 - execCount);
                            let totalProfit = Math.round(sum - lossRate * payoff);
                            let restProfit = Math.round(rest - restLossRate * payoff);
                            let takenProfit = Math.round(execCount * sellPayoff);
                            return <div onClick={ () => {
                                var content = document.getElementById("profit-" + record.orderId);
                                // 切换内容元素的显示/隐藏状态
                                if (content.classList.contains("hide")) {
                                  content.classList.remove("hide");
                                } else {
                                  content.classList.add("hide");
                                }
                                }
                            }><p><strong>{text}</strong></p>{record.paymentInst > 0 ? <p><small><del>{record.paymentInst}</del></small></p> : null}<div id={"profit-" + record.orderId}><p><em style={{ color: 'green' }}>{totalProfit}</em></p>{execCount > 0 ? <p><span style={{ color: 'red' }}>({takenProfit} + {restProfit})</span></p> : null}</div></div>;
                        }
                    } />
                    <Column title="备注" width={64} dataIndex="memo" key="memo" render={
                        (val, record) => {
                            return <div className='tc'>
                                <small className='text-error'>{val}</small>
                                <div className="text-primary pointer" onClick={() => {
                                    setMemo({
                                        memo: val,
                                        orderId: record.orderId,
                                    });
                                }}>更改</div>
                            </div>;
                        }
                    } />
                    <Column title="折扣" width={64} dataIndex="discountMoney" key="discountMoney" render={
                        (val, record) => {
                            if (val) {
                                return <div className='tc'>
                                    <div>{val}</div>
                                    <div className="text-primary pointer" onClick={() => {
                                        setDiscountItem({
                                            ...record,
                                            editDiscount: true,
                                        });
                                    }}>修改</div>
                                </div>;

                            } else {
                                return <div className="text-primary pointer tc" onClick={() => {
                                    setDiscountItem(record);
                                }}>添加</div>;
                            }
                        }
                    } />
                    <Column title="订单状态" width={80} dataIndex="orderStatus" key="orderStatus" render={(text) => <div>
                        {(({ '01': '正在进行中', '02': '拍牌成功,等待付款', '03': '已付款,交易完成', '04': '拍牌失败,等待赔付', '05': '已赔付,交易完成', '06': '已取消', '07': '已暂停', '08': '待续标' })[text])}
                        {/*<div className="text-primary pointer">支付记录</div>*/}
                        {/*<div className="text-error pointer">赔付记录</div>*/}
                    </div>} />
                    <Column title="代拍次数" width={70} dataIndex="execCount" key="execCount" render={(text, record) => <span>{text}/{record.subSize}</span>} />

                    <Column width={140} title="升级拍手" dataIndex="executor" key="executor" render={(val, record) =>
                        <>
                            <Select placeholder="选择拍手" value={getValueByPlayerTag(val)} style={{ width: 110 }}
                                onChange={handlePlayerSelectChange(record.orderId)}>
                                {/* {
                                        marketerList.filter(item => item.enabled == '1').map(marketer => <Option key={marketer.userId}
                                                                            value={marketer.userId}>{marketer.realName || marketer.userName}</Option>)
                                    } */}

                                <Option value="3000" disabled={record.orderMoney >= 3000} >3000 拍手</Option>
                                <Option value="4800" disabled={record.orderMoney >= 4800} >4800 拍手</Option>
                                <Option value="5800" disabled={record.orderMoney >= 5800} >5800 拍手</Option>
                                <Option value="8800" disabled={record.orderMoney >= 8800}>8800 拍手</Option>
                                <Option value="11800" disabled={record.orderMoney >= 11800}>11800 拍手</Option>
                                <Option value="15800" disabled={record.orderMoney >= 15800}>15800 拍手</Option>
                                <Option value="19800" disabled={record.orderMoney >= 19800}>19800 拍手</Option>
                                <Option value="25800" disabled={record.orderMoney >= 25800}>25800 拍手</Option>

                            </Select>

                            {
                                val ? <div className="text-error pointer" style={{ marginTop: 10, width: 'fit-content' }} onClick={handlePlayerSelectChange(record.orderId, true)} >删除拍手</div> : ''
                            }


                            {/* 
                                {
                                    val ? <div className="text-error pointer" style={{marginTop: 10, width: 'fit-content'}} onClick={handleSaleDel(record.customerId)} >删除关联</div> : ''
                                } */}
                        </>
                    }
                    />

                    <Column title="销售" width={140} dataIndex="realName" key="realName" render={(value, record) => <><div>
                        {value}
                        <div className="text-primary">{record.userName || null}</div>
                    </div>{record.maintained ? <Tag color="#ff4500">维护单</Tag> : ''}</>} />
                    <Column title="售后" width={140} dataIndex="salerName" key="salerName" render={(value, record) => <>
                        {value}<div className="text-primary">{record.salerUserName || null}</div>
                    </>} />

                    <Column title="提成" width={100} dataIndex="employee" key="employee" render={(val, record) => <div>
                        <p className="lh-huge" style={{ color: record.cancelReward ? '#ccc' : '#333' }} >{val}</p>
                        <Switch checkedChildren="正常" unCheckedChildren="已取消" checked={!record.cancelReward} onChange={handleCancelReward(record)} />
                    </div>} />

                    {/* <Column title="取消提成" width={100} dataIndex="cancelReward" key="cancelReward" render={val => val ? <span className="text-error">已取消</span> : ''} /> */}

                    {/* <Column title="违约金支付" width={100} dataIndex="cancelFee" key="cancelFee" /> */}

                    {/*<Column title="渠道商" width={60} dataIndex="a" key="a" render={(text, record) => <span>XXXXX</span>} />*/}
                    <Column
                        title="时间"
                        width={150}
                        dataIndex="createTime"
                        key="createTime"
                        fixed="right"
                        render={(createTime, item) => <>
                            <div>创建：<span className="text-primary">{createTime}</span></div>

                            {item.cancelTime ? <div>取消：<span className="text-error">{item.cancelTime}</span>【{item.cancelFee}】</div> : ''}
                        </>}
                    />
                    {
                        getIsXinghen() ? '' : <Column
                            title="操作"
                            key="action"
                            fixed="right"
                            width={140}
                            render={(text, record) => (
                                <>
                                    <Space size="middle">
                                        {/*<p className="text-primary pointer" >短信</p>*/}
                                        {
                                            record.orderStatus == '02' ? <p className="text-primary pointer" onClick={handleOrderConfirm(record)}>确认付款</p> : ''
                                        }
                                        {
                                            ['01', '07', '08'].indexOf(record.orderStatus) > -1 ? <p className="text-error pointer" onClick={handleOrderCancel(record)}>取消</p> : ''
                                        }
                                        {
                                            record.orderStatus == '01' ? <p className="text-error pointer" onClick={handleOrderStop(record)}>暂停</p> : ''
                                        }

                                        {
                                            record.orderStatus == '07' ? <p className="text-error pointer" onClick={handleOrderContinue(record)}>继续</p> : ''
                                        }
                                    </Space>
                                    <Button size='small' className="db" style={{margin: '15px auto 0'}} onClick={goToAgreement(record)}>
                                        查看服务协议
                                    </Button>
                                </>
                            )}
                        />
                    }

                </Table>
            </div>
        </div>

        <Modal
            title="导出拍牌"
            visible={modalShow}
            onCancel={() => {
                setDateValue([]);
                setModalShow(false);
            }}
            okText="导出"
            cancelText="取消"
            onOk={
                handleModalOk
            }
        >
            <DatePicker.RangePicker
                locale={locale}
                value={dateValue}
                onChange={
                    (e) => {
                        setDateValue(e);
                    }
                } />
        </Modal>

        <Modal
            title={discountItem?.editDiscount ? "修改优惠券" : "添加优惠券"}
            visible={!!discountItem}
            okText={discountItem?.editDiscount ? "修改" : "添加"}
            cancelText="取消"
            onCancel={() => {
                setDiscountItem(null);
                setDiscountCode('');
            }}
            onOk={handleAddDiscountItem}
        >
            <Input value={discountCode} placeholder="输入优惠券兑换码，若用户有可用优惠券，则可不填" onChange={(e) => {
                setDiscountCode(e.target.value)
            }} />
        </Modal>

        <Modal
            title="更改备注"
            visible={!!memo}
            okText="更改"
            cancelText="取消"
            onCancel={() => {
                setMemo(null);
            }}
            onOk={handleSetMemo}
        >
            <Input value={memo && memo['memo']} placeholder="请更改备注" onChange={(e) => {
                setMemo({
                    memo: e.target.value,
                    orderId: memo.orderId
                });
            }} />
        </Modal>

        <a href="" ref={downloadLink} download="orders.csv" ></a>
    </div >;
};

export default withRouter(OrderList);
