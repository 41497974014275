// 策略接口
import $fetch from "./fetch";

// 获取策略配置列表
export const apiStrategyConfigAll= () => {
    return $fetch('/strategy_config/all', {
        method: 'GET',
    });
}


// 更新策略配置
export const apiStrategyConfigUpdate = ({ name, key, content }) => {
    return $fetch('/strategy_config/config', {
        method: 'PUT',
        params: {
            name,
            key,
            content,
        }
    });
}

// 更新策略配置
export const apiStrategyConfigAllocation = () => {
    return $fetch('/strategy_config/allocation', {
        method: 'POST',
    });
}

// 删除策略配置
export const apiStrategyConfigDel = (key) => {
    return $fetch(`/strategy_config/config/${key}`, {
        method: 'DELETE',
    });
};

export const apiStrategyList = ({bidNumber = '', pageIndex = 1, pageSize = 10}={pageIndex: 1, pageSize: 10}) => {
    return $fetch('/strategies/page', {
        method: 'GET',
        params: {
            bidSeq: bidNumber,
            pageIndex,
        }
    });
};

export const apiStrategyUpdate = ({id, long, short, force, price}) => {
    return $fetch('/strategies/strategy', {
        method: 'PUT',
        params: {
            id,
            price,
            conf: {
                long,
                short,
                force,
            }
        }
    });
};

// 清空标书
export const apiStrategyAllocationDel = () => {
    return $fetch('/strategies/allocation', {
        method: 'DELETE',
    });
};

// 导入标书策略
export const apiBidsStrategyBatch = ({ csv }) => {
    console.log(3);
    return $fetch('/bids/batch_strategy', {
        method: 'POST',
        params: {
            csv,
        }
    });
};
