// 代理管理接口
import $fetch from "./fetch";

// 新增标书
export const apiProxyBatch = ({ csv }) => {
    return $fetch('/proxies/batch', {
        method: 'POST',
        params: {
            csv,
        }
    });
}

// 新增代理
export const apiProxyAdd = ({ host, port, userName, password, capacity, partnerName }) => {
    return $fetch('/proxies/proxy', {
        method: 'POST',
        params: {
            host,
            port,
            userName,
            password,
            capacity,
            partnerName,
        }
    });
}

// 更新代理
export const apiProxyUpdate = ({ id, host, port, userName, password, capacity, partnerName }) => {
    return $fetch("/proxies/proxy", {
        method: 'PUT',
        params: {
            id,
            host,
            port,
            userName,
            password,
            capacity,
            partnerName
        }
    });
}

// 代理列表
export const apiProxyList = ({ proxyId, partnerName, pageIndex = 1, pageSize = 10 } = {blurSearch: false, pageIndex: 1, pageSize: 10}) => {
    return $fetch('/proxies/page', {
        method: 'GET',
        params: {
            proxyId,
            partnerName,
            pageIndex,
            pageSize,
        }
    });
};


// 代理删除
export const apiProxyDel = ({ id }) => {
    return $fetch(`/proxies/proxy/${id}`, {
        method: 'DELETE',
    });
}

// 分配代理
export const apiProxyAllocation = () => {
    return $fetch('/proxies/allocation', {
        method: 'POST',
    });
};

// 清空代理
export const apiProxyAllocationDel = () => {
    return $fetch('/proxies/allocation', {
        method: 'DELETE',
    });
};


// 添加飞猪代理
export const apiProxyFlyAdd = ({address = '', count}) => {
    return $fetch('/proxies/feizhu', {
        method: 'POST',
        params: {
            address,
            count,
        }
    });
};


export const apiProxiesClear = () => {
    return $fetch('/proxies/unavailable', {
        method: 'DELETE',
    });
}
