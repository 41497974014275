import React, {useEffect, useState} from 'react';

import {
    HashRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from 'react-router-dom';
import { Provider } from 'react-redux'

import {Layout, Switch as ASwitch} from "antd";

import PageHome from './pages/Home';
import PageLogin from './pages/Auth/Login';

import './assets/style/common.less';

import './App.less'

import Home from "./pages/Home";
import Login from "./pages/Auth/Login";

import SiderMenu from './components/SiderMenu';
import BidList from "./pages/BidList";
import OrderList from "./pages/OrderList";
import StrategySettings from "./pages/StrategySettings";
import PackageList from "./pages/PackageList";
import ProxyList from "./pages/ProxyList";
import UserList from "./pages/UserList";
import CustomerList from "./pages/CustomerList";
import RoleList from "./pages/RoleList";
import Personal from "./pages/Personal";
import Settings from "./pages/Settings";
import PotentialList from "./pages/PotentialList";
import CouponSettings from './pages/CouponSettings';
import QAList from './pages/QAList';
import InfoAnalysis from './pages/InfoAnalysis';
import CommentList from './pages/CommentList';
import PayList from './pages/PayList';
import TaskList from './pages/TaskList';
import store from './store';
import TaskListSpec from "./pages/TaskListSpec";
import ComboList from "./pages/ComboList";
import Salary from './pages/Salary';
import Pictures from './pages/Pictures';

const { Footer, Sider, Content } = Layout;

export const appTheme = 'light';

export const usePageViews = () => {
    let location = useLocation();
    useEffect(() => {
        if (location.pathname !== '/login' && !localStorage.getItem('userInfo')) {
            window.location.href = '/#/login';
        }
    }, [location]);
}

const App = () => {

    const [isTest, setIsTest] = useState(!!localStorage.getItem('isTest'));

    return (
      <Provider store={store}>
          <Router>
              <Switch>
                  <Route path="/login">
                      <Login />
                  </Route>

                  {/*<Route path="/personal">*/}
                  {/*    <div>*/}
                  {/*        个人中心*/}
                  {/*    </div>*/}
                  {/*</Route>*/}

                  <Route path="/">
                      <Layout className="app-layout">
                          <Sider className="app-sider" width={200} theme={appTheme}>
                              <div className="test-wrap flex items-center" style={{marginRight: 10}}>
                                  <div style={{marginRight: 10}}>开启测试模式</div>
                                  <ASwitch checked={isTest} onChange={(e) => {
                                      setIsTest(e);
                                      window.localStorage.setItem('isTest', e ? 'true' : '');
                                      window.localStorage.removeItem('userInfo');
                                      window.location.reload();
                                  }} />
                              </div>
                              <div className="sider-header flex items-center">
                                  <div className="logo"></div>
                                  <div>
                                      <p className="fs-18 lh-md">蚂蚁代拍</p>
                                      <p className="fs-12 lh-sm text-black-secondary">运营管理后台</p>
                                  </div>
                              </div>
                              <SiderMenu />
                          </Sider>
                          <Layout className="app-layout-right">
                              <Content>
                                  <Switch>
                                      <Route path="/settings">
                                          <Settings />
                                      </Route>
                                      <Route path="/personal">
                                          <Personal />
                                      </Route>
                                      <Route path="/bid-list">
                                          <BidList />
                                      </Route>
                                      <Route path="/order-list">
                                          <OrderList />
                                      </Route>
                                      <Route path="/strategy-settings">
                                          <StrategySettings />
                                      </Route>
                                      <Route path="/package-list">
                                          <PackageList />
                                      </Route>
                                      <Route path="/proxy-list">
                                          <ProxyList />
                                      </Route>
                                      <Route path="/user-list">
                                          <UserList />
                                      </Route>
                                      <Route path="/customer-list">
                                          <CustomerList />
                                      </Route>
                                      <Route path="/potential-list">
                                          <PotentialList />
                                      </Route>
                                      <Route path="/role-list">
                                          <RoleList />
                                      </Route>
                                      <Route path="/coupon-settings">
                                          <CouponSettings />
                                      </Route>
                                      <Route path="/qa-list">
                                          <QAList />
                                      </Route>
                                      <Route path="/salary">
                                          <Salary />
                                      </Route>
                                      <Route path="/info-analysis">
                                          <InfoAnalysis />
                                      </Route>
                                      <Route path="/comment-list">
                                          <CommentList />
                                      </Route>
                                      <Route path="/pay-list">
                                          <PayList />
                                      </Route>
                                      <Route path="/task-list">
                                          <TaskList />
                                      </Route>
                                      <Route path="/task-list-spec">
                                          <TaskListSpec />
                                      </Route>
                                      <Route path="/combos">
                                          <ComboList />
                                      </Route>
                                      <Route path="/pictures">
                                          <Pictures />
                                      </Route>
                                      <Route path="/">
                                          <Home />
                                      </Route>
                                  </Switch>
                              </Content>
                              <Footer theme={appTheme}>
                                  © Copyright 上海星痕网络科技有限公司
                              </Footer>
                          </Layout>
                      </Layout>
                  </Route>
              </Switch>
          </Router>
      </Provider>
  );
};

export default App;
