// 用户管理接口
import $fetch from "./fetch";

// 新增用户
export const apiUserAdd = (
    {
        enabled,
        mobile,
        realName,
        userName,
        roleId,
        dingdingUrl,
        customerId,
    }) => {
    return $fetch('/api/cms/users', {
        method: 'POST',
        params: {
            enabled,
            mobile,
            realName,
            userName,
            roleId,
            dingdingUrl,
            customerId,
        }
    }, 
    false,
    true);
}

// 修改用户信息
export const apiUserUpdate = (
    {
        id,
        enabled,
        mobile,
        realName,
        userName,
        roleId,
        dingdingUrl,
        customerId,
        maintainerId,
    }) => {
    return $fetch(`/api/cms/users/${id}`, {
        method: 'PUT',
        params: {
            enabled,
            mobile,
            realName,
            userName,
            roleId,
            dingdingUrl,
            customerId,
            maintainerId,
        }
    },
    false,
    true);
}

// 重置密码
export const apiResetPwd = ({ id }) => {
    return $fetch(`/api/cms/users/${id}/password/reset`, {
        method: 'PUT',
    });
}

// 用户列表
export const apiUserList = ({ userName, roleName, userId, pageIndex = 1, pageSize = 50 } = {pageIndex: 1, pageSize: 50}) => {
    return $fetch('/api/cms/users/page', {
        method: 'GET',
        params: {
            userName,
            roleName,
            userId,
            pageIndex,
            pageSize,
        },
        noMessage: true
    });
};


