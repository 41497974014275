
import React, { useEffect, useState } from 'react';

import {Form, Row, Col, Input, Button, Table, Tag, Space, Breadcrumb, Menu, Modal, Select, message} from 'antd';

import './index.less';
import Header from "../../components/Header";
import { HomeOutlined, UserOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {usePageViews} from "../../App";
import {apiPartnerAdd, apiPartnerDel, apiPartnerList, apiPartnerUpdate} from "../../api/partner";
import {apiBidAdd, apiBidDel, apiBidDELETE, apiBidUpdate} from "../../api/bid";
import {Link} from "react-router-dom";
import {apiUserAdd, apiUserList, apiUserUpdate} from "../../api/user";
import {apiRoleList} from "../../api/role";
import FormItem from 'antd/lib/form/FormItem';
// import {apiWorkerList} from "../../api";

const { Column, ColumnGroup } = Table;

const UserList = () => {

    usePageViews();

    const [tableData, setTableData] = useState([]);

    const [expand, setExpand] = useState(false);

    const [form] = Form.useForm();

    const [formNew] = Form.useForm();

    const [editId, setEditId] = useState('');

    const [pageIndex, setPageIndex] = useState(1);

    const [total, setTotal] = useState(0);

    const [modalVisible, setModalVisible] = useState(false);

    const [modalConfirmLoading, setModalConfirmLoading] = useState(false);

    const [roleList, setRoleList] = useState([]);

    useEffect(() => {
        getUserList({
            name: '',
            pageIndex: 1,
        });

        getRoleList({
            pageIndex: 1,
        });
    }, []);

    useEffect(() => {

        if (!editId) {
            return;
        }

        const {userName, realName, mobile, roleId, enabled, dingdingUrl, customerId, maintainerId} = tableData.find(item => item.userId == editId);

        console.log(maintainerId);
        formNew.setFieldsValue({
            name: realName,
            alias: userName,
            phone: mobile,
            roleId,
            enabled,
            dingdingUrl,
            customerId,
            maintainerId: maintainerId || '0'
        });

    }, [editId]);

    const getRoleList = async ({
                                   pageIndex = 1,
                               }) => {
        const res = await apiRoleList({
            pageIndex,
            pageSize: 50,
        });


        setRoleList(res.records);
    };

    const getUserList = async ({
        id,
        name,
        pageIndex = 1,
    }) => {
        const res = await apiUserList({
            userName: name,
            userId: id,
            pageIndex
        });


        setPageIndex(res.pageIndex);
        setTotal(res.total);

        setTableData(res.records.map((item, index) => ({...item, key: index})));
    };

    const onFinish = ({name = '', id = ''}) => {
        getUserList({
            id: id.trim(),
            name: name.trim(),
            pageIndex: 1,
        });
    };

    const pageChange = (page) => {
        const name = form.getFieldValue('name') || '';
        const id = form.getFieldValue('id') || '';

        getUserList({
            id: id.trim(),
            name: name.trim(),
            pageIndex: page,
        });
    };

    const handleModalOk = async () => {
        try {
            let { name, alias, phone, roleId, enabled, dingdingUrl, customerId, maintainerId } = await formNew.validateFields();
            customerId = parseInt(customerId);

            console.log(name, alias, phone, roleId, enabled, dingdingUrl, customerId, maintainerId);

            setModalConfirmLoading(true);

            if (editId) {
                await apiUserUpdate({
                    id: editId,
                    mobile: phone,
                    realName: name,
                    userName: alias,
                    enabled,
                    roleId,
                    dingdingUrl,
                    customerId,
                    maintainerId: maintainerId || 0,
                });
            } else {
                await apiUserAdd({
                    mobile: phone,
                    realName: name,
                    userName: alias,
                    enabled: '1',
                    roleId,
                    dingdingUrl,
                    customerId,
                });
            }

            formNew.resetFields();
            setModalVisible(false);
            setModalConfirmLoading(false);

            message.success(`用户${editId ? '更新' : '添加'}成功`);

            setEditId(null);

            getUserList({
                pageIndex: 1,
            });

        } catch (e) {
            message.error(e.message);
            setModalConfirmLoading(false);
        }
    };

    const handleModalCancel = () => {
        setEditId(null);
        setModalVisible(false);
        formNew.resetFields();
    };

    const handleBtnNewClick = () => {
        setModalVisible(true);
    };

    const handleEditBtnClick = (id) => async () => {
        console.log(id);

        // const res = await apiBidUpdate()
        //

        setEditId(id);

        setModalVisible(true);
    };

    const handleDelBtnClick = (id) => async () => {

        Modal.confirm({
            title: '删除友商',
            icon: <ExclamationCircleOutlined />,
            content: '是否要删除该友商?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                await apiPartnerDel({id});

                message.success("友商已删除");

                pageChange(pageIndex);
            },
            // onCancel() {
            // },
        });

    };

    const handleUserEnable = (user, status) => async (e) => {
        const  { mobile, realName, userName, roleId} = user;
        await apiUserUpdate({
            id: user.userId,
            mobile,
            realName,
            userName,
            roleId,
            enabled: status,
        });

        message.success(status == '1' ? "已启用" : '已禁用');

        const index = tableData.findIndex(item => item.userId == user.userId);

        setTableData(prev => {
            let data = JSON.parse(JSON.stringify(tableData));

            data[index].enabled = status;

            return data;
        });
    };

    return <div className="page-home">
        <Header>
            <div className="flex items-center justify-between">
                <Breadcrumb>
                    <Breadcrumb.Item href="/#/">
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="">
                        <UserOutlined />
                        <span>微信管理</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div>
                    <Button
                        style={{margin: '0 15px'}}
                        shape="round"
                        icon={<PlusOutlined />}
                        onClick={handleBtnNewClick}
                    >
                        新增用户
                    </Button>
                </div>
            </div>

        </Header>

        <div className="content-panel-common">
            <div className="filter-pannel-common">
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                name="name"
                                label="微信昵称"
                            >
                                <Input placeholder="输入微信昵称" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="id"
                                label="ID"
                            >
                                <Input placeholder="输入ID" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                            <Button
                                style={{ marginLeft: 8 }}
                                onClick={() => {
                                    form.resetFields();
                                    getUserList({pageIndex: 1});
                                }}
                            >
                                重置
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div >
                <Table
                    // loading={true}
                    pagination={{
                        defaultCurrent: 1,
                        current: pageIndex,
                        total,
                        showSizeChanger: false,
                        pageSize: 50,
                        onChange: pageChange,
                    }}
                    dataSource={tableData}
                >
                    <Column title="ID" dataIndex="userId" key="userId" />
                    <Column title="微信昵称" dataIndex="userName" key="userName" render={(userName, record) => 
                        <>
                            <span style={{color: record.enabled == '1' ? '#333' : '#ccc'}}>{userName}</span>
                            {
                                record.enabled == '1' ? '' : <Tag color="#c51b71" style={{marginLeft: 8}}>停用</Tag>
                            }
                        </>} />
                    
                    <Column title="客户ID" dataIndex="customerId" key="customerId" />
                    <Column title="交付人" dataIndex="realName" key="realName" render={(text, item) => item.maintainerId > 0 ? <div>
                        <del className="text-error">{text}</del>
                            <div>
                                {item.maintainerId > 0 ? <span className="text-primary">{item.maintainerName}</span> : item.maintainerName}
                            </div>
                        </div> : <div>
                            {text}
                            <div>
                                {item.maintainerId > 0 ? <span className="text-primary">{item.maintainerName}</span> : item.maintainerName}
                            </div>
                        </div>} />
                    <Column title="交付时间" dataIndex="deliverTime" key="deliverTime" />
                    <Column title="角色" dataIndex="roleName" key="roleName" />

                    <Column
                        title="钉钉链接"
                        dataIndex="dingdingUrl"
                        key="dingdingUrl"
                        width={300}
                    />

                    <Column
                        title="操作"
                        key="action"
                        render={(text, record) => (
                            <Space size="middle">
                                <p className="text-primary pointer" onClick={handleEditBtnClick(record.userId)}>编辑</p>
                                {/*<p className="text-primary pointer" onClick={handleEditBtnClick(record.id)}>重置密码</p>*/}

                                {
                                    record.enabled == '1' ? <p className="text-error pointer" onClick={handleUserEnable(record, '0')}>
                                        停用
                                    </p> : <p className="text-success pointer" onClick={handleUserEnable(record, '1')}>
                                        启用
                                    </p>
                                }
                                {/*<p className="text-error pointer" onClick={handleDelBtnClick(record.id)}>删除</p>*/}
                            </Space>
                        )}
                    />
                </Table>
            </div>
        </div>

        <Modal
            title={editId ? '用户编辑' : '新增用户'}
            visible={modalVisible}
            onOk={handleModalOk}
            confirmLoading={modalConfirmLoading}
            onCancel={handleModalCancel}
            okText="确定"
            cancelText="取消"
            maskClosable={false}
        >
            <Form
                form={formNew}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 17 }}
                name="basic"
                // onFinish={onFinish}
            >
                <Form.Item
                    label="微信昵称"
                    name="alias"
                    rules={[{ required: true, message: '请输入微信昵称' }]}
                >
                    <Input maxLength={20} placeholder="请输入微信昵称" />
                </Form.Item>

                <Form.Item
                    label="交付人"
                    name="name"
                    rules={[{ required: true, message: '请输入交付人' }]}

                >
                    <Input maxLength={20} placeholder="请输入交付人" />
                </Form.Item>

                {/* {
                    editId ?  <Form.Item
                    label="代维护"
                    name="maintainerId"
                >
                    <Select placeholder="请选择代维护">
                        <Select.Option value="0">无</Select.Option>
                        {
                           (tableData || []).filter(item => (item.enabled == '1') && (item.userId !== editId)).map(marketer => <Select.Option key={marketer.userId} value={marketer.userId}>{marketer.realName || marketer.userName}</Select.Option>)
                        }
                    </Select>
                </Form.Item> : ''
                } */}

                <Form.Item
                    label="角色"
                    name="roleId"
                    rules={[{ required: true, message: '请选择角色' }]}
                >
                    <Select placeholder="请选择角色">
                        {
                            roleList.map(role => <Select.Option key={role.roleId} value={role.roleId}>{role.roleName}</Select.Option>)
                        }
                    </Select>
                </Form.Item>

                <Form.Item
                    label="钉钉链接"
                    name="dingdingUrl"
                >
                    <Input placeholder="请输入钉钉链接" />
                </Form.Item>

                <Form.Item
                    label="客户ID"
                    name="customerId"
                >
                    <Input placeholder="请输入客户ID" />
                </Form.Item>

                <Form.Item
                    className="hide"
                    label="启用"
                    name="enabled"
                >
                    <Input value="1" />
                </Form.Item>


                {/*<Form.Item*/}
                {/*    label="手机号"*/}
                {/*    name="phone"*/}
                {/*    rules={[{ required: true, message: '请输入友商手机号' }]}*/}
                {/*>*/}
                {/*    <Input />*/}
                {/*</Form.Item>*/}

            </Form>
        </Modal>

    </div>;
};

export default UserList;