// 套餐管理接口
import $fetch from "./fetch";


// 定制 套餐
export const apiComboSpecial = (
    {
        // 客户ID
        customerId,
        // 套餐ID
        packageId,
        // 折扣
        discount,
        // 额外赔付
        payoff,
    }
) => {
    return $fetch('/api/cms/packages/special', {
        method: 'POST',
        params: {
            customerId,
            packageId,
            discount,
            payoff,
        }
    });
}

// 新增 套餐
export const apiComboAdd = (
    {
        // 套餐名
        name,
        // 排序
        orderNum,
        // 单月或6月
        pay,
        // pay 信息
        payRemark,
        // 支付类型
        payType,
        // 套餐价格
        price,
        // productIds
        productIds,
        // 是否放首页推荐
        recommend,
        // 信息
        remark,
    }
) => {
    return $fetch('/api/cms/packages', {
        method: 'POST',
        params: {
            name,
            orderNum,
            pay,
            payRemark,
            payType,
            price,
            productIds: payType == '01' ? ['1001'] : ['1001', '1001'],
            recommend,
            remark,
        }
    });
}

// 更新 套餐
export const apiComboUpdate = (
    {
        id,
        // 套餐名
        name,
        // 排序
        orderNum,
        // 赔付
        pay,
        // pay 信息
        payRemark,
        // 支付类型（单月或6月）
        payType,
        // 套餐价格
        price,
        // productIds
        productIds,
        // 是否放首页推荐
        recommend,
        // 信息
        remark,
    }
) => {
    return $fetch(`/api/cms/packages/${id}`, {
        method: 'PUT',
        params: {
            name,
            orderNum,
            pay,
            payRemark,
            payType,
            price,
            productIds,
            recommend,
            remark,
        }
    });
}

// 删除 套餐
export const apiComboDel = (
    {
        id,
    }
) => {
    return $fetch(`/api/cms/packages/${id}`, {
        method: 'DELETE',
    });
}

// 套餐 列表
export const apiComboList = (
    {
        pageIndex,
        pageSize = 50,
    } = {
        pageIndex: 1,
        pageSize: 50,
    }
) => {
    return $fetch('/api/cms/packages/page', {
        method: 'GET',
        params: {
            pageIndex,
            pageSize: pageSize,
        }
    });
}