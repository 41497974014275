
import React, { FormEvent, useState, useEffect } from 'react';

import { Breadcrumb, Menu } from 'antd';
import { HomeOutlined, DollarCircleOutlined, PictureOutlined, LineChartOutlined, AntDesignOutlined, UserOutlined, PhoneOutlined, SafetyCertificateOutlined, RedEnvelopeOutlined, ClockCircleOutlined, ShoppingOutlined, HistoryOutlined, TeamOutlined, GiftOutlined, CommentOutlined, SettingOutlined, FileTextOutlined, GlobalOutlined } from '@ant-design/icons';
import { Link, useLocation } from "react-router-dom";
import { getIsXinghen } from "../../util";

const { SubMenu } = Menu;

const rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];

const SiderMenu = () => {

    const [activeMenu, setActiveMenu] = useState('');

    const location = useLocation();

    return <Menu
        mode="inline"
        selectedKeys={[location.pathname]}
    >
        <Menu.Item key="/">
            <Link to="/">
                <HomeOutlined />
                首页
            </Link>
        </Menu.Item>
        <Menu.Item key="/order-list">
            <Link to="/order-list">
                <ShoppingOutlined />
                订单管理
            </Link>
        </Menu.Item>

        <Menu.Item key="/bid-list">
            <Link to="/bid-list">
                <FileTextOutlined />
                标书管理
            </Link>
        </Menu.Item>
        <Menu.Item key="/customer-list">
            <Link to="/customer-list">
                <UserOutlined />
                客户管理
            </Link>
        </Menu.Item>

        {
            getIsXinghen() ? '' : <Menu.Item key="/potential-list">
                <Link to="/potential-list">
                    <PhoneOutlined />
                    潜在客户管理
                </Link>
            </Menu.Item>
        }

        <Menu.Item key="/salary">
            <Link to="/salary">
                <DollarCircleOutlined />
                提成结算
            </Link>
        </Menu.Item>

        {
            getIsXinghen() ? '' : <Menu.Item key="/role-list">
                <Link to="/role-list">
                    <SafetyCertificateOutlined />
                    角色管理
                </Link>
            </Menu.Item>
        }

        {
            getIsXinghen() ? '' : <Menu.Item key="/user-list">
                <Link to="/user-list">
                    <UserOutlined />
                    微信管理
                </Link>
            </Menu.Item>
        }

        <Menu.Item key="/info-analysis">
            <Link to="/info-analysis">
                <LineChartOutlined />
                信息统计
            </Link>
        </Menu.Item>

        <Menu.Item key="/coupon-settings">
            <Link to="/coupon-settings">
                <RedEnvelopeOutlined />
                优惠券管理
            </Link>
        </Menu.Item>

        <Menu.Item key="/qa-list">
            <Link to="/qa-list">
                <CommentOutlined />
                QA管理
            </Link>
        </Menu.Item>



        {
            getIsXinghen() ? '' : <Menu.Item key="/pay-list">
                <Link to="/pay-list">
                    <HistoryOutlined />
                    赔付记录管理
                </Link>
            </Menu.Item>
        }

        {
            getIsXinghen() ? '' : <Menu.Item key="/comment-list">
                <Link to="/comment-list">
                    <CommentOutlined />
                    评论管理
                </Link>
            </Menu.Item>
        }

        {
            getIsXinghen() ? '' : <Menu.Item key="/task-list">
                <Link to="/task-list">
                    <ClockCircleOutlined />
                    拍牌任务
                </Link>
            </Menu.Item>
        }

        {
            getIsXinghen() ? '' : <Menu.Item key="/task-list-spec">
                <Link to="/task-list-spec">
                    <ClockCircleOutlined />
                    定时任务
                </Link>
            </Menu.Item>
        }

        <Menu.Item key="/combos">
            <Link to="/combos">
                <AntDesignOutlined />
                套餐管理
            </Link>
        </Menu.Item>

        <Menu.Item key="/pictures">
            <Link to="/pictures">
                <PictureOutlined />
                中标图片
            </Link>
        </Menu.Item>

        <Menu.Item key="/settings">
            <Link to="/settings">
                <SettingOutlined />
                系统配置
            </Link>
        </Menu.Item>

        {/*<Menu.Item key="/personal">*/}
        {/*    <Link to="/personal">*/}
        {/*        <UserOutlined />*/}
        {/*        个人中心*/}
        {/*    </Link>*/}
        {/*</Menu.Item>*/}
    </Menu>;
};


export default SiderMenu;