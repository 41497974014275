
import React, {useEffect, useState, Fragment} from 'react';

import {Button, Col, Form, Input, Layout, message, Modal, Row, Select, Space, Table} from 'antd';

import { withRouter } from "react-router";

import { Link, useLocation } from 'react-router-dom';

import { Breadcrumb, Typography } from 'antd';
import { HomeOutlined, CommentOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import SiderMenu from "../../components/SiderMenu";

import Header from "../../components/Header";

import logo from '../../logo.svg';

import './index.less';

import {usePageViews} from "../../App";

import imgCircle from '../../assets/imgs/circle.svg';
import imgCircleSpec from '../../assets/imgs/circle-spec.svg';
import {apiOverview} from "../../api";
import {apiRoleAdd, apiRoleDel, apiRoleUpdate} from "../../api/role";
import {apiQaAdd, apiQaDel, apiQaList, apiQaUpdate} from "../../api/qa";
import {apiMachineList} from "../../api/machine";

const { Title } = Typography;

const { Column, ColumnGroup } = Table;

const { Option } = Select;

const QAList = (props) => {

    const [form] = Form.useForm();

    const [formNew] = Form.useForm();

    const [pageIndex, setPageIndex] = useState(1);

    const [total, setTotal] = useState(0);

    const [tableData, setTableData] = useState([{
        key: 1,
        question: '春天在哪里呀?',
        answer: '春天在小姐姐的歌声里!'
    }]);

    const [editId, setEditId] = useState('');

    const [modalVisible, setModalVisible] = useState(false);

    const [modalConfirmLoading, setModalConfirmLoading] = useState(false);

    // const [editId, setEditId] = useState('');

    useEffect(() => {
        getQAList({
            pageIndex: 1,
        });
    }, []);

    useEffect(() => {

        if (!editId) {
            return;
        }

        const {qaType, answer, question} = tableData.find(item => item.id == editId);

        formNew.setFieldsValue({
            type: qaType,
            answer,
            question,
        });

    }, [editId]);

    const onFinish = ({ type = '', answer = '', question = ''}) => {
        getQAList({
            type,
            answer: answer.trim(),
            question: question.trim(),
            pageIndex: 1,
        });
    };

    const pageChange = (page) => {
        const type = (form.getFieldValue('type') || '').trim();
        const answer = (form.getFieldValue('answer') || '').trim();
        const question = (form.getFieldValue('question') || '').trim();

        getQAList({
            type,
            answer,
            question,
            pageIndex: page,
        });
    };

    const getQAList = async (
        {
            type,
            answer,
            question,
            pageIndex = 1,
        }
    ) => {
        const res = await apiQaList({
            type: type === "all" ? '' : type,
            answer,
            question,
            pageIndex,
        });

        console.log(res);

        setPageIndex(res.pageIndex);
        setTotal(res.total);

        setTableData(res.records.map((item, index) => ({...item, key: index})));
    };

    const handleModalOk = async () => {
        try {
            const { type, question, answer } = await formNew.validateFields();

            setModalConfirmLoading(true);

            if (editId) {
                await apiQaUpdate({
                    id: editId,
                    question,
                    answer,
                    qaType: type,
                });

                pageChange(1);
            } else {
                await apiQaAdd({
                    question,
                    answer,
                    qaType: type,
                });

                getQAList({
                    pageIndex: 1,
                });
            }

            formNew.resetFields();
            setModalVisible(false);
            setModalConfirmLoading(false);

            message.success(`QA${editId ? '更新' : '添加'}成功`);

            setEditId(null);

        } catch (e) {
            message.error(e.message);
            setModalConfirmLoading(false);
        }
    };

    const handleModalCancel = () => {
        setEditId(null);
        setModalVisible(false);
        formNew.resetFields();
    };

    const handleEditBtnClick = (id) => async () => {
        console.log(id);

        setEditId(id);

        setModalVisible(true);
    };

    const handleDelBtnClick = (id) => async () => {

        Modal.confirm({
            title: '删除QA',
            icon: <ExclamationCircleOutlined />,
            content: '是否要删除该QA?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                await apiQaDel({id});

                message.success("QA已删除");

                pageChange(pageIndex);
            },
            // onCancel() {
            // },
        });

    };

    return <div className="page-coupon-settings">
        <Header>
            <div className="flex items-center justify-between">
                <Breadcrumb>
                    <Breadcrumb.Item href="/#/">
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/#/">
                        <CommentOutlined />
                        <span style={{marginLeft: 8}}>QA管理</span>
                    </Breadcrumb.Item>
                </Breadcrumb>

                <Button type="primary" shape="round" onClick={() => {
                    setModalVisible(true);
                }} ><PlusOutlined />新增QA</Button>
            </div>

        </Header>

        <div className="content-panel-common">

            <div className="filter-pannel-common">
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                >
                    <Row gutter={24} >

                        <Col span={8}>
                            <Form.Item
                                name="type"
                                label="类型"
                                initialValue="all"
                            >
                                <Select placeholder="请选择QA类型" onChange={() => {
                                    pageChange(1)
                                }}>
                                    <Option value="all">全部</Option>
                                    <Option value="沪牌政策">沪牌政策</Option>
                                    <Option value="拍牌流程">拍牌流程</Option>
                                    <Option value="蚂蚁平台指南">蚂蚁平台指南</Option>
                                    <Option value="沟通话术-业务类">沟通话术-业务类</Option>
                                    <Option value="沟通话术-技术类">沟通话术-技术类</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="question"
                                label="问题"
                            >
                                <Input placeholder="输入问题关键字" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="answer"
                                label="回答"
                            >
                                <Input placeholder="输入回答关键字" />
                            </Form.Item>
                        </Col>

                        <Col md={8} lg={6} >
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                            <Button
                                style={{ marginLeft: 8 }}
                                onClick={() => {
                                    form.resetFields();
                                    // getBidList({
                                    //     pageIndex: 1,
                                    // });
                                }}
                            >
                                重置
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div style={{marginTop: 10}}>
                <Table
                    // loading={true}
                    pagination={{
                        defaultCurrent: 1,
                        current: pageIndex,
                        total,
                        showSizeChanger: false,
                        pageSize: 10,
                        onChange: pageChange,
                    }}
                    dataSource={tableData}
                    // scroll={{ x: 1600 }}
                >
                    <Column
                        title="问题"
                        dataIndex="question"
                        key="question"
                        width={140}
                    />
                    <Column title="类型" width={100} dataIndex="qaType" key="qaType" />
                    <Column title="回答" width={280} dataIndex="answer" key="answer" />
                    <Column title="创建时间" width={140} dataIndex="createTime" key="createTime" />
                    <Column
                        title="操作"
                        key="action"
                        width={120}
                        render={(text, record) => (
                            <Space size="middle">
                                <span className="text-primary pointer" onClick={handleEditBtnClick(record.id)}>编辑</span>
                                <span className="text-error pointer" onClick={handleDelBtnClick(record.id)}>删除</span>
                            </Space>
                        )}
                    />
                </Table>
            </div>
        </div>


        <Modal
            title={editId ? 'QA编辑' : '新增QA'}
            visible={modalVisible}
            onOk={handleModalOk}
            confirmLoading={modalConfirmLoading}
            onCancel={handleModalCancel}
            okText="确定"
            cancelText="取消"
            maskClosable={false}
        >
            <Form
                form={formNew}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 17 }}
                name="basic"
            >
                <Form.Item
                    label="类型"
                    name="type"
                    rules={[{ required: true, message: '请选择QA类型' }]}
                >
                    <Select placeholder="请选择QA类型" onChange={() => {
                    }}>
                        <Option value="沪牌政策">沪牌政策</Option>
                        <Option value="拍牌流程">拍牌流程</Option>
                        <Option value="蚂蚁平台指南">蚂蚁平台指南</Option>
                        <Option value="沟通话术-业务类">沟通话术-业务类</Option>
                        <Option value="沟通话术-技术类">沟通话术-技术类</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="问题"
                    name="question"
                    rules={[{ required: true, message: '请输入问题' }]}
                >
                    <Input maxLength={50} placeholder="请输入问题" />
                </Form.Item>

                <Form.Item
                    label="回答"
                    name="answer"
                    rules={[{ required: true, message: '请输入回答' }]}
                >
                    <Input.TextArea maxLength={2000} autoSize={{minRows:3}}  placeholder="请输入回答" />
                </Form.Item>
            </Form>
        </Modal>

    </div>;
};

export default QAList;
