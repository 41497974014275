export const SET_LOADING = 'SET_LOADING';

const INITIAL_STATE = false;

export default function loading (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_LOADING:
      return action.payload;
    default:
      return state;
  }
}
