export const UPDATE_USER_INFO = "UPDATE_USER_INFO";

const INITIAL_STATE = null;

export default function user (state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_USER_INFO:
      return {
        ...state,
        ...action.payload,
      };
     default:
       return state
  }
}
