
import React, {useEffect, useRef, useState, Fragment} from 'react';

import {Form, Row, Col, Input, Button, Table, Tag, Space, Breadcrumb, Menu, Select, Upload, message, Modal} from 'antd';

import './index.less';
import Header from "../../components/Header";
import { HomeOutlined, CodepenOutlined, PlusOutlined, CompassOutlined, SendOutlined, CheckOutlined, NodeExpandOutlined, ExclamationCircleOutlined, CloseOutlined, EditOutlined, MinusCircleOutlined } from '@ant-design/icons';
import {usePageViews} from "../../App";
import {apiBidBatch, apiBidDel} from "../../api/bid";
import {apiPriceBatch, apiPriceList} from "../../api/price";
import {
    apiStrategyAllocationDel,
    apiStrategyConfigAll,
    apiStrategyConfigAllocation,
    apiStrategyConfigUpdate
} from "../../api/strategy";
import {apiMachineAllocationDel} from "../../api/machine";

const { Column, ColumnGroup } = Table;

const { Option } = Select;

const TimeAmountInput = (props = {show:false}) => {
    const isTimeGap = Object.prototype.toString.call(props.time).indexOf('Array') > -1;
    const width = isTimeGap ? 50 : 80;
    const [time, setTime] = useState((isTimeGap ? props.time[0] : props.time) || 0);
    const [amount, setAmount] = useState(props.amount || 0);
    const [timeMax, setTimeMax] = useState(isTimeGap ? props.time[1] || 0 : 0);

    return props.show ? <div className="flex items-center">
        <Input value={time} type="number" style={{width, marginRight: isTimeGap ? 4 : 10}} onChange={e => setTime(e.target.value)} ></Input>
        {
            isTimeGap && <Fragment>
                -
                <Input value={timeMax} type="number" style={{width, marginRight: 10, marginLeft: 4}} onChange={e => setTimeMax(e.target.value)} ></Input>
            </Fragment>
        }

        =>
        <Input value={amount} type="number" style={{width: 80, marginLeft: 10}} onChange={e => setAmount(e.target.value)} ></Input>
        <Button
            type="primary"
            style={{marginLeft: 10}}
            icon={<CheckOutlined />}
            onClick={props.onOK ? props.onOK(time, amount, timeMax) : (() => {})}
        ></Button>
        <Button
            type="primary"
            danger
            style={{marginLeft: 10}}
            icon={<CloseOutlined />}
            onClick={props.onCancel || (() => {})}
        ></Button>
    </div> : '';
};

const StrategySettings = () => {

    usePageViews();

    const [tableData, setTableData] = useState([]);

    const [pageIndex, setPageIndex] = useState(1);

    const [total, setTotal] = useState(0);

    const [fileList, setFileList] = useState([]);

    const [priceErrArr, setPriceErrArr] = useState([]);

    const [errCount, setErrCount] = useState(0);

    const [expand, setExpand] = useState(false);

    const [longTermEditIndex, setLongTermEditIndex] = useState(-1);

    const [longItem, setLongItem] = useState(null);

    const [longTerm, setLongTerm] = useState([]);

    const [shortTermEditIndex, setShortTermEditIndex] = useState(-1);

    const [shortItem, setShortItem] = useState(null);

    const [shortTerm, setShortTerm] = useState([]);

    const [commitEditIndex, setCommitEditIndex] = useState(-1);

    const [commitItem, setCommitItem] = useState(null);

    const [commitTerm, setCommitTerm] = useState([]);

    const [form] = Form.useForm();

    useEffect(() => {
        getPriceList({
            pageIndex: 1,
        });

        apiStrategyConfigAll().then(res => {
            // console.log(res);

            // content: "45:10;40:20;35:30;30:40;25:15"
            // key: "long"
            // name: "长伏击时间"
            // updateTime: "2020-09-04 16:42:37"

            // setLongTerm();

            (res.data || []).forEach(item => {

                switch (item.key) {
                    case 'long':
                        setLongTerm(item.content.split(';').map(_item => {
                            const [time, amount] = _item.split(':')

                            return {
                              time,
                              amount,
                            };
                        }));

                        break;
                    case 'short':
                        setShortTerm(item.content.split(';').map(_item => {
                            const [time, amount] = _item.split(':')

                            return {
                                time,
                                amount,
                            };
                        }));
                        break;
                    case 'force':
                        setCommitTerm(item.content.split(';').map(_item => {
                            const [timeGap, amount] = _item.split(':')

                            return {
                                time: timeGap.split(','),
                                amount,
                            };
                        }));
                        break;
                }
            })
        });

    }, []);

    useEffect(() => {
    }, [longTermEditIndex]);

    useEffect(() => {

        if (!priceErrArr.length) {
            return;
        }

        Modal.error({
            title: '数据校验失败',
            width: 800,
            okText: "确定",
            content: <div className="lh-lg">
                {
                    priceErrArr.map(item => <div key={item.index} className="flex items-center">
                        <p className="tr" style={{width: 75}}>第 <span className="text-error">{item.index + 1}</span> 行</p>
                        <p className="flex-grow-1 w-0 ellipsis" style={{padding: '0 15px'}}>数据： {item.data.join(',')}</p>
                        <p style={{width: 200}}>原因： <span className="text-error">{item.message}</span></p>
                    </div>)
                }
                {
                    errCount > 15 && <div style={{paddingLeft: 10}}>
                        <p>...</p>
                        <p>总计 <span className="text-error">{errCount}</span> 个错误</p>
                    </div>
                }
            </div>,
        });
    }, [priceErrArr]);

    const uploadProps = {
        fileList,
        beforeUpload: file => {
            // if (file.type !== 'image/png') {
            //     message.error(`${file.name} is not a png file`);
            // }

            const reader = new FileReader();

            setErrCount(0);

            reader.onload = async function () {
                // 对标号信息进行校验

                let csv = this.result;

                let counter = {};

                try {
                    const priceArr = this.result.split('\n').map(item => item.replace(/\s*/g, '').split(','));

                    const _priceErrArr = priceArr.reduce((total, item, index, arr) => {

                        // 第一行为表头
                        if (index === 0 && !/\d+/g.test(item[0])) {
                            return total;
                        }

                        if (!item[0]) {
                            return total;
                        }

                        if (item.length === 1 && item[0]) {

                            setErrCount(prevState => prevState+1);

                            if (total.length < 15) {
                                return total.concat({
                                    index,
                                    message: "时间、加价、提交价、数量不能为空",
                                    data: item,
                                });
                            } else {
                                return total;
                            }
                        }

                        const validateArr = [
                            {
                                index: 0,
                                regex: /^\d{1,2}$/,
                                message: "时间信息格式错误",
                            },
                            {
                                index: 1,
                                regex: /^\d{1,2}0{2}$/,
                                message: "加价缺失或格式错误",
                                require: true,
                            },
                            {
                                index: 2,
                                regex: /^(\d0{2})|0$/,
                                message: "提交价缺失或格式错误",
                                require: true,
                            },
                            {
                                index: 3,
                                regex: /^\d{1,4}$/,
                                message: "数量缺失或格式错误",
                                require: true,
                            },
                        ];

                        for (let i = 0; i < validateArr.length; i++) {
                            const _blk = validateArr[i];
                            if (item[_blk.index] && !_blk.regex.test(item[_blk.index]) || (!item[_blk.index] && _blk.require)) {
                                setErrCount(prevState => prevState+1);

                                if (total.length < 15) {
                                    return total.concat({
                                        index,
                                        message: _blk.message,
                                        data: item,
                                    });
                                } else {
                                    return total;
                                }
                            }
                        }

                        // 检查重复项
                        const _index = priceArr.findIndex((_pItem, _index) => _index != index && _pItem.slice(0,3).join(',') === item.slice(0, 3).join(','));

                        if (_index > -1) {
                            setErrCount(prevState => prevState+1);

                            if (total.length < 15) {
                                return total.concat({
                                    index,
                                    message: `第${index + 1}行与第${_index + 1}行重复`,
                                    data: item,
                                });
                            } else {
                                return total;
                            }
                        }

                        counter[item[0]] = parseInt(counter[item[0]] || 0) + parseInt(item[3]);

                        return total;
                    }, []);

                    setPriceErrArr(_priceErrArr);

                    if (!_priceErrArr.length) {
                        // 校验不同时间和是否一样
                        console.log(Object.entries(counter));

                        if (new Set(Object.values(counter)).size != 1) {

                            Modal.error({
                                title: '数据校验失败',
                                width: 800,
                                okText: "确定",
                                content: <div className="lh-lg">
                                    <p>各项的数量总和不一致</p>
                                    {
                                        Object.entries(counter).sort((a, b) => a[1] - b[1]).map(count => <p key={count[0]}>{count[0]}s => {count[1]} 个</p>)
                                    }
                                </div>,
                            });

                            return;
                        }

                        // 上传标书信息

                        await apiPriceBatch({csv});

                        message.success("价格表导入成功");

                        getPriceList({pageIndex: 1});
                    }

                } catch (e) {
                    message.error("文件内容解析失败,请查看文件内容和编码是否符合要求");
                }
            }

            reader.readAsText(file);

            return false;
        },
        onChange: info => {
            // console.log(info.fileList);
            setFileList(info.fileList);
        },
    };

    const onFinish = ({codeId ='', userId = ''}) => {
        getPriceList({
            pageIndex: 1,
        });
    };

    const pageChange = (page) => {
        const codeId = form.getFieldValue('codeId') || '';
        const userId = form.getFieldValue('userId') || '';

        getPriceList({
            pageIndex: page,
        });
    };


    const getPriceList = async (
        {
            pageIndex = 1
        }
    ) => {
        const res = await apiPriceList({
            pageIndex,
        });

        setPageIndex(res.data.pageIndex);
        setTotal(res.data.total);

        console.log(res);

        setTableData(res.data.lists.map((item, index) => ({...item, key: index})));
    };

    const longTermEditOK = (time, amount) => async (e) => {

        if (!time || !amount || time <= 0 || amount <= 0) {

            message.error("时间和数量不能为空且需大于0");

            return;
        }

        // 新增
        if (longTermEditIndex == -1) {

            await apiStrategyConfigUpdate({
                name: '长伏击时间',
                key: 'long',
                content: [...longTerm, {
                    time,
                    amount,
                }].sort((a,b) => b.time - a.time).map(item => `${item.time}:${item.amount}`).join(';'),
            });

            message.success("配置更新成功");

            setLongTerm(prev => [...prev, {
                time,
                amount,
            }]);

            setLongItem(null);

        } else {
            const _arr = [...longTerm];

            _arr.splice(longTermEditIndex, 1, {
                time,
                amount,
            })

            await apiStrategyConfigUpdate({
                name: '长伏击时间',
                key: 'long',
                content: _arr.sort((a,b) => b.time - a.time).map(item => `${item.time}:${item.amount}`).join(';'),
            });

            message.success("配置更新成功");

            setLongTerm(prev => {
                    const arr = [...prev];

                    arr.splice(longTermEditIndex, 1, {
                        time,
                        amount,
                    })

                    return arr;
                }
            );

            setLongTermEditIndex(-1);
        }
    };

    const shortTermEditOK = (time, amount) => async (e) => {

        if (!time || !amount || time <= 0 || amount <= 0) {

            message.error("时间和数量不能为空且需大于0");

            return;
        }

        // 新增
        if (shortTermEditIndex == -1) {
            await apiStrategyConfigUpdate({
                name: '短伏击时间',
                key: 'short',
                content: [...shortTerm, {
                    time,
                    amount,
                }].sort((a,b) => b.time - a.time).map(item => `${item.time}:${item.amount}`).join(';'),
            });

            message.success("配置更新成功");

            setShortTerm(prev => [...prev, {
                time,
                amount,
            }]);

            setShortItem(null);

        } else {

            const _arr = [...shortTerm];

            _arr.splice(shortTermEditIndex, 1, {
                time,
                amount,
            })

            await apiStrategyConfigUpdate({
                name: '短伏击时间',
                key: 'short',
                content: _arr.sort((a,b) => b.time - a.time).map(item => `${item.time}:${item.amount}`).join(';'),
            });

            message.success("配置更新成功");

            setShortTerm(prev => {
                    const arr = [...prev];

                    arr.splice(shortTermEditIndex, 1, {
                        time,
                        amount,
                    })

                    return arr;
                }
            );

            setShortTermEditIndex(-1);
        }
    };

    const commitTermEditOK = (time, amount, timeMax) => async (e) => {

        if (!time || !amount || !timeMax || time <= 0 || amount <= 0 || timeMax <= 0) {

            message.error("时间和数量不能为空且需大于0");

            return;
        }

        // 新增
        if (commitEditIndex == -1) {

            await apiStrategyConfigUpdate({
                name: '强制提交时间',
                key: 'force',
                content: [...commitTerm, {
                    time: [time, timeMax],
                    amount,
                }].sort((a,b) => b.time[0] - a.time[0]).map(item => `${item.time[0]},${item.time[1]}:${item.amount}`).join(';'),
            });

            message.success("配置更新成功");

            setCommitTerm(prev => [...prev, {
                time: [time, timeMax],
                amount,
            }]);

            setCommitItem(null);

        } else {

            const _arr = [...commitTerm];

            _arr.splice(commitEditIndex, 1, {
                time: [time, timeMax],
                amount,
            })

            await apiStrategyConfigUpdate({
                name: '强制提交时间',
                key: 'force',
                content: _arr.sort((a,b) => b.time[0] - a.time[0]).map(item => `${item.time[0]},${item.time[1]}:${item.amount}`).join(';'),
            });

            message.success("配置更新成功");

            setCommitTerm(prev => {
                    const arr = [...prev];

                    arr.splice(commitEditIndex, 1, {
                        time: [time, timeMax],
                        amount,
                    })

                    return arr;
                }
            );

            setCommitEditIndex(-1);
        }
    };

    const generateStrategy = async () => {
        await apiStrategyConfigAllocation();

        message.success("策略分配成功");
    };

    const handleStrategyClear = async () => {
        Modal.confirm({
            title: '清空策略',
            icon: <ExclamationCircleOutlined />,
            content: '是否要清空已分配的策略?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                await apiStrategyAllocationDel();

                message.success("策略已清空");
            },
            // onCancel() {
            // },
        });
    };

    return <div className="page-strategy-list">
        <Header>
            <div className="flex items-center justify-between">
                <Breadcrumb>
                    <Breadcrumb.Item href="/#/">
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="">
                        <CodepenOutlined />
                        <span>策略配置</span>
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div>
                    <Upload
                        {...uploadProps}
                    >
                        <Button
                            style={{margin: '0 15px'}}
                            shape="round"
                            icon={<SendOutlined />}
                        >
                            导入策略价格表
                        </Button>
                    </Upload>
                </div>
            </div>
        </Header>

        <div className="content-panel-common">

            <div className="card">
                <div className="card-title spec fs-16 flex justify-between">
                    策略配置项
                </div>

                <div className="flex">
                    <div className="card-item flex-grow-1 w-0">
                        <p className="card-item-title flex justify-between">
                            长伏击时间：
                            <Button
                                type="primary"
                                shape="circle"
                                size="small"
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    setLongItem({
                                        time: 0,
                                        amount: 0,
                                    });
                                    setLongTermEditIndex(-1);
                                }}
                            >
                            </Button>
                        </p>
                        <div className="card-item-content">
                            {
                                !longTerm.length && !longItem && <p className="tc text-black-disable">暂无配置</p>
                            }

                            {
                                longTerm.map((item, index) => index == longTermEditIndex ? <TimeAmountInput
                                    time={item.time}
                                    amount={item.amount}
                                    show={true}
                                    onOK={longTermEditOK}
                                    key={index}
                                    onCancel={() => {
                                        setLongTermEditIndex(-1);
                                    }}
                                /> : <div key={index} className="item-tips flex justify-between items-center">
                                    {item.time} s => {item.amount} 个

                                    <div className="item-actions">
                                        <EditOutlined className="pointer text-primary" onClick={() => {

                                            setLongItem(null);

                                            setLongTermEditIndex(index);

                                        }} />
                                        <MinusCircleOutlined
                                            className="pointer
                                            text-error"
                                            style={{marginLeft: 10}}
                                            onClick={
                                                async () => {
                                                    Modal.confirm({
                                                        title: '删除配置',
                                                        icon: <ExclamationCircleOutlined />,
                                                        content: '是否要删除这条配置?',
                                                        okText: '确定',
                                                        okType: 'danger',
                                                        cancelText: '取消',
                                                        async onOk() {
                                                            const _arr = [...longTerm];

                                                            _arr.splice(index, 1);

                                                            await apiStrategyConfigUpdate({
                                                                name: '长伏击时间',
                                                                key: 'long',
                                                                content: _arr.map(item => `${item.time}:${item.amount}`).join(';'),
                                                            });

                                                            message.success("配置已更新");

                                                            setLongTerm(prev => {
                                                                const arr = [...prev];
                                                                arr.splice(index, 1);

                                                                return arr;
                                                            });
                                                        },
                                                        // onCancel() {
                                                        // },
                                                    });

                                                }
                                            }
                                        />
                                    </div>
                                </div>)
                            }

                            {
                                longItem && <TimeAmountInput
                                    show={true}
                                    time={0}
                                    amount={0}
                                    onOK={longTermEditOK}
                                    onCancel={() => {
                                        setLongItem(null);
                                    }}
                                />
                            }

                        </div>
                    </div>

                    <div className="card-item flex-grow-1 w-0">
                        <p className="card-item-title flex justify-between">
                            短伏击时间：
                            <Button
                                type="primary"
                                shape="circle"
                                size="small"
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    setShortItem({
                                        time: 0,
                                        amount: 0,
                                    });
                                    setShortTermEditIndex(-1);
                                }}
                            >
                            </Button>
                        </p>
                        <div className="card-item-content">
                            {
                                !shortTerm.length && !shortItem && <p className="tc text-black-disable">暂无配置</p>
                            }

                            {
                                shortTerm.map((item, index) => index == shortTermEditIndex ? <TimeAmountInput
                                    time={item.time}
                                    amount={item.amount}
                                    show={true}
                                    onOK={shortTermEditOK}
                                    key={index}
                                    onCancel={() => {
                                        setShortTermEditIndex(-1);
                                    }}
                                /> : <div key={index} className="item-tips flex justify-between items-center">
                                    {item.time} s => {item.amount} 个

                                    <div className="item-actions">
                                        <EditOutlined className="pointer text-primary" onClick={() => {

                                            setShortItem(null);

                                            setShortTermEditIndex(index);

                                        }} />
                                        <MinusCircleOutlined
                                            className="pointer
                                            text-error"
                                            style={{marginLeft: 10}}
                                            onClick={
                                                async () => {

                                                    Modal.confirm({
                                                        title: '删除配置',
                                                        icon: <ExclamationCircleOutlined />,
                                                        content: '是否要删除这条配置?',
                                                        okText: '确定',
                                                        okType: 'danger',
                                                        cancelText: '取消',
                                                        async onOk() {
                                                            const _arr = [...shortTerm];

                                                            _arr.splice(index, 1);

                                                            await apiStrategyConfigUpdate({
                                                                name: '短伏击时间',
                                                                key: 'short',
                                                                content: _arr.map(item => `${item.time}:${item.amount}`).join(';'),
                                                            });

                                                            message.success("配置已更新");

                                                            setShortTerm(prev => {
                                                                const arr = [...prev];
                                                                arr.splice(index, 1);

                                                                return arr;
                                                            });
                                                        },
                                                        // onCancel() {
                                                        // },
                                                    });

                                                }
                                            }
                                        />
                                    </div>
                                </div>)
                            }

                            {
                                shortItem && <TimeAmountInput
                                    show={true}
                                    time={0}
                                    amount={0}
                                    onOK={shortTermEditOK}
                                    onCancel={() => {
                                        setShortItem(null);
                                    }}
                                />
                            }

                        </div>
                    </div>


                    <div className="card-item flex-grow-1 w-0">
                        <p className="card-item-title flex justify-between">
                            强制提交时间：
                            <Button
                                type="primary"
                                shape="circle"
                                size="small"
                                icon={<PlusOutlined />}
                                onClick={async () => {
                                    setCommitItem({
                                        time: [0, 0],
                                        amount: 0,
                                    });
                                    setCommitEditIndex(-1);
                                }}
                            >
                            </Button>
                        </p>
                        <div className="card-item-content">
                            {
                                !commitTerm.length && !commitItem && <p className="tc text-black-disable">暂无配置</p>
                            }

                            {
                                commitTerm.map((item, index) => index == commitEditIndex ? <TimeAmountInput
                                    time={item.time}
                                    amount={item.amount}
                                    show={true}
                                    onOK={commitTermEditOK}
                                    key={index}
                                    onCancel={() => {
                                        setCommitEditIndex(-1);
                                    }}
                                /> : <div key={index} className="item-tips flex justify-between items-center">
                                    {item.time[0] || 0} - {item.time[1] || 0} s => {item.amount} 个

                                    <div className="item-actions">
                                        <EditOutlined className="pointer text-primary" onClick={() => {

                                            setCommitItem(null);

                                            setCommitEditIndex(index);

                                        }} />
                                        <MinusCircleOutlined
                                            className="pointer
                                            text-error"
                                            style={{marginLeft: 10}}
                                            onClick={
                                                async () => {

                                                    Modal.confirm({
                                                        title: '删除配置',
                                                        icon: <ExclamationCircleOutlined />,
                                                        content: '是否要删除这条配置?',
                                                        okText: '确定',
                                                        okType: 'danger',
                                                        cancelText: '取消',
                                                        async onOk() {
                                                            const _arr = [...commitTerm];

                                                            _arr.splice(index, 1);

                                                            await apiStrategyConfigUpdate({
                                                                name: '强制提交时间',
                                                                key: 'force',
                                                                content: _arr.map(item => `${item.time}:${item.amount}`).join(';'),
                                                            });

                                                            message.success("配置已更新");

                                                            setCommitTerm(prev => {
                                                                const arr = [...prev];
                                                                arr.splice(index, 1);

                                                                return arr;
                                                            });
                                                        },
                                                        // onCancel() {
                                                        // },
                                                    });

                                                }
                                            }
                                        />
                                    </div>
                                </div>)
                            }

                            {
                                commitItem && <TimeAmountInput
                                    time={commitItem.time}
                                    amount={commitItem.amount}
                                    show={true}
                                    onOK={commitTermEditOK}
                                    onCancel={() => {
                                        setCommitItem(null);
                                    }}
                                />
                            }

                        </div>
                    </div>

                </div>

                <Button
                    type="primary"
                    icon={<NodeExpandOutlined />}
                    onClick={generateStrategy}
                    style={{marginTop: 20}}
                >
                    分配策略
                </Button>

                <Button
                    type="primary"
                    icon={<MinusCircleOutlined />}
                    onClick={handleStrategyClear}
                    danger
                    style={{margin: '20px 0 0 20px'}}
                >
                    清空策略
                </Button>
            </div>

            <div className="card" style={{marginTop: 25}}>
                <p className="card-title spec fs-16">策略价格表</p>
                <Table
                    // loading={true}
                    pagination={{
                        defaultCurrent: 1,
                        current: pageIndex,
                        total,
                        showSizeChanger: false,
                        pageSize: 50,
                        onChange: pageChange,
                    }}
                    dataSource={tableData}
                >
                    <Column title="时间" dataIndex="time" key="time" />
                    <Column title="加价" dataIndex="addPrice" key="addPrice" />
                    <Column title="提交价" dataIndex="applyPrice" key="applyPrice" />
                    <Column title="数量" dataIndex="range" key="range" />
                </Table>
            </div>
        </div>
        <a
            href="https://code-sprite.oss-cn-shanghai.aliyuncs.com/price.csv"
            className="demo-download-link">
            <Button  shape="round" type="primary">
                <CompassOutlined />
                示例下载
            </Button>
        </a>
    </div>;
};

export default StrategySettings;