
import React, { useEffect, useState } from 'react';

import {Form, Row, Col, Input, Button, Table, Tag, Space, Breadcrumb, Menu, Modal, Select, message, Upload} from 'antd';

import './index.less';
import Header from "../../components/Header";
import { HomeOutlined, GiftOutlined, ExclamationCircleOutlined, endOutlined, SendOutlined } from '@ant-design/icons';
import {usePageViews} from "../../App";
import {apiPartnerAdd, apiPartnerDel, apiPartnerList, apiPartnerUpdate} from "../../api/partner";
import {apiBidAdd, apiBidDel, apiBidDELETE, apiBidUpdate} from "../../api/bid";
import {apiBidsStrategyBatch, apiStrategyList, apiStrategyUpdate} from "../../api/strategy";
import {apiPriceBatch} from "../../api/price";
// import {apiWorkerList} from "../../api";

const { Column, ColumnGroup } = Table;

const TimePrice = ({text, onChange}) => {
    const [_time = 0, _price = 0, _limit = 0] = text.split(',');
    const [time, setTime] = useState(_time);
    const [price, setPrice] = useState(_price);
    const [limit, setLimit] = useState(_limit);

    useEffect(() => {
        onChange && onChange(`${time},${price},${limit}`);
    }, [time, price, limit]);

    return <div className="flex time-price-wrap">
        <Input
            className="time-price-input"
            defaultValue={time}
            type="number"
            onChange={(e) => setTime(e.target.value)}
            suffix="s"
        ></Input>

        <Input
            className="time-price-input"
            defaultValue={price / 100}
            type="number"
            onChange={(e) => setPrice(e.target.value * 100)}
            suffix="百元"
        ></Input>

        <Input
            className="time-price-input"
            defaultValue={limit/100}
            type="number"
            suffix="百元"
            onChange={(e) => setLimit(e.target.value * 100)}
        ></Input>
    </div>
};

const PackageList = () => {

    usePageViews();

    useEffect(() => {
        getStrategyList({
            name: '',
            pageIndex: 1,
        });
    }, []);

    const [tableData, setTableData] = useState([]);

    const [expand, setExpand] = useState(false);

    const [form] = Form.useForm();

    const [formNew] = Form.useForm();

    const [editId, setEditId] = useState('');

    const [pageIndex, setPageIndex] = useState(1);

    const [total, setTotal] = useState(0);

    const [modalVisible, setModalVisible] = useState(false);

    const [modalConfirmLoading, setModalConfirmLoading] = useState(false);

    const [fileList, setFileList] = useState([]);

    const [errCount, setErrCount] = useState(0);

    const [strategyErrArr, setStrategyErrArr] = useState([]);

    const [priceArr, setPriceArr] = useState([]);

    useEffect(() => {

        if (!editId) {
            return;
        }

        const {long, short, force, price} = tableData.find(item => item.id == editId);

        formNew.setFieldsValue({
            long,
            short,
            force,
            price
        });

        setPriceArr((price || '').split(';'));

    }, [editId]);

    useEffect(() => {

        if (!strategyErrArr.length) {
            return;
        }

        Modal.error({
            title: '数据校验失败',
            width: 800,
            okText: "确定",
            content: <div className="lh-lg">
                {
                    strategyErrArr.map(item => <div key={item.index} className="flex items-center">
                        <p className="tr" style={{width: 75}}>第 <span className="text-error">{item.index + 1}</span> 行</p>
                        <p className="flex-grow-1 w-0 ellipsis" style={{padding: '0 15px'}}>数据： {item.data.join(',')}</p>
                        <p style={{width: 200}}>原因： <span className="text-error">{item.message}</span></p>
                    </div>)
                }
                {
                    errCount > 15 && <div style={{paddingLeft: 10}}>
                        <p>...</p>
                        <p>总计 <span className="text-error">{errCount}</span> 个错误</p>
                    </div>
                }
            </div>,
        });
    }, [strategyErrArr]);

    // 按标书搜索
    const getStrategyList = async ({
        bidNumber,
        pageIndex = 1,
    }) => {
        const res = await apiStrategyList({
            bidNumber,
            pageIndex
        });

        setPageIndex(res.data.pageIndex);
        setTotal(res.data.total);

        console.log(res);

        setTableData(res.data.lists.map((item, index) => ({...item.strategy.conf, bidSeq: item.bidSeq, id: item.strategy.id, price: item.strategy.price, key: index})));
    };

    const onFinish = ({bidNumber = ''}) => {
        getStrategyList({
            bidNumber: bidNumber.trim(),
            pageIndex: 1,
        });
    };

    const uploadProps = {
        fileList,
        beforeUpload: file => {
            // if (file.type !== 'image/png') {
            //     message.error(`${file.name} is not a png file`);
            // }

            const reader = new FileReader();

            setErrCount(0);

            reader.onload = async function () {
                // 对标号信息进行校验

                let csv = this.result;

                console.log(csv);

                let counter = {};

                try {
                    const strategyArr = this.result.split('\n').map(item => item.replace(/\s*/g, '').split(','));

                    const _strategyErrArr = strategyArr.reduce((total, item, index, arr) => {

                        // 第一行为表头
                        if (index === 0 && !/\d+/g.test(item[0])) {
                            return total;
                        }

                        if (!item[0]) {
                            return total;
                        }

                        if (item.length === 1 && item[0]) {

                            setErrCount(prevState => prevState+1);

                            if (total.length < 15) {
                                return total.concat({
                                    index,
                                    message: "标号、长伏击（限价）、强制提交(容忍)不能为空",
                                    data: item,
                                });
                            } else {
                                return total;
                            }
                        }

                        const validateArr = [
                            {
                                index: 0,
                                regex: /^\d{8}$/,
                                message: "标号信息格式错误",
                            },
                            {
                                index: 1,
                                regex: /^\d{1,6}$/,
                                message: "长伏击(限价)格式不正确",
                                require: true,
                            },
                            {
                                index: 2,
                                regex: /^\d{1,5}$/,
                                message: "强制提交(容忍)格式不正确",
                                require: true,
                            },
                        ];

                        for (let i = 0; i < validateArr.length; i++) {
                            const _blk = validateArr[i];
                            if (item[_blk.index] && !_blk.regex.test(item[_blk.index]) || (!item[_blk.index] && _blk.require)) {
                                setErrCount(prevState => prevState+1);

                                if (total.length < 15) {
                                    return total.concat({
                                        index,
                                        message: _blk.message,
                                        data: item,
                                    });
                                } else {
                                    return total;
                                }
                            }
                        }

                        // 检查重复项
                        const _index = strategyArr.findIndex((_pItem, _index) => _index != index && _pItem.slice(0,3).join(',') === item.slice(0, 3).join(','));

                        if (_index > -1) {
                            setErrCount(prevState => prevState+1);

                            if (total.length < 15) {
                                return total.concat({
                                    index,
                                    message: `第${index + 1}行与第${_index + 1}行重复`,
                                    data: item,
                                });
                            } else {
                                return total;
                            }
                        }

                        counter[item[0]] = parseInt(counter[item[0]] || 0) + parseInt(item[3]);

                        return total;
                    }, []);


                    setStrategyErrArr(_strategyErrArr);

                    if (!_strategyErrArr.length) {

                        await apiBidsStrategyBatch({csv});

                        message.success("公牌策略表导入成功");

                        getStrategyList({pageIndex: 1});
                    }

                } catch (e) {
                    message.error("文件内容解析失败,请查看文件内容和编码是否符合要求");
                }
            }

            reader.readAsText(file);

            return false;
        },
        onChange: info => {
            // console.log(info.fileList);
            setFileList(info.fileList);
        },
    };


    const pageChange = (page) => {
        const bidNumber = form.getFieldValue('bidNumber') || '';

        getStrategyList({
            bidNumber: bidNumber.trim(),
            pageIndex: page,
        });
    };

    const handleModalOk = async () => {
        const { long, short, force } = await formNew.validateFields();

        for (let i = 0; i < priceArr.length; i++) {
            if (!/^\d{1,2},\d{1,2}00,(\d0)?0$/.test(priceArr[i])) {
                message.error(`${priceArr[i]} 格式错误！`);
                return;
            }
        }

        try {
            setModalConfirmLoading(true);

            await apiStrategyUpdate({
                id: editId,
                long,
                short,
                force,
                price: priceArr.join(';'),
            });

            formNew.resetFields();
            setModalVisible(false);
            setModalConfirmLoading(false);

            message.success('策略更新成功');

            setEditId(null);

            getStrategyList({
                pageIndex: 1,
            });

        } catch (e) {
            message.error(e.message);
            setModalConfirmLoading(false);
        }
    };

    const handleModalCancel = () => {
        setEditId(null);
        setModalVisible(false);
        formNew.resetFields();
    };

    const handleBtnNewClick = () => {
        setModalVisible(true);
    };

    const handleEditBtnClick = (id) => async () => {
        console.log(id);

        setEditId(id);

        setModalVisible(true);
    };

    const handleDelBtnClick = (id) => async () => {

        Modal.confirm({
            title: '删除策略',
            icon: <ExclamationCircleOutlined />,
            content: '是否要删除该策略?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                await apiPartnerDel({id});

                message.success("策略已删除");

                pageChange(pageIndex);
            },
            // onCancel() {
            // },
        });

    };

    return <div className="page-home">
        <Header>
            <div className="flex items-center justify-between">
                <Breadcrumb>
                    <Breadcrumb.Item href="/#/">
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="">
                        <GiftOutlined />
                        <span>套餐管理</span>
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div>
                    <Upload
                        {...uploadProps}
                    >
                        <Button
                            style={{margin: '0 15px'}}
                            shape="round"
                            icon={<SendOutlined />}
                        >
                            导入公牌策略
                        </Button>
                    </Upload>
                </div>
            </div>

        </Header>

        <div className="content-panel-common">
            <div className="filter-pannel-common">
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                name="bidNumber"
                                label="标号"
                            >
                                <Input placeholder="输入标号" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                            <Button
                                style={{ marginLeft: 8 }}
                                onClick={() => {
                                    form.resetFields();
                                    getStrategyList({pageIndex: 1});
                                }}
                            >
                                重置
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div >
                <Table
                    // loading={true}
                    pagination={{
                        defaultCurrent: 1,
                        current: pageIndex,
                        total,
                        showSizeChanger: false,
                        pageSize: 10,
                        onChange: pageChange,
                    }}
                    dataSource={tableData}
                >
                    <Column title="套餐ID" dataIndex="id" key="id" />
                    <Column title="套餐类型" dataIndex="short" key="short" />
                    <Column title="赔付类型" dataIndex="short" key="short" />
                    <Column title="赔付金额" dataIndex="long" key="long" />
                    <Column title="价格" dataIndex="bidSeq" key="bidSeq" />
                    <Column title="名称" dataIndex="bidSeq" key="bidSeq" />
                    <Column title="描述" dataIndex="bidSeq" key="bidSeq" />
                    <Column title="赔付说明" dataIndex="bidSeq" key="bidSeq" />

                    <Column
                        title="操作"
                        key="action"
                        width={80}
                        render={(text, record) => (
                            <Space size="middle">
                                <p className="text-primary pointer" onClick={handleEditBtnClick(record.id)}>编辑</p>
                                {/*<p className="text-error pointer" onClick={handleDelBtnClick(record.id)}>删除</p>*/}
                            </Space>
                        )}
                    />
                </Table>
            </div>
        </div>

        <Modal
            title={editId ? '策略编辑' : '新增策略'}
            visible={modalVisible}
            onOk={handleModalOk}
            confirmLoading={modalConfirmLoading}
            onCancel={handleModalCancel}
            okText="确定"
            cancelText="取消"
            maskClosable={false}
        >
            <Form
                form={formNew}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 17 }}
                name="basic"
                // onFinish={onFinish}
            >
                <Form.Item
                    label="长伏击"
                    name="long"
                    rules={[{ required: true, message: '请输入长伏击时间' }]}
                >
                    <Input type="number" suffix="s" />
                </Form.Item>
                <Form.Item
                    label="短伏击"
                    name="short"
                    rules={[{ required: true, message: '请输入短伏击时间' }]}
                >
                    <Input type="number" suffix="s" />
                </Form.Item>
                <Form.Item
                    label="强制提交"
                    name="force"
                    rules={[{ required: true, message: '请输入强制提交时间' }]}
                >
                    <Input type="number" suffix="s" />
                </Form.Item>
                <Form.Item
                    label="价格表"
                    name="price"
                    rules={[{ required: true, message: '请填写价格信息' }]}
                >
                    <Input hidden={true} />

                </Form.Item>

                <Col span={17} offset={5} style={{marginTop: -63}}>
                    {
                        priceArr.map((price, index) => <TimePrice
                            key={price}
                            text={price}
                            onChange={(text) => {
                                setPriceArr(prev => {
                                    prev[index] = text;

                                    return prev;
                                });
                            }}
                        >
                        </TimePrice>)
                    }
                </Col>

            </Form>
        </Modal>

    </div>;
};

export default PackageList;