// 友商管理接口
import $fetch from "./fetch";

// 新增友商
export const apiPartnerAdd = ({ name }) => {
    return $fetch('/partners/partner', {
        method: 'POST',
        params: {
            name
        }
    });
}

// 更新友商
export const apiPartnerUpdate = ({ id, name }) => {
    return $fetch(`/partners/partner/${id}`, {
        method: 'PUT',
        params: {
            name,
        }
    });
}

// 友商列表
export const apiPartnerList = ({ name, pageIndex = 1, pageSize = 10 } = {pageIndex: 1, pageSize: 10}) => {
    return $fetch('/partners/page', {
        method: 'GET',
        params: {
            name,
            pageIndex,
            pageSize,
        }
    });
};

// 友商删除
export const apiPartnerDel = ({ id }) => {
    return $fetch(`/partners/partner/${id}`, {
        method: 'DELETE',
    });
}

