
import React, { useEffect, useState, Fragment, useRef } from 'react';

import { Button, Col, Form, Input, DatePicker, Layout, message, Modal, Row, Select, Space, Table, Upload } from 'antd';

import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';

import { withRouter } from "react-router";

import { Link, useLocation } from 'react-router-dom';

import { Breadcrumb, Typography } from 'antd';
import { HomeOutlined, ClockCircleOutlined, CompassOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import SiderMenu from "../../components/SiderMenu";

import Header from "../../components/Header";

import logo from '../../logo.svg';

import './index.less';

import { usePageViews } from "../../App";

import imgCircle from '../../assets/imgs/circle.svg';
import imgCircleSpec from '../../assets/imgs/circle-spec.svg';
import { apiOverview } from "../../api";
import { apiRoleAdd, apiRoleDel, apiRoleUpdate } from "../../api/role";
import { apiQaAdd, apiQaDel, apiQaList, apiQaUpdate } from "../../api/qa";
import { apiMachineList } from "../../api/machine";
import { apiPayList, apiPayUpdate } from "../../api/pay";
import { apiTaskPage } from "../../api/task";
import { apiOrderBidsUpdateBatch, apiOrderTaskPage, apiOrderTaskGenerate, apiOrderTaskStatus, apiOrderTaskConfirm, apiOrderBidsUpdateSingle, apiOrderTaskLoginFail } from "../../api/order";
import { apiBidActivationFailure, apiBidActivationSuccess } from "../../api/bid";

const orderTaskStatus = { '01': '中标', '02': '暂停', '03': '漏拍', '04': '未中标', '05': '赔付' }
const { Title } = Typography;

const { Column, ColumnGroup } = Table;

const { Option } = Select;

const TaskList = (props) => {

    const [form] = Form.useForm();

    const [pageIndex, setPageIndex] = useState(1);

    const [total, setTotal] = useState(0);
    const [taskStatus, setTaskStatus] = useState(-2);

    const [tableData, setTableData] = useState([]);

    const [fileList, setFileList] = useState([]);

    const [canImport, setCanImport] = useState(true);

    const timer = useRef(0);

    const [count, setCount] = useState(0);

    const [file, setFile] = useState(null);

    const [modalShow, setModalShow] = useState(false);

    const downloadLink = useRef(null);

    const [modalConfirmLoading, setModalConfirmLoading] = useState(false);
    useEffect(() => {
        // getTaskList({
        //     pageIndex: 1,
        // });

        return () => {
            clearTimeout(timer.current);
            timer.current = 0;
        };
    }, []);

    useEffect(() => {
        console.log(tableData);

        // 列表中有正在进行中的任务
        if (tableData.find(item => parseInt(item.status) < 2)) {
            setCanImport(false);

            !timer.current && (timer.current = setInterval(() => {
                setCount(prev => (prev + 1));
            }, 1000));
        } else {
            clearTimeout(timer.current);
            timer.current = 0;

            setCanImport(true);
        }

    }, [tableData]);

    useEffect(() => {
        if (count > 0) {
            pageChange(pageIndex);
        }
    }, [count]);

    const handleModalGenerate = async () => {
        if (taskStatus == 0 || taskStatus == -1) {
            const res = await apiOrderTaskGenerate(taskStatus == 0 ? true : false);

            // 修改 a 标签的 href 和 download 属性
            downloadLink.current.setAttribute('href', res);

            // 点击下载
            setTimeout(() => {
                downloadLink.current.click();
            }, 0);
            form.setFieldsValue({ bidPeriod: moment().weekday(5) });
            pageChange(1);
        }
        setTaskStatus(-2);
    };


    const handleGetTaskStatus = async () => {
        const res = await apiOrderTaskStatus();

        setTaskStatus(res);
        // num: 0, userId: '1328254671043174402', realName: '夏鹏飞'
    };

    const handlePostTaskConfirm = async () => {
        await apiOrderTaskConfirm();

        pageChange(1);
        // num: 0, userId: '1328254671043174402', realName: '夏鹏飞'
    };

    const uploadPropsFile = {
        beforeUpload: file => {
            if (!file.name.toLowerCase().endsWith('.csv') && !file.name.toLowerCase().endsWith('.html')) {
                message.error(`${file.name} 不是 csv 或者 html 文件`);
            }

            return false;
        },
        onChange: info => {
            if (info.file.name.toLowerCase().endsWith('.csv') || info.file.name.toLowerCase().endsWith('.html')) {
                setFile(info.file);
            }
        },
    };

    const onFinish = ({ bidPeriod,
        paused,
        winStatus,
        counted,
        bidSequence,
        newOrderStatus }) => {
        getTaskList({
            bidPeriod,
            paused,
            winStatus,
            counted,
            bidSequence,
            newOrderStatus,
            pageIndex: 1,
        });
    };

    const pageChange = (pageIndex) => {
        const bidPeriod = form.getFieldValue('bidPeriod');
        const paused = form.getFieldValue('paused');
        const winStatus = form.getFieldValue('winStatus');
        const counted = form.getFieldValue('counted');
        const bidSequence = form.getFieldValue('bidSequence');
        const newOrderStatus = form.getFieldValue('newOrderStatus');

        getTaskList({
            bidPeriod,
            paused,
            winStatus,
            counted,
            bidSequence,
            newOrderStatus,
            pageIndex
        });
    };

    const handleLoginFail = (taskId) => {
        Modal.confirm({
            title: '不参拍',
            icon: <ExclamationCircleOutlined />,
            content: (<div>
                <p>是否不参拍?</p>
                <br />
            </div>),
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                await apiOrderTaskLoginFail({ taskId });
                pageChange(pageIndex);
            },
        });

    };    

    const getTaskList = async (
        {
            bidPeriod,
            paused,
            winStatus,
            counted,
            bidSequence,
            newOrderStatus,
            pageIndex = 1,
            pageSize
        }
    ) => {
        const res = await apiOrderTaskPage({
            bidPeriod: bidPeriod && bidPeriod.format("YYYYMMDD"),
            paused,
            winStatus,
            counted,
            bidSequence,
            newOrderStatus,
            pageIndex,
            pageSize
        });
        setPageIndex(res.pageIndex);
        setTotal(res.total);

        setTableData(res.records.map((item, index) => ({ ...item, key: index })));
    };

    const handleModalOk = async () => {
        try {
            console.log(file);

            if (file) {
                const res = await apiOrderBidsUpdateSingle({
                    file
                });

                if (res.code === 2001) {
                    message.error('用户未登录或token已过期，请重新登录');
                    window.location.href = '/#/login';
                    localStorage.removeItem('userInfo');
                    return;
                }

                if (res.code === 0) {
                    setModalConfirmLoading(false);

                    message.success('导入成功, 任务执行中');

                    setTimeout(() => {
                        pageChange(1);
                    }, 1000);

                    setModalShow(false);
                    setFile(null);
                } else {
                    message.error(res.message);
                    setModalConfirmLoading(false);
                }

            } else {
                message.error('文件不能为空');
                setModalConfirmLoading(false);
            }

        } catch (e) {
            message.error(e.message);
            setModalConfirmLoading(false);
        }
    };

    return <div className="page-task-list">
        <Header>
            <div className="flex items-center justify-between">
                <Breadcrumb>
                    <Breadcrumb.Item href="/#/">
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/#/">
                        <ClockCircleOutlined />
                        <span style={{ marginLeft: 8 }}>拍牌任务</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>

        </Header>

        <div className="content-panel-common">

            <div className="filter-pannel-common">
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                >
                    <Row gutter={24} >
                        <Col span={3}>
                            <Form.Item
                                label="拍牌日期"
                                name="bidPeriod"
                            >
                                <DatePicker style={{ width: '100%' }} locale={locale} placeholder="请选择拍牌日期" disabledDate={
                                    (current) => {
                                        // Can not select days before today and today
                                        return current && (current.day() != 6 || current.date() < 15 || current.date() > 28);
                                    }
                                } />
                            </Form.Item>
                        </Col>

                        <Col span={3}>
                            <Form.Item
                                name="paused"
                                label="暂停"
                            >
                                <Select
                                    allowClear className="w-100"
                                    placeholder="全部"
                                >
                                    <Option value="true">已暂停</Option>
                                    <Option value="false">未暂停</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={3}>
                            <Form.Item
                                name="newOrderStatus"
                                label="结果"
                            >
                                <Select
                                    allowClear className="w-100"
                                    placeholder="结果"
                                >
                                    <Option value="01">中标</Option>
                                    <Option value="02">暂停</Option>
                                    <Option value="03">漏拍</Option>
                                    <Option value="04">未中标</Option>
                                    <Option value="05">赔付</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item
                                name="counted"
                                label="参拍"
                            >
                                <Select allowClear className="w-100" placeholder="是否参拍">
                                    <Option value="0">未参拍</Option>
                                    <Option value="1">已参拍</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={3}>
                            <Form.Item
                                name="winStatus"
                                label="中标"
                            >
                                <Select allowClear className="w-100" placeholder="是否中标">
                                    <Option value="0">未中标</Option>
                                    <Option value="1">已中标</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item
                                name="bidSequence"
                                label="标书号"
                            >
                                <Input placeholder="输入标书号" />
                            </Form.Item>
                        </Col>


                        <Col md={8} lg={6} >
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                            <Button
                                style={{ marginLeft: 8 }}
                                onClick={() => {
                                    form.resetFields();
                                }}
                            >
                                重置
                            </Button>
                            {
                                total ? <div className="tag-total">共 {total} 条</div> : null
                            }
                        </Col>
                    </Row>
                </Form>

                <div>

                    <Button
                        type="primary"
                        style={{ margin: '0 15px' }}
                        shape="round"
                        onClick={handleGetTaskStatus}
                    >
                        准备拍牌
                    </Button>


                    <Button
                        type="ghost"
                        style={{ margin: '0 15px' }}
                        shape="round"
                        onClick={handlePostTaskConfirm}
                    >
                        开始拍牌(10:30)
                    </Button>

                    <Button
                        type="primary"
                        style={{ margin: '0 15px' }}
                        shape="round"
                        onClick={() => {
                            setModalShow(true);
                        }}
                    >
                        更新结果(12:00 ~ 15:30)
                    </Button>
                </div>
            </div>

            <div style={{ marginTop: 10 }}>
                <Table
                    // loading={true}
                    pagination={{
                        defaultCurrent: 1,
                        current: pageIndex,
                        total,
                        showSizeChanger: false,
                        pageSize: 50,
                        onChange: pageChange,
                    }}
                    dataSource={tableData}
                // scroll={{ x: 1600 }}
                >
                    <Column title="周期" dataIndex="bidPeriod" key="bidPeriod" />
                    <Column title="订单号" dataIndex="orderId" key="orderId" />
                    <Column title="标书号" dataIndex="bidSequence" key="bidSequence" />
                    <Column title="姓名" dataIndex="name" key="name" />
                    <Column title="标书次数" dataIndex="useCount" key="useCount" />
                    <Column title="过期时间" dataIndex="expireTime" key="expireTime" render={(val) => val && val.substr(0, 10) } />
                    <Column title="开始" dataIndex="confirmed" key="confirmed" render={(val) => <span className={val ? 'text-success' : 'text-error'}>{val ? '开始' : '尚未开始'}</span>} />
                    <Column title="订单次数" dataIndex="orderCount" key="orderCount" render={(val, record) => val != null ? val + " / " + record['subSize'] : ""} />
                    <Column title="暂停" dataIndex="paused" key="paused" render={(val) => <span className={val ? 'text-error' : 'text-success'}>{val ? '暂停' : '进行'}</span>} />
                    <Column title="操作" render={
                        (item, record) => {
                            if(!record.paused){
                                return <Button
                                    type="primary"
                                    danger="true"
                                    style={{ margin: '0 15px' }}
                                    shape="round"
                                    onClick={() => handleLoginFail(record.taskId)}
                                >不参拍</Button>
                            }
                        }

                    } />
                    <Column title="结果" dataIndex="newOrderStatus" key="newOrderStatus" render={(val) => orderTaskStatus[val]} />
                    <Column title="通知结果" dataIndex="notifyOrderStatus" key="notifyOrderStatus" render={(val) => orderTaskStatus[val]} />
                    <Column title="备注" dataIndex="comment" key="comment" />
                    <Column title="支付价格" dataIndex="paymentMoney" key="paymentMoney" />
                    <Column title="参拍" dataIndex="counted" key="counted" render={
                        (val) => {
                            if(val != null){
                                return <span className={val ? 'text-success' : 'text-error'}>{val ? '参拍' : '未拍'}</span>;
                            }
                        }
                    } />
                    <Column title="中标" dataIndex="winStatus" key="winStatus" render={
                        (val) => {
                            if(val != null){
                                return <span className={val ? 'text-success' : 'text-error'}>{val ? '中标' : '未中'}</span>;
                            }
                        }
                    } />
                    <Column title="下单时间" dataIndex="orderTime" key="orderTime" />
                    <Column title="结束" dataIndex="finished" key="finished" render={(val) => <span className={val ? 'text-success' : 'text-error'}>{val ? '结束' : '尚未结束'}</span>} />
                </Table>
            </div>
        </div>

        <Modal
            className="batch-modal"
            width={700}
            title="生成拍牌任务"
            visible={taskStatus >= -1}
            okText="确定"
            cancelText="取消"
            onCancel={() => {
                setTaskStatus(-2);
            }}

            onOk={handleModalGenerate}
        >
            <div>
                <p style={{ marginTop: 6 }} className="lh-lg text-success">{taskStatus >= 1 ? '当月拍牌任务已开始，请不要重复生成。' : (taskStatus == 0 ? '当月拍牌任务已生成，确定覆盖吗？' : '即将生成拍牌任务，是否确定生成？')}</p>
            </div>
        </Modal>

        <Modal
            visible={modalShow}
            okText={'开始上传'}
            cancelText={'取消'}
            className="modal-task"
            onOk={handleModalOk}
            confirmLoading={modalConfirmLoading}
            onCancel={() => {
                setModalShow(false);
                setFile(null);
            }}
            maskClosable={false}
        >
            <div>
                <Upload
                    {...uploadPropsFile}
                >
                    <Button style={{ width: 90 }}>上传结果</Button>
                </Upload>
                <p style={{ marginTop: 6 }} className="lh-lg text-success">{file ? file.name : ''}</p>
            </div>
        </Modal>

        <a href="" ref={downloadLink} download="orders.csv" ></a>
    </div>;
};

export default TaskList;
