
import React, {useEffect, useState, Fragment, useRef} from 'react';

import {Button, Col, Drawer, Form, Input, Layout, message, Modal, Row, Select, Space, Table, Upload} from 'antd';

import { withRouter } from "react-router";

import { Link, useLocation } from 'react-router-dom';

import { Breadcrumb, Typography } from 'antd';
import { HomeOutlined, ClockCircleOutlined, CompassOutlined, PlusOutlined } from '@ant-design/icons';

import SiderMenu from "../../components/SiderMenu";

import Header from "../../components/Header";

import logo from '../../logo.svg';

import './index.less';

import {usePageViews} from "../../App";

import imgCircle from '../../assets/imgs/circle.svg';
import imgCircleSpec from '../../assets/imgs/circle-spec.svg';
import {apiOverview} from "../../api";
import {apiRoleAdd, apiRoleDel, apiRoleUpdate} from "../../api/role";
import {apiQaAdd, apiQaDel, apiQaList, apiQaUpdate} from "../../api/qa";
import {apiMachineList} from "../../api/machine";
import {apiPayList, apiPayUpdate} from "../../api/pay";
import {apiSchedulesExecution, apiSchedulesLog, apiTaskPage} from "../../api/task";
import {apiOrderBidsUpdateBatch, apiOrderImportPage} from "../../api/order";
import {apiBidActivationFailure, apiBidActivationSuccess} from "../../api/bid";

const { Title } = Typography;

const { Column, ColumnGroup } = Table;

const { Option } = Select;

const TaskListSpec = (props) => {

    const [form] = Form.useForm();

    const [pageIndex, setPageIndex] = useState(1);

    const [total, setTotal] = useState(0);

    const [tableData, setTableData] = useState([]);

    const [fileList, setFileList] = useState([]);

    const [canImport, setCanImport] = useState(true);

    const [count, setCount] = useState(0);

    const [modalShow, setModalShow] = useState(false);

    const [modalConfirmLoading, setModalConfirmLoading] = useState(false);

    const [logJob, setLogJob] = useState(null);

    const [logs, setLogs] = useState([]);

    const [logsTotal, setLogsTotal] = useState(0);

    const [logPageIndex, setLogPageIndex] = useState(1);

    useEffect(() => {
        getTaskList({
            pageIndex: 1,
        });

    }, []);

    useEffect(() => {
        pageChange(pageIndex);
    }, [count]);

    const onFinish = () => {
        getTaskList({
            pageIndex: 1,
        });
    };

    const pageChange = (page) => {

        getTaskList({
            pageIndex: page,
        });
    };

    const logsPageChange = async (page) => {

        const res = await apiSchedulesLog({jobId: logJob.jobId, pageIndex: page});

        setLogPageIndex(res.pageIndex);
        setLogsTotal(res.total);

        setLogs(res.records.map((item, index) => ({...item, key: index})));
    };

    const getTaskList = async (
        {
            pageIndex = 1,
        }
    ) => {
        const res = await apiTaskPage({
            pageIndex,
        });

        console.log(res);

        setPageIndex(res.pageIndex);
        setTotal(res.total);

        setTableData(res.records.map((item, index) => ({...item, key: index})));
    };

    const handleExecute = (id) => async () => {
        await apiSchedulesExecution({id});

        message.success('任务已开始执行');

    };

    const handleLogsShow = (record) => async () =>  {

        setLogJob(record);

        const res = await apiSchedulesLog({jobId: record.jobId, pageIndex: 1});

        console.log(res);

        setLogPageIndex(res.pageIndex);
        setLogsTotal(res.total);

        setLogs(res.records.map((item, index) => ({...item, key: index})));
    }

    return <div className="page-task-list">
        <Header>
            <div className="flex items-center justify-between">
                <Breadcrumb>
                    <Breadcrumb.Item href="/#/">
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/#/">
                        <ClockCircleOutlined />
                        <span style={{marginLeft: 8}}>定时任务</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>

        </Header>

        <div className="content-panel-common">

            <div className="filter-pannel-common">
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                >
                    <Row gutter={24} >
                        <Col span={8}>
                            <Form.Item
                                name="jobId"
                                label="任务ID"
                            >
                                <Input placeholder="输入任务ID" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="jobName"
                                label="任务状态"
                            >
                                <Input placeholder="输入任务状态" />
                            </Form.Item>
                        </Col>

                        <Col md={8} lg={6} >
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                            <Button
                                style={{ marginLeft: 8 }}
                                onClick={() => {
                                    form.resetFields();
                                    // getBidList({
                                    //     pageIndex: 1,
                                    // });
                                }}
                            >
                                重置
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <div>

                    <Button
                        type="primary"
                        // style={{margin: '0 15px'}}
                        shape="round"
                        disabled={!canImport}
                        onClick={() => {
                            setModalShow(true);
                        }}
                    >
                        更新标书状态
                    </Button>

                </div>
            </div>

            <div style={{marginTop: 10}}>
                <Table
                    // loading={true}
                    pagination={{
                        defaultCurrent: 1,
                        current: pageIndex,
                        total,
                        showSizeChanger: false,
                        pageSize: 20,
                        onChange: pageChange,
                    }}
                    dataSource={tableData}
                    // scroll={{ x: 1600 }}
                >
                    <Column
                        title="任务ID"
                        dataIndex="jobId"
                        key="jobId"
                    />
                    <Column
                        title="任务标识"
                        dataIndex="beanName"
                        key="beanName"
                    />
                    <Column
                        title="任务名"
                        dataIndex="remark"
                        key="remark"
                    />
                    <Column title="周期" dataIndex="cronExpression" key="cronExpression" />
                    <Column title="状态" dataIndex="enabled" key="enabled" render={(val) => <span className={val === "1" ? 'text-success' : 'text-error'}>{val === "1" ? '已启用' : '已禁用'}</span>} />
                    <Column title="创建时间" dataIndex="createTime" key="createTime" />
                    <Column
                        title="操作"
                        key="action"
                        render={(text, record) => (
                            <Space size="middle">
                                <span className="text-primary pointer" onClick={handleExecute(record.jobId)}>立即执行</span>
                                <span className="text-primary pointer" onClick={handleLogsShow(record)}>日志</span>
                            </Space>
                        )}
                    />
                </Table>
            </div>
        </div>

        <Drawer
            title={`${logJob ? logJob.remark : ''}-日志`}
            placement="right"
            className="drawer-spec"
            closable={true}
            onClose={() => {
                setLogJob(null);

                setLogs([]);

                setLogsTotal(0);

                setLogPageIndex(1);
            }}
            visible={!!logJob}
            width={600}
        >
            <Table
                // loading={true}
                pagination={{
                    defaultCurrent: 1,
                    current: logPageIndex,
                    total: logsTotal,
                    showSizeChanger: false,
                    pageSize: 20,
                    onChange: logsPageChange,
                }}
                dataSource={logs}
                // scroll={{ x: 1600 }}
            >
                <Column
                    title="日志ID"
                    dataIndex="logId"
                    key="logId"
                />
                <Column
                    title="状态"
                    dataIndex="status"
                    key="status"
                />
                <Column
                    title="执行耗时"
                    dataIndex="times"
                    key="times"
                />
                <Column
                    title="创建时间"
                    dataIndex="createTime"
                    key="createTime"
                />
            </Table>
        </Drawer>

    </div>;
};

export default TaskListSpec;
