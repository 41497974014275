// 评论管理接口
import $fetch from "./fetch";

// 更新评论
export const apiCommentUpdate = (
    {
        id,
        content,
        createTime,
        hot,
        orderNum,
        star,
    }
) => {
    return $fetch(`/api/cms/comments/${id}`, {
        method: 'PUT',
        params: {
            content,
            createTime,
            hot,
            orderNum,
            star,
        }
    });
}

// 删除评论
export const apiCommentDel = (
    {
        id,
    }
) => {
    return $fetch(`/api/cms/comments/${id}`, {
        method: 'DELETE',
    });
}

// 评论列表
export const apiCommentList = (
    {
        auditStatus,
        customerId,
        hot,
        orderId,
        packageId,
        teamId,
        pageIndex,
        pageSize = 20,
    } = {
        pageIndex: 1,
        pageSize: 20,
    }
) => {
    return $fetch('/api/cms/comments/page', {
        method: 'GET',
        params: {
            auditStatus,
            customerId,
            hot,
            orderId,
            packageId,
            teamId,
            pageIndex,
            pageSize: pageSize || 20,
        }
    });
}