// 策略接口
import $fetch from "./fetch";

// 获取订单列表
export const apiOrderList = ({ bidSequence, customerId, onlyUnbind, orderId, nickName, saleName, orderStatus, startTime, endTime, cancelStartTime, cancelEndTime, employee, pageIndex, pageSize = 10 } = { pageIndex: 1, pageSize: 10 }) => {
    
    // console.log(startTime, endTime, cancelStartTime, cancelEndTime, employee);
    
    return $fetch('/api/cms/orders/page', {
        method: 'GET',
        params: {
            bidSequence,
            customerId,
            orderId,
            nickName,
            orderStatus,
            saleName,
            onlyUnbind,
            startTime, 
            endTime, 
            cancelStartTime, 
            cancelEndTime, 
            employee,
            pageIndex,
            pageSize,
        }
    });
}

// 删除订单
export const apiOrderCancel = ({ id, fee }) => {
    return $fetch(`/api/cms/orders/${id}/cancel/${fee}`, {
        method: 'PUT',
    });
}

// 取消提成
export const apiOrderCancelReward = ({ id, cancel }) => {
    return $fetch(`/api/cms/orders/${id}/cancel_reward`, {
        method: 'PUT',
        params: {
            cancel
        }
    });
}

// 批量导入标书代拍结果
export const apiOrderBidsUpdateBatch = ({ csv, html }) => {

    const _formdata = new FormData();

    _formdata.append('missingFile', csv);

    _formdata.append('winFile', html);

    const apiBaseUrl = localStorage.getItem('isTest') ? 'https://test-api.mayidaipai.com' : 'https://api.mayidaipai.com';

    const { token } = JSON.parse(localStorage.getItem('userInfo') || '{}');

    return fetch(`${apiBaseUrl}/api/cms/order/tasks/import/batch`, {
        method: 'POST',
        headers: {
            access_token: token,
        },
        body: _formdata,
    }).then(res => res.json());
};


// 单个导入标书代拍结果
export const apiOrderBidsUpdateSingle = ({ file }) => {
    const _formdata = new FormData();
    _formdata.append('file', file);
    const apiBaseUrl = 'https://api.mayidaipai.com';

    const { token } = JSON.parse(localStorage.getItem('userInfo') || '{}');

    return fetch(`${apiBaseUrl}/api/cms/order/tasks/import/single`, {
        method: 'POST',
        headers: {
            access_token: token,
        },
        body: _formdata,
    }).then(res => res.json());
};

// 标书代拍结果查询
// export const apiOrderImportPage = ({ status, pageIndex, pageSize } = {
//     pageIndex: 1,
//     pageSize: 20,
// }) => {
//     return $fetch('/api/cms/order/tasks/import/page', {
//         method: 'GET',
//         params: {
//             status,
//             pageIndex,
//             pageSize,
//         }
//     });
// };


export const apiOrderTaskPage = ({bidPeriod, paused, winStatus, counted, bidSequence, newOrderStatus, pageIndex, pageSize } = {
    pageIndex: 1,
    pageSize: 50,
}) => {
    return $fetch('/api/cms/order/tasks/page', {
        method: 'GET',
        params: {
            bidPeriod,
            paused, 
            winStatus, 
            counted, 
            bidSequence, 
            newOrderStatus,
            pageIndex,
            pageSize,
        }
    });
};

// 订单收款确认
export const apiOrderAffirmance = ({ id }) => {
    return $fetch(`/api/cms/orders/${id}/affirmance`, {
        method: 'PUT',
    });
};

// 导出订单到 csv
export const apiOrder2Csv = ({
    startTime = '',
    endTime = ''
} = {
        startTime: '',
        endTime: '',
    }) => {
    return $fetch(`/api/cms/orders/csv`, {
        method: 'GET',
        params: {
            startTime,
            endTime,
        }
    });
};

// 生成任务
export const apiOrderTaskGenerate = (clear = false) => {
    return clear ? $fetch(`/api/cms/order/tasks/regenerate`, {
        method: 'POST'
    }) : $fetch(`/api/cms/order/tasks/generate`, {
        method: 'POST'
    });
};

// 查询任务状态
export const apiOrderTaskStatus = ({
} = {
    }) => {
    return $fetch(`/api/cms/order/tasks/status`, {
        method: 'GET'
    });
};

// 开始拍牌
export const apiOrderTaskConfirm = ({
} = {
    }) => {
    return $fetch(`/api/cms/order/tasks/confirm`, {
        method: 'POST'
    });
};

export const apiOrderTaskLoginFail = ({taskId}) => {
    return $fetch(`/api/cms/order/tasks/${taskId}/fail_login`, {
        method: 'PUT'
    });
};

// 订单暂停
export const apiOrderPause = ({ id }) => {
    return $fetch(`/api/cms/orders/${id}/pause`, {
        method: 'PUT',
    });
};

// 订单恢复
export const apiOrderRun = ({ id }) => {
    return $fetch(`/api/cms/orders/${id}/running`, {
        method: 'PUT',
    });
};

// 更新拍手
export const apiOrderPlayerTagUpdate = (
    {
        orderId,
        executor
    }
) => {
    return $fetch(`/api/cms/orders/${orderId}/executor`, {
        method: 'PUT',
        params: {
            executor,
        }
    }, false, true);
};

// 更改备注
export const apiOrderChangeMemo = (
    {
        orderId,
        memo
    }
) => {
    return $fetch(`/api/cms/orders/${orderId}/memo`, {
        method: 'PUT',
        params: {
            memo,
        }
    }, false, true);
};

export const apiCmsCouponsUse = (
    {
        orderId,
        cdkey
    }
) => {
    return $fetch(`/api/cms/coupons/cdkey/use`, {
        method: 'POST',
        params: {
            orderId,
            cdkey,
        }
    }, false);
};


export const apiOrdersEmployee = () =>　{
    return $fetch('/api/cms/orders/employee');
}