// 优惠券管理接口
import $fetch from "./fetch";

// 新增优惠券模板
export const apiCouponsTempAdd = (
    {
        // couponCode,
        couponName,
        // expireTime,
        money,
        priceCondition,
        // remainCount,
        remark,
        // totalCount,
        validPeriod,
        // validUnit,
    }
) => {
    return $fetch('/api/cms/coupons', {
        method: 'POST',
        params: {
            // couponCode: 'customer:new',
            couponName,
            // expireTime,
            money,
            priceCondition: priceCondition ? priceCondition : -1,
            remainCount: -1,
            remark,
            totalCount: -1,
            validPeriod,
            validUnit: 'day',
        }
    });
}


// 更新优惠券模板
export const apiCouponsTempUpdate = (
    {
        id,
        // couponCode,
        couponName,
        // expireTime,
        money,
        priceCondition,
        // remainCount,
        remark,
        // totalCount,
        validPeriod,
        // validUnit,
    }
) => {
    return $fetch(`/api/cms/coupons/${id}`, {
        method: 'PUT',
        params: {
            couponName,
            // expireTime,
            money,
            priceCondition: priceCondition ? priceCondition : -1,
            remainCount: -1,
            remark,
            totalCount: -1,
            validPeriod,
            validUnit: 'day',
        }
    });
}

// 删除优惠券模板
export const apiCouponsTempDel = (
    {
        id,
    }
) => {
    return $fetch(`/api/cms/coupons/${id}`, {
        method: 'DELETE',
    });
}

// 查询优惠券模板列表
export const apiCouponsTempList = (
    {
        cdId,
        couponName,
        pageIndex = 1,
        pageSize　= 100,
    } = {
        pageIndex: 1,
        pageSize: 100,
    }
) => {
    return $fetch('/api/cms/coupons/page', {
        method: 'GET',
        params: {
            pageIndex,
            pageSize,
        },
    });
}

// 生成兑换码
export const apiCouponsKeyAdd = (
    {
        couponDefId,
        size,
    }
) => {
    return $fetch('/api/cms/coupons/cdkey', {
        method: 'POST',
        params: {
            couponDefId,
            size,
        }
    });
}


// 删除兑换码
export const apiCouponsKeyDel = (
    {
        cdkey,
    }
) => {
    return $fetch(`/api/cms/coupons/cdkey/${cdkey}`, {
        method: 'DELETE',
    });
}


// 查询优惠券兑换码列表
export const apiCouponsKeyList = (
    {
        cdId,
        cdkey,
        hasUsed,
        pageIndex,
        pageSize = 20,
    } = {
        pageIndex: 1,
        pageSize: 20,
    }
) => {
    return $fetch('/api/cms/coupons/cdkey/page', {
        method: 'GET',
        params: {
            cdId,
            cdkey,
            hasUsed,
            pageIndex,
            pageSize: 20,
        }
    });
}


