
import React, { useEffect, useState } from 'react';

import {Form, Row, Col, Input, Button, DatePicker, Table, Breadcrumb, Select, Modal, message,  } from 'antd';

import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';

import './index.less';
import Header from "../../components/Header";
import { HomeOutlined, FileTextOutlined } from '@ant-design/icons';
import {usePageViews} from "../../App";
import {
    apiBidActivationFailure,
    apiBidActivationSuccess,
    apiBidDetail,
    apiBidList,
} from "../../api/bid";
import { withRouter } from 'react-router-dom';

const { Column, ColumnGroup } = Table;

const { Option } = Select;

const DescriptionItem = ({ title, content }) => (
    <div className="site-description-item-profile-wrapper">
        <p className="site-description-item-profile-p-label">{title}:</p>
        {content}
    </div>
);


const BidList = (props) => {

    usePageViews();

    const [tableData, setTableData] = useState([]);

    const [form] = Form.useForm();

    const [formNew] = Form.useForm();

    const [pageIndex, setPageIndex] = useState(1);

    const [total, setTotal] = useState(0);

    const [drawerShow, setDrawerShow] = useState(false);

    const [editId, setEditId] = useState('');

    const [bidData, setBidData] = useState(null);

    const [fileList, setFileList] = useState([]);

    const [statusSelect, setStatusSelect] = useState(null);

    const [statusId, setStatusId] = useState('');

    const [modalConfirmLoading, setModalConfirmLoading] = useState(false);

    useEffect(() => {
        getBidList({
            pageIndex: 1,
        });

        getDefaultDate();
    }, []);

    const getBidList = async (
        {
            bidSeq,
            customerName,
            winStatus,
            active,
            exceptionStatus,
            pageIndex,
            pageSize,
        }
    ) => {
        const res = await apiBidList({
            bidSeq,
            customerName,
            winStatus,
            active,
            exceptionStatus,
            pageIndex,
            pageSize: 20,
        });

        setPageIndex(res.pageIndex);
        setTotal(res.total);

        setTableData(res.records.map((item, index) => ({...item, key: index})));
    };

    const onFinish = (
        {
            bidSeq = '',
            customerName = '',
            winStatus = 'all',
            active = 'all',
            exceptionStatus = 'all',
        }
    ) => {

        getBidList({
            bidSeq: bidSeq.trim(),
            customerName: customerName.trim(),
            winStatus: winStatus == 'all' ? '' : winStatus,
            active: active == 'all' ? '' : active,
            exceptionStatus: exceptionStatus == 'all' ? '' : exceptionStatus,
            pageIndex: 1,
        });
    };

    const pageChange = (page) => {

        const bidSeq = form.getFieldValue('bidSeq') || '';
        const customerName = form.getFieldValue('customerName') || '';
        const winStatus =  form.getFieldValue('winStatus') || '';
        const exceptionStatus = form.getFieldValue('exceptionStatus') || '';

        const active =  form.getFieldValue('active');

        getBidList({
            bidSeq: bidSeq.trim(),
            customerName: customerName.trim(),
            winStatus: winStatus == 'all' ? '' : winStatus,
            active: active == 'all' ? '' : active,
            exceptionStatus: exceptionStatus == 'all' ? '' : exceptionStatus,
            pageIndex: page,
        });
    };

    const handleEditBtnClick = (id) => async () => {
        console.log(id);

        // const res = await apiBidUpdate()
        //

        setEditId(id);

        // setModalVisible(true);
    };

    const handleDelBtnClick = (id) => async () => {

        // Modal.confirm({
        //     title: '删除标书',
        //     icon: <ExclamationCircleOutlined />,
        //     content: '是否要删除该标书?',
        //     okText: '确定',
        //     okType: 'danger',
        //     cancelText: '取消',
        //     async onOk() {
        //         // await apiBidDel({id});
        //
        //         message.success("标书已删除");
        //
        //         pageChange(pageIndex);
        //     },
        //     // onCancel() {
        //     // },
        // });

    };

    const handleShowDetail = (id) => async () => {

        const res = await apiBidDetail({id});

        setBidData(res.data);

        setDrawerShow(true);
    };

    const getDefaultDate = () => {
        const _currentTime = new Date();

        const year = _currentTime.getFullYear();

        const month = _currentTime.getMonth();

        const targetMonth = month + 6 > 11 ? month + 6 - 12 : month + 6;

        const targetYear = month + 6 > 11 ? year + 1 : year;

        const targetDay = new Date(targetYear, targetMonth + 1, 0).getDate();

        return moment(`${targetYear}-${targetMonth + 1}-${targetDay}`);

    };

    const handleModalOk = async () => {
        try {
            const { type, vdate} = await formNew.validateFields();

            setModalConfirmLoading(true);

            if (type == '1') {
                await apiBidActivationSuccess({
                    id: statusId,
                    expireTime: vdate.format('YYYY-MM-DD')
                });
            }

            if (type == '2') {
                await apiBidActivationFailure({id: statusId});
            }

            pageChange(pageIndex);

            formNew.resetFields();
            setModalConfirmLoading(false);

            message.success(type == '1' ? '已设置激活成功' : '已设置激活失败');

            setStatusSelect('');
            setStatusId('');
        } catch (e) {
            message.error(e.message);
            setModalConfirmLoading(false);
        }
    };

    return <div className="page-bid-list">
        <Header>
            <div className="flex items-center justify-between">
                <Breadcrumb>
                    <Breadcrumb.Item href="/#/">
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="">
                        <FileTextOutlined />
                        <span>标书管理</span>
                    </Breadcrumb.Item>
                </Breadcrumb>

            </div>
        </Header>

        <div className="content-panel-common">
            <div className="filter-pannel-common">
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                >
                    <Row gutter={24} >

                        <Col span={8}>
                            <Form.Item
                                name="bidSeq"
                                label="标号"
                            >
                                <Input placeholder="输入标号" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="customerName"
                                label="客户昵称"
                            >
                                <Input placeholder="客户昵称" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="exceptionStatus"
                                label="状态"
                                initialValue='all'
                            >
                                <Select className="w-100" placeholder="选择标书状态" onChange={() => {
                                    pageChange(1);
                                }}>
                                    <Option value="all">全部</Option>
                                    <Option value="00">正常</Option>
                                    <Option value="03">已过期</Option>
                                    <Option value="04">次数已使用完</Option>
                                    <Option value="05">激活失败</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="active"
                                label="是否激活"
                                initialValue='all'
                            >
                                <Select className="w-100" placeholder="选择激活状态" onChange={() => {
                                    pageChange(1);
                                }}>
                                    <Option value="all">全部</Option>
                                    <Option value={false}>未激活</Option>
                                    <Option value={true}>已激活</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="winStatus"
                                label="是否中标"
                                initialValue='all'
                            >
                                <Select className="w-100" placeholder="选择中标状态" onChange={() => {
                                    pageChange(1);
                                }}>
                                    <Option value="all">全部</Option>
                                    <Option value="0">未中标</Option>
                                    <Option value="1">已中标</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col md={8} lg={6} >
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                            <Button
                                style={{ marginLeft: 8 }}
                                onClick={() => {
                                    form.resetFields();
                                    getBidList({
                                        pageIndex: 1,
                                    });
                                }}
                            >
                                重置
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div style={{marginTop: 10}}>
                <Table
                    // loading={true}
                    pagination={{
                        defaultCurrent: 1,
                        current: pageIndex,
                        total,
                        showSizeChanger: false,
                        pageSize: 20,
                        onChange: pageChange,
                    }}
                    dataSource={tableData}
                    // scroll={{ x: 1600 }}
                >
                    <Column
                        title="标号"
                        width={66}
                        fixed="left"
                        dataIndex="bidSequence"
                        key="bidSequence"
                        render={(text, item) => <div className="relative">{text} {item.name == 'test' ? <div className="tag-test">测试</div> : ''}</div>}
                    />
                    {/*<Column title="密码" width={56} dataIndex="password" key="password" />*/}
                    <Column title="姓名" width={56} dataIndex="name" key="name" />
                    <Column title="证件号" width={120} dataIndex="certNo" key="certNo" />
                    <Column title="手机号" width={88} dataIndex="phone" key="phone" />
                    <Column title="客户"  width={120} dataIndex="customerId" key="customerId" render={(customerId, item) => <>
                        <div className="flex items-center">
                            <div
                                className="avatar"
                                style={{backgroundImage: `url('${item.avatarUrl}')`}}
                            ></div>
                          <div className="w-0 flex-grow-1 pointer text-primary" style={{marginLeft: 6, width: 'fit-content'}} onClick={() => {
                                window.localStorage.setItem('customerId', customerId);

                                props.history.push('/customer-list');
                                
                            }}>{item.nickName}</div>
                        </div>
                    </>} />
                    <Column title="状态" width={55} dataIndex="exceptionStatus" key="exceptionStatus" render={(exceptionStatus) => ['正常', '', '', '已过期', '次数已使用完', '激活失败'][parseInt(exceptionStatus)]} />

                    <Column title="已拍次数" width={66} dataIndex="useCount" key="useCount" />

                    <Column title="过期时间" width={100} dataIndex="expireTime" key="expireTime" render={(item, record) => <div>{item ? /\d{4}-\d{2}-\d{2}/.exec(item)[0] : <div className="text-error">{record.exceptionStatus === '05' ? '激活失败' : <div>尚未激活 <span style={{marginLeft: 8}} className="text-primary pointer" onClick={() => setStatusId(record.bidId)}>操作</span></div>}</div>}</div>} />

                    {/*<Column title="订单ID" width={140} dataIndex="orders" key="orders" render={(item) => <span>{item && item.length ? item[0].orderId : ''}</span>} />*/}
                    {/*<Column title="订单状态" width={80} dataIndex="orders" key="orders" render={(text) => (({'01': '正在进行中', '02': '拍牌成功,等待付款', '03': '已付款,交易完成', '04': '拍牌失败,等待赔付', '05': '已赔付,交易完成', '06': '已取消', '07': '已暂停'})[text])} />*/}
                    {/*<Column title="代拍次数" width={66} dataIndex="execCount" key="execCount" />*/}

                    <Column title="是否中标" width={66} dataIndex="winStatus" key="winStatus" render={(text) => <span className={['text-error', 'text-success'][parseInt(text)]}>{['未中标', '已中标'][parseInt(text)]}</span>} />
                    <Column title="创建时间" width={100} dataIndex="createTime" key="createTime" />
                    {/*<Column title="代拍记录" fixed="right" width={90} dataIndex="password" key="password" render={() => <div className="flex flex-wrap">*/}
                    {/*    <Button size="small">20-10-01</Button>*/}
                    {/*    <Button size="small">20-11-01</Button>*/}
                    {/*</div>} />*/}

                    {/*<Column*/}
                    {/*    title="异常信息"*/}
                    {/*    width={100}*/}
                    {/*    dataIndex="importStatus"*/}
                    {/*    key="importStatus"*/}
                    {/*    render={*/}
                    {/*        (text, record) => ['', '标号重复', '证件号校验失败'][parseInt(text || 0)]*/}
                    {/*    }*/}
                    {/*/>*/}
                    {/*<Column*/}
                    {/*    title="操作"*/}
                    {/*    key="action"*/}
                    {/*    width={100}*/}
                    {/*    fixed="right"*/}
                    {/*    render={(text, record) => (*/}
                    {/*        <Space size="middle">*/}


                    {/*        </Space>*/}
                    {/*    )}*/}
                    {/*/>*/}
                </Table>
            </div>

            <Modal
                title="修改标书激活状态"
                visible={!!statusId}
                onOk={handleModalOk}
                confirmLoading={modalConfirmLoading}
                onCancel={() => {
                    setStatusId('');
                    formNew.resetFields();
                    setStatusSelect(null);
                }}
                okText="确定"
                cancelText="取消"
                maskClosable={false}
            >
                <Form
                    form={formNew}
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 17 }}
                    name="basic"
                >
                    <Form.Item
                        label="选择状态"
                        name="type"
                        rules={[{ required: true, message: '请选择标书状态' }]}
                    >
                        <Select placeholder="请选择标书状态" onChange={(v) => {
                            setStatusSelect(v);
                        }}>
                            <Option value="1">激活成功</Option>
                            <Option value="2">激活失败</Option>
                        </Select>
                    </Form.Item>

                    {
                        statusSelect == '1' ?  <Form.Item
                            label="过期时间"
                            name="vdate"
                            initialValue={getDefaultDate()}
                            rules={[{ required: true, message: '请选择过期时间' }]}
                        >
                            <DatePicker style={{width: '100%'}} locale={locale} placeholder="请选择过期时间" />
                        </Form.Item> : ''
                    }


                </Form>
            </Modal>

        </div>

    </div>;
};

export default withRouter(BidList);