
// 统计分析
import $fetch from "./fetch";

// 统计分析
export const apiStatisticCustomerLatent = (
    {
        startTime,
        endTime,
        resource,
        oldPhone,
        reverse,
        userId,
    }
    ) => {
    return $fetch('/api/cms/statistics/customers/latent', {
        method: 'GET',
        params: {
            startTime,
            endTime,
            resource,
            oldPhone,
            reverse,
            userId,
        }
    });
};


export const apiStatisticEmployee = () => {
    return $fetch('/api/cms/statistics/settle', {
        method: 'POST',
    });
};


export const apiSalaryDeliver = () => {
    return $fetch('/api/cms/statistics/deliver', {
        method: 'POST',
    });
};

export const apiSalaryClean = () => {
    return $fetch('/api/cms/statistics/clean', {
        method: 'POST',
    });
};