
import React, { FormEvent, useState, useEffect } from 'react';
import {Layout, Input, Button, Divider, Checkbox, Form, message, Spin, Switch as ASwitch} from 'antd';
import { WechatOutlined, LockOutlined, UserOutlined, ToolOutlined } from '@ant-design/icons';
import {
    Link,
    useLocation,
} from 'react-router-dom';

import './auth.less';
import {apiLogin} from "../../api/auth";

import wxLogin from '../../util/wxLogin';
import {getQueryObj} from "../../util";
import appConfig from "../../appConfig";

const FormItem = Form.Item;

const Login = (props) => {
    let location = useLocation();

    // 处于登录过程中？
    const [login, setLogin] = useState(false);

    // 登录类型 0 表示码工登录  1表示管理员登录
    const [loginType, setLoginType] = useState(1);

    const [isTest, setIsTest] = useState(!!localStorage.getItem('isTest'));

    // useEffect(() => {
    //
    //     const { code, type } = getQueryObj();
    //
    //     if (code) {
    //         setLogin(true);
    //
    //         // 发送登录请求给后端
    //         console.log(code);
    //
    //         apiLogin({ code }).then(res => {
    //             if (res.code === 0) {
    //                 localStorage.setItem('userInfo', JSON.stringify({
    //                     token: res.data.accessToken,
    //                     nickName: res.data.user.nickName,
    //                     avatar: res.data.user.avatarUrl,
    //                 }));
    //
    //                 window.location.replace('/#/');
    //             }
    //         });
    //     }
    //
    //     wxLogin({
    //         self_redirect: false,
    //         id:"wx-login-wrap",
    //         appid: appConfig.appId,
    //         scope: "snsapi_login",
    //         redirect_uri: encodeURIComponent("https://www.damajl.com/#/login?type=2"),
    //         state: "",
    //         style: "",
    //         href: ""
    //     });
    //
    // }, [location]);

    const handleLogin = async ({name, password}) => {
        console.log(name, password);

        const res = await apiLogin({
            userName: name,
            password,
        })

        localStorage.setItem('userInfo', JSON.stringify(res));

        window.location.replace('/#/');
    }

    return (
        <div className="page-login absolute absolute-fill flex items-center justify-center">

            <div className="login-panel-wrap">

                <div className="tab-wrap flex tc justify-between">
                    <div className="title flex items-center justify-center">
                        <i className="logo"></i>
                        <div className="flex flex-column items-center">
                            <p className="fs-18 lh-sm">蚂蚁代拍</p>
                            <p className="fs-12 text-black-secondary lh-sm">蚂蚁代拍运营后台</p>
                        </div>
                    </div >

                    <div className="test-wrap flex items-center" style={{marginRight: 10}}>
                        <div style={{marginRight: 10}}>开启测试模式</div>
                        <ASwitch checked={isTest} onChange={(e) => {
                            setIsTest(e);
                            window.localStorage.setItem('isTest', e ? 'true' : '');
                            window.localStorage.removeItem('userInfo');
                            window.location.reload();
                        }} />
                    </div>
                </div>

                {
                    loginType === 0 && <div className="login-panel-worker">

                        <div className="flex items-center justify-center" style={{marginTop: 8}}>
                            <div>
                                <div className="wx-logo"></div>
                                <p className="text-black-body lh-huge tc">微信扫码登录</p>
                            </div>

                            <div className="wx-login-wrap pointer flex flex-column items-center justify-center">
                                <div id="wx-login-wrap" style={{height: 340}}>
                                </div>
                            </div>
                        </div>

                    </div>
                }

                {
                    loginType === 1 && <Form
                        name="normal_login"
                        className="login_form m-auto"
                        onFinish={handleLogin}
                    >
                        <FormItem
                            name="name"
                            style={{paddingTop: 10}}
                            rules={[{ required: true, message: '请输入用户名!' }]}
                        >
                            <Input
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                size="large"
                                placeholder="用户名"
                            />
                        </FormItem>
                        <FormItem
                            name="password"
                            style={{paddingTop: 10}}
                            rules={[{ required: true, message: '请输入密码!' }]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="密码"
                                size="large"
                            />
                        </FormItem>

                        {/*<div className="flex justify-between">*/}
                        {/*    <FormItem name="remember" valuePropName="checked" noStyle>*/}
                        {/*        <Checkbox>记住我</Checkbox>*/}
                        {/*    </FormItem>*/}

                        {/*    <a className="text-error" href="">*/}
                        {/*        忘记密码?*/}
                        {/*    </a >*/}
                        {/*</div>*/}

                        <div style={{paddingTop: 20,}}>
                            <Button size="large" block type="primary" htmlType="submit" className="login-form-button">
                                登录
                            </Button>
                        </div>
                    </Form>
                }
            </div>

            {
                login && <div className="absolute absolute-fill flex flex-column items-center justify-center"
                              style={{backgroundColor: 'rgba(255, 255, 255, 0.8)'}}>
                    <Spin size="large"/>
                    <p className="tc text-primary lh-huge">正在登录...</p>
                </div>
            }

        </div>
    );
};

export default Login;