// 用户管理接口
import $fetch from "./fetch";

// 新增用户
export const apiRoleAdd = ({
       remark,
       roleName,
   }) => {
    return $fetch('/api/cms/roles', {
        method: 'POST',
        params: {
            remark,
            roleName,
        }
    });
}

// 查询角色信息
export const apiUserRole = ({
    id,
}) => {
    return $fetch(`/api/cms/roles/${id}`, {
        method: 'GET',
    });
}

// 更新角色信息
export const apiRoleUpdate = ({
     id,
     role,
 }) => {
    return $fetch(`/api/cms/roles/${id}`, {
        method: 'PUT',
        params: {
            role,
        },
    });
}

// 角色列表
export const apiRoleList = ({ pageIndex = 1, pageSize = 10 } = {pageIndex: 1, pageSize: 10}) => {
    return $fetch('/api/cms/roles/page', {
        method: 'GET',
        params: {
            pageIndex,
            pageSize,
        }
    });
};

// 删除角色
export const apiRoleDel = ({id}) => {
    return $fetch(`/api/cms/roles/${id}`, {
        method: 'DELETE',
    });
}

// 修改系统角色权限
export const apiRolePermissions = ({
    id,
    permissionIds
}) => {
    return $fetch(`/api/cms/roles/${id}`, {
        method: 'PUT',
        params: {
            permissionIds
        },
    });
}

