
import React, { FormEvent, useState, useEffect } from 'react';
import {Switch } from 'antd';

import './index.less';

const Header = (props) => {
    const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo') || '{}'));


    return <div className="app-header flex items-center">
        <div className="flex-grow-1">
            {props.children}
        </div>

        <div className="info-wrap flex items-center">
            <div className="inner flex items-center pointer">
                <div className="avatar" style={{backgroundImage: `url(${userInfo.avatar})`}}></div>
                <div>{userInfo.user ? userInfo.user.userName : ''}</div>
                <div className="btn-logout text-primary" onClick={() => {
                    localStorage.removeItem('userInfo');
                    window.location.reload();
                }}>
                    退出
                </div>
            </div>
        </div>
    </div>
};

export default Header;