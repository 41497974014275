// 标书管理接口
import $fetch from "./fetch";

// 标书列表
export const apiBidList = ({ bidId = '', active, bidSeq ='', customerName = '', winStatus, exceptionStatus, pageIndex = 1, pageSize = 10 } = {blurSearch: false, pageIndex: 1, pageSize: 10}) => {
    return $fetch('/api/cms/bids/page', {
        method: 'GET',
        params: {
            active,
            bidId,
            bidSeq,
            customerName,
            winStatus,
            exceptionStatus,
            pageIndex,
            pageSize,
        }
    });
};


// // 标书删除
// export const apiBidDel = ({ id }) => {
//     return $fetch(`/bids/bid/${id}`, {
//         method: 'DELETE',
//     });
// }

// 标书详情
export const apiBidDetail = ({id}) => {
    return $fetch(`/api/cms/bids/${id}`, {
        method: 'GET',
    });
}

// 标书激活成功
export const apiBidActivationSuccess = ({id, expireTime}) => {
    return $fetch(`/api/cms/bids/${id}/activation/success`, {
        method: 'PUT',
        params: {
            expireTime,
        }
    });
}

// 标书激活失败
export const apiBidActivationFailure = ({id}) => {
    return $fetch(`/api/cms/bids/${id}/activation/failure`, {
        method: 'PUT',
    });
}