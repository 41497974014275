
import React, {useEffect, useState, Fragment} from 'react';

import {Button, Col, Form, Input, Layout, message, Modal, Row, Select, Space, Switch, Table} from 'antd';

import { withRouter } from "react-router";

import { Link, useLocation } from 'react-router-dom';

import { Breadcrumb, Typography } from 'antd';
import { HomeOutlined, CommentOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import SiderMenu from "../../components/SiderMenu";

import Header from "../../components/Header";

import logo from '../../logo.svg';

import './index.less';

import {usePageViews} from "../../App";

import imgCircle from '../../assets/imgs/circle.svg';
import imgCircleSpec from '../../assets/imgs/circle-spec.svg';
import {apiOverview} from "../../api";
import {apiRoleAdd, apiRoleDel, apiRoleUpdate} from "../../api/role";
import {apiComboAdd, apiComboDel, apiComboList, apiComboUpdate} from "../../api/combo";
import {apiMachineList} from "../../api/machine";

const { Title } = Typography;

const { Column, ColumnGroup } = Table;

const { Option } = Select;

const ComboList = (props) => {

    const [formNew] = Form.useForm();

    const [pageIndex, setPageIndex] = useState(1);

    const [total, setTotal] = useState(0);

    const [tableData, setTableData] = useState([]);

    const [editId, setEditId] = useState('');

    const [modalVisible, setModalVisible] = useState(false);

    const [modalConfirmLoading, setModalConfirmLoading] = useState(false);

    // const [editId, setEditId] = useState('');

    useEffect(() => {
        getComboList({
            pageIndex: 1,
        });
    }, []);

    useEffect(() => {

        if (!editId) {
            return;
        }

        const {
            name,
            orderNum,
            pay,
            payRemark,
            payType,
            price,
            recommend,
            remark,
        } = tableData.find(item => item.packageId == editId);

        formNew.setFieldsValue({
            name,
            orderNum,
            pay,
            payRemark,
            payType,
            price,
            recommend,
            remark,
        });

    }, [editId]);

    const onFinish = ({ type = '', answer = '', question = ''}) => {
        getComboList({
            type,
            answer: answer.trim(),
            question: question.trim(),
            pageIndex: 1,
        });
    };

    const pageChange = (page) => {
        getComboList({
            pageIndex: page,
        });
    };

    const getComboList = async (
        {
            question,
            pageIndex = 1,
        }
    ) => {
        const res = await apiComboList({
            question,
            pageIndex,
        });

        console.log(res);

        setPageIndex(res.pageIndex);
        setTotal(res.total);

        setTableData(res.records.map((item, index) => ({...item, key: index})));
    };

    const handleModalOk = async () => {
        try {
            const {
                name,
                orderNum,
                pay,
                payRemark,
                payType,
                price,
                recommend,
                remark,
            } = await formNew.validateFields();

            setModalConfirmLoading(true);

            if (editId) {
                await apiComboUpdate({
                    id: editId,
                    name: name.trim(),
                    orderNum,
                    pay,
                    payRemark: payRemark.trim(),
                    payType,
                    price,
                    recommend,
                    remark: remark.trim(),
                });

                pageChange(1);
            } else {
                await apiComboAdd({
                    name: name.trim(),
                    orderNum,
                    pay,
                    payRemark: payRemark.trim(),
                    payType,
                    price,
                    recommend,
                    remark: remark.trim(),
                });

                getComboList({
                    pageIndex: 1,
                });
            }

            formNew.resetFields();
            setModalVisible(false);
            setModalConfirmLoading(false);

            message.success(`套餐${editId ? '更新' : '添加'}成功`);

            setEditId(null);

        } catch (e) {
            message.error(e.message);
            setModalConfirmLoading(false);
        }
    };

    const handleModalCancel = () => {
        setEditId(null);
        setModalVisible(false);
        formNew.resetFields();
    };

    const handleEditBtnClick = (id) => async () => {
        console.log(id);

        setEditId(id);

        setModalVisible(true);
    };

    const handleDelBtnClick = (id) => async () => {

        Modal.confirm({
            title: '删除套餐',
            icon: <ExclamationCircleOutlined />,
            content: '是否要删除该套餐?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                await apiComboDel({id});

                message.success("套餐已删除");

                pageChange(pageIndex);
            },
            // onCancel() {
            // },
        });

    };

    return <div className="page-coupon-settings">
        <Header>
            <div className="flex items-center justify-between">
                <Breadcrumb>
                    <Breadcrumb.Item href="/#/">
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/#/">
                        <CommentOutlined />
                        <span style={{marginLeft: 8}}>套餐管理</span>
                    </Breadcrumb.Item>
                </Breadcrumb>

                <Button type="primary" shape="round" onClick={() => {
                    setModalVisible(true);
                }} ><PlusOutlined />新增套餐</Button>
            </div>

        </Header>

        <div className="content-panel-common">

            <div style={{marginTop: 10}}>
                <Table
                    // loading={true}
                    pagination={{
                        defaultCurrent: 1,
                        current: pageIndex,
                        total,
                        showSizeChanger: false,
                        pageSize: 50,
                        onChange: pageChange,
                    }}
                    dataSource={tableData}
                    // scroll={{ x: 1600 }}
                >
                    <Column
                        title="编号"
                        dataIndex="packageId"
                        key="packageId"
                        width={80}
                    />
                    <Column
                        title="套餐名"
                        dataIndex="name"
                        key="name"
                        width={140}
                    />
                    <Column title="赔付类型" width={120} dataIndex="payType" key="payType" render={(val) => ({'01': '6次', '02': '单次'})[val]} />
                    <Column title="价格" width={100} dataIndex="price" key="price" />
                    <Column title="赔付" width={100} dataIndex="pay" key="pay"  />
                    <Column title="提示" width={280} dataIndex="remark" key="remark" />
                    <Column title="支付提示" width={220} dataIndex="payRemark" key="payRemark" />
                    <Column title="推荐" width={80} dataIndex="recommend" key="recommend" render={(val) => val ? '是' : '否'} />
                    <Column title="排序" width={80} dataIndex="orderNum" key="orderNum" />
                    <Column title="创建时间" width={140} dataIndex="createTime" key="createTime" />
                    <Column
                        title="操作"
                        key="action"
                        width={120}
                        render={(text, record) => (
                            <Space size="middle">
                                <span className="text-primary pointer" onClick={handleEditBtnClick(record.packageId)}>编辑</span>
                                <span className="text-error pointer" onClick={handleDelBtnClick(record.packageId)}>删除</span>
                            </Space>
                        )}
                    />
                </Table>
            </div>
        </div>


        <Modal
            title={editId ? '套餐编辑' : '新增套餐'}
            visible={modalVisible}
            onOk={handleModalOk}
            confirmLoading={modalConfirmLoading}
            onCancel={handleModalCancel}
            okText="确定"
            cancelText="取消"
            maskClosable={false}
        >
            <Form
                form={formNew}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 17 }}
                name="basic"
            >
                <Form.Item
                    label="套餐名"
                    name="name"
                    rules={[{ required: true, message: '请输入套餐名' }]}
                >
                    <Input maxLength={50} placeholder="请输入套餐名" />
                </Form.Item>

                <Form.Item
                    label="排序数字"
                    name="orderNum"
                    rules={[{ required: true, message: '请输入排序数字' }]}
                >
                    <Input type="number" maxLength={50} placeholder="请输入排序数字（越小越靠前）" />
                </Form.Item>

                <Form.Item
                    label="套餐类型"
                    name="payType"
                    rules={[{ required: true, message: '请选择套餐类型' }]}
                    initialValue="01"
                >
                    <Select placeholder="请选择套餐类型" onChange={() => {
                    }}>
                        <Option value="01">单标书</Option>
                        <Option value="02">双标书</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="价格"
                    name="price"
                    rules={[{ required: true, message: '请输入套餐价格' }]}
                >
                    <Input type="number" maxLength={50} placeholder="请输入套餐价格" />
                </Form.Item>

                <Form.Item
                    label="赔付"
                    name="pay"
                    rules={[{ required: true, message: '请输入套餐赔付' }]}
                >
                    <Input type="number" maxLength={50} placeholder="请输入套餐赔付" />
                </Form.Item>

                <Form.Item
                    label="提示"
                    name="remark"
                    rules={[{ required: true, message: '请输入提示' }]}
                >
                    <Input maxLength={100} placeholder="请输入提示" />
                </Form.Item>

                <Form.Item
                    label="支付提示"
                    name="payRemark"
                    rules={[{ required: true, message: '请输入支付提示' }]}
                >
                    <Input maxLength={100} placeholder="请输入支付提示" />
                </Form.Item>

                <Form.Item
                    label="是否推荐"
                    name="recommend"
                    valuePropName="checked"
                    initialValue={false}
                >
                    <Switch />
                </Form.Item>
            </Form>
        </Modal>

    </div>;
};

export default ComboList;
