// 用户管理接口
import $fetch from "./fetch";

// 新增用户
export const apiUserAdd = ({
       mobile,
       realName,
       userName,
   }) => {
    return $fetch('/api/cms/users', {
        method: 'POST',
        params: {
            mobile,
            realName,
            userName,
        }
    });
}

// 修改用户信息
export const apiUserUpdate = ({
                                     id,
                                     mobile,
                                     realName,
                                     userName,
 }) => {
    return $fetch(`/api/cms/users/${id}`, {
        method: 'PUT',
        params: {
            mobile,
            realName,
            userName,
        }
    });
}

// 重置密码
export const apiResetPwd = ({ id }) => {
    return $fetch(`/api/cms/users/${id}/password/reset`, {
        method: 'PUT',
    });
}

// 用户列表
export const apiCustomerList = ({ customerId = '', customerName = '', onlyUnbind, saleName = '', pageIndex = 1, pageSize = 10 } = {pageIndex: 1, pageSize: 10}) => {
    return $fetch('/api/cms/customers/page', {
        method: 'GET',
        params: {
            customerId,
            customerName,
            onlyUnbind,
            saleName,
            pageIndex,
            pageSize,
        }
    });
};


// 潜在用户列表
export const apiCustomerLatentList = ({ channel, hasAssign, hasExchange, name, userId, tagStr, phoneCode, resource, pageIndex = 1, pageSize = 50 } = {pageIndex: 1, pageSize: 10}) => {
    return $fetch('/api/cms/customers/latent/page', {
        method: 'GET',
        params: {
            channel,
            hasAssign,
            name,
            phoneCode,
            hasExchange,
            userId,
            tagStr,
            resource: resource ? encodeURIComponent(resource) : '',
            pageIndex,
            pageSize,
        }
    });
};


// 批量导入
export const apiCustomerLatentBatch = ({ name = '', csv = '' }) => {
    return $fetch('/api/cms/customers/latent/batch', {
        method: 'POST',
        params: {
            name,
            csv,
        }
    });
};


// 更新用户信息
export const apiCustomerLatentUpdate = ({ id, avatarUrl, name, nickName, wechatNo }) => {
    return $fetch(`/api/cms/customers/latent/${id}`, {
        method: 'PUT',
        params: {
            id,
            avatarUrl,
            name,
            nickName,
            wechatNo
        }
    });
};

// 分配潜在客户给业务员
export const apiCustomerLatentDispatch = ({lcIds, userId}) => {
    return $fetch('/api/cms/customers/latent/dispatcher', {
        method: 'POST',
        params: {
            lcIds,
            userId,
        }
    });
}

export const apiCustomerLatentDelete = ({lcIds}) => {
    return $fetch('/api/cms/customers/latent/allocation', {
        method: 'POST',
        params: {
            lcIds,
        }
    });
}

// 根据客户 ID 查询，可能关联的潜在客户
export const apiCustomerAssociation = ({id}) => {
    return $fetch(`/api/cms/customers/association/${id}`, {
        method: 'GET',
    });
}


// 根据潜在客户条件搜索符合的潜在客户
export const apiCustomerLatentSearch = ({condition, pageIndex, pageSize} = {pageIndex: 1, pageSize: 20}) => {
    return $fetch('/api/cms/customers/latents/page', {
        method: 'GET',
        params: {
            condition,
            pageIndex,
            pageSize: pageSize || 20,
        }
    });
}

// 添加关联
export const apiCustomerAssLc = ({customerId, lcId}) => {
    return $fetch(`/api/cms/customers/${customerId}/association/${lcId}`, {
        method: 'POST',
    });
}

// 删除关联
export const apiCustomerAssDel = ({id}) => {
    return $fetch(`/api/cms/customers/association/${id}`, {
        method: 'DELETE',
    });
}

// 绑定售后
export const apiCustomerSaleAdd = ({id, salerId = null, maintained = null}) => {
    return $fetch(`/api/cms/customers/${id}/sale`, {
        method: 'PUT',
        params: {
            salerId,
            maintained,
        }
    });
};

// 删除售后
export const apiCustomerSaleDel = ({id}) => {
    return $fetch(`/api/cms/customers/${id}/sale`, {
        method: 'DELETE',
    });
};

export const apiCustomerSupply = ({cookie, token}) => {
    return $fetch('/api/cms/customers/supply', {
        method: 'PUT',
        params: {
            cookie,
            token,
        }
    })
};

// 查询销售以及今日已分配信息
export const apiLatentBatchInfo = () => {
    return $fetch('/api/cms/customers/latent/batch/info', {
        method: 'GET',
    });
}

// "size": 0,
// "userId": 0
export const apiLatentBatch = (clearToday = false, batchInfo = []) => {
    return $fetch('/api/cms/customers/latent/dispatcher/batch', {
        method: 'POST',
        params: {
            clearBefore: clearToday,
            data: batchInfo,
        }
    });
}