
import React, { useEffect, useState } from 'react';

import {Form, Row, Col, Input, Button, Table, Tag, Space, Breadcrumb, Menu, Modal, Select, message} from 'antd';

import './index.less';
import Header from "../../components/Header";
import { HomeOutlined, UserOutlined, PlusOutlined, SafetyCertificateOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {usePageViews} from "../../App";
import {apiPartnerAdd, apiPartnerDel, apiPartnerList, apiPartnerUpdate} from "../../api/partner";
import {apiBidAdd, apiBidDel, apiBidDELETE, apiBidUpdate} from "../../api/bid";
import {Link} from "react-router-dom";
import {apiUserAdd, apiUserList, apiUserUpdate} from "../../api/user";
import {apiRoleAdd, apiRoleDel, apiRoleList, apiRoleUpdate} from "../../api/role";
// import {apiWorkerList} from "../../api";

const { Column, ColumnGroup } = Table;

const RoleList = () => {

    usePageViews();

    useEffect(() => {
        getRoleList({
            name: '',
            pageIndex: 1,
        });
    }, []);

    const [tableData, setTableData] = useState([]);

    const [expand, setExpand] = useState(false);

    const [form] = Form.useForm();

    const [formNew] = Form.useForm();

    const [editId, setEditId] = useState('');

    const [pageIndex, setPageIndex] = useState(1);

    const [total, setTotal] = useState(0);

    const [modalVisible, setModalVisible] = useState(false);

    const [modalConfirmLoading, setModalConfirmLoading] = useState(false);

    useEffect(() => {

        if (!editId) {
            return;
        }

        const {roleName, remark} = tableData.find(item => item.roleId == editId);

        console.log(roleName, remark);

        formNew.setFieldsValue({
            roleName,
            remark
        });

    }, [editId]);

    const getRoleList = async ({
        pageIndex = 1,
    }) => {
        const res = await apiRoleList({
            pageIndex
        });

        setPageIndex(res.pageIndex);
        setTotal(res.total);

        setTableData(res.records.map((item, index) => ({...item, key: index})));
    };

    const onFinish = () => {
        getRoleList({
            pageIndex: 1,
        });
    };

    const pageChange = (page) => {
        getRoleList({
            pageIndex: page,
        });
    };

    const handleModalOk = async () => {
        try {
            const { roleName, remark } = await formNew.validateFields();

            setModalConfirmLoading(true);

            if (editId) {
                await apiRoleUpdate({
                    id: editId,
                    roleName,
                    remark
                });
            } else {
                await apiRoleAdd({
                    roleName,
                    remark
                });
            }

            formNew.resetFields();
            setModalVisible(false);
            setModalConfirmLoading(false);

            message.success(`角色${editId ? '更新' : '添加'}成功`);

            setEditId(null);

            getRoleList({
                pageIndex: 1,
            });

        } catch (e) {
            message.error(e.message);
            setModalConfirmLoading(false);
        }
    };

    const handleModalCancel = () => {
        setEditId(null);
        setModalVisible(false);
        formNew.resetFields();
    };

    const handleBtnNewClick = () => {
        setModalVisible(true);
    };

    const handleEditBtnClick = (id) => async () => {
        console.log(id);

        // const res = await apiBidUpdate()
        //

        setEditId(id);

        setModalVisible(true);
    };

    const handleDelBtnClick = (id) => async () => {

        Modal.confirm({
            title: '删除角色',
            icon: <ExclamationCircleOutlined />,
            content: '是否要删除该角色?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                await apiRoleDel({id});

                message.success("角色已删除");

                pageChange(pageIndex);
            },
            // onCancel() {
            // },
        });

    };

    return <div className="page-role-list">
        <Header>
            <div className="flex items-center justify-between">
                <Breadcrumb>
                    <Breadcrumb.Item href="/#/">
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="">
                        <SafetyCertificateOutlined />
                        <span>角色管理</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div>
                    <Button
                        style={{margin: '0 15px'}}
                        shape="round"
                        icon={<PlusOutlined />}
                        onClick={handleBtnNewClick}
                    >
                        新增角色
                    </Button>
                </div>
            </div>

        </Header>

        <div className="content-panel-common">

            <div >
                <Table
                    // loading={true}
                    pagination={{
                        defaultCurrent: 1,
                        current: pageIndex,
                        total,
                        showSizeChanger: false,
                        pageSize: 10,
                        onChange: pageChange,
                    }}
                    dataSource={tableData}
                >
                    <Column title="角色ID" dataIndex="roleId" key="roleId" />
                    <Column title="角色名" dataIndex="roleName" key="roleName" />
                    <Column title="标签" dataIndex="remark" key="remark" />
                    <Column title="创建者" dataIndex="createBy" key="createBy" />

                    <Column
                        title="操作"
                        key="action"
                        render={(text, record) => (
                            <Space size="middle">
                                <p className="text-primary pointer" onClick={handleEditBtnClick(record.roleId)}>编辑</p>
                                <p className="text-error pointer" onClick={handleDelBtnClick(record.roleId)}>删除</p>
                            </Space>
                        )}
                    />
                </Table>
            </div>
        </div>

        <Modal
            title={editId ? '角色编辑' : '新增角色'}
            visible={modalVisible}
            onOk={handleModalOk}
            confirmLoading={modalConfirmLoading}
            onCancel={handleModalCancel}
            okText="确定"
            cancelText="取消"
            maskClosable={false}
        >
            <Form
                form={formNew}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 17 }}
                name="basic"
                // onFinish={onFinish}
            >
                <Form.Item
                    label="角色名"
                    name="roleName"
                    rules={[{ required: true, message: '请输入角色名' }]}

                >
                    <Input maxLength={20} placeholder="请输入角色名，如 admin" />
                </Form.Item>

                <Form.Item
                    label="标签"
                    name="remark"
                    rules={[{ required: true, message: '请输入角色标签' }]}

                >
                    <Input maxLength={20} placeholder="请输入角色标签，如管理员" />
                </Form.Item>

                {/*<Form.Item*/}
                {/*    label="手机号"*/}
                {/*    name="phone"*/}
                {/*    rules={[{ required: true, message: '请输入友商手机号' }]}*/}
                {/*>*/}
                {/*    <Input />*/}
                {/*</Form.Item>*/}

            </Form>
        </Modal>

    </div>;
};

export default RoleList;