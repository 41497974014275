
import React, { useEffect, useState, Fragment } from 'react';

import { Button, Col, Form, Input, Layout, message, Modal, Row, Select, Space, Table } from 'antd';

import { withRouter } from "react-router";

import { Link, useLocation } from 'react-router-dom';

import { Breadcrumb, Typography } from 'antd';
import { HomeOutlined, HistoryOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import SiderMenu from "../../components/SiderMenu";

import Header from "../../components/Header";

import logo from '../../logo.svg';

import './index.less';

import { usePageViews } from "../../App";

import imgCircle from '../../assets/imgs/circle.svg';
import imgCircleSpec from '../../assets/imgs/circle-spec.svg';
import { apiOverview } from "../../api";
import { apiRoleAdd, apiRoleDel, apiRoleUpdate } from "../../api/role";
import { apiQaAdd, apiQaDel, apiQaList, apiQaUpdate } from "../../api/qa";
import { apiMachineList } from "../../api/machine";
import { apiPayList, apiPayUpdate } from "../../api/pay";
import { apiUserAdd, apiUserList, apiUserUpdate } from "../../api/user";


const { Title } = Typography;

const { Column, ColumnGroup } = Table;

const { Option } = Select;

const PayList = (props) => {

    const [form] = Form.useForm();

    const [formNew] = Form.useForm();

    const [pageIndex, setPageIndex] = useState(1);

    const [total, setTotal] = useState(0);

    const [tableData, setTableData] = useState([]);

    const [editId, setEditId] = useState('');

    const [modalVisible, setModalVisible] = useState(false);

    const [marketerList, setMarketerList] = useState([]);

    const [modalConfirmLoading, setModalConfirmLoading] = useState(false);

    // const [editId, setEditId] = useState('');

    useEffect(() => {
        getPayList({
            pageIndex: 1,
        });

        getMarketerList();

    }, []);

    const onFinish = ({ bidPeriod = '', orderId = '', payStatus = '', piId = '', saleId = '' }) => {
        getPayList({
            bidPeriod,
            orderId,
            payStatus,
            piId,
            saleId,
            pageIndex: 1,
        });
    };

    const getMarketerList = async () => {
        const res = await apiUserList({ roleName: 'sale', pageSize: 100 });

        setMarketerList(res.records.filter(item => item.enabled == '1'));
    };

    const pageChange = (page) => {
        const bidPeriod = (form.getFieldValue('bidPeriod') || '').trim();
        const orderId = (form.getFieldValue('orderId') || '').trim();
        const payStatus = (form.getFieldValue('payStatus') || '').trim();
        const piId = (form.getFieldValue('piId') || '').trim();
        const saleId = (form.getFieldValue('saleId') || '').trim();

        getPayList({
            bidPeriod,
            orderId,
            payStatus,
            piId,
            saleId,
            pageIndex: page,
        });
    };

    const getPayList = async (
        {
            bidPeriod,
            orderId,
            payStatus,
            piId,
            saleId,
            pageIndex = 1,
        }
    ) => {
        const res = await apiPayList({
            bidPeriod: bidPeriod == 'all' ? '' : bidPeriod,
            orderId,
            payStatus: payStatus == 'all' ? '' : payStatus,
            piId,
            saleId: saleId == 'all' ? '' : saleId,
            pageIndex,
        });

        console.log(res);

        setPageIndex(res.pageIndex);
        setTotal(res.total);

        setTableData(res.records.map((item, index) => ({ ...item, key: index })));
    };

    const handlePayConfirm = (id) => async () => {

        Modal.confirm({
            title: '赔付确认',
            icon: <ExclamationCircleOutlined />,
            content: '该条记录已赔付？',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                await apiPayUpdate({ id });

                message.success("已更新赔付状态");

                pageChange(pageIndex);
            },
            // onCancel() {
            // },
        });

    };

    return <div className="page-coupon-settings">
        <Header>
            <div className="flex items-center justify-between">
                <Breadcrumb>
                    <Breadcrumb.Item href="/#/">
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/#/">
                        <HistoryOutlined />
                        <span style={{ marginLeft: 8 }}>赔付记录管理</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>

        </Header>

        <div className="content-panel-common">

            <div className="filter-pannel-common">
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                >
                    <Row gutter={24} >

                        <Col span={8}>
                            <Form.Item
                                name="type"
                                label="拍牌周期"
                                initialValue="all"
                            >
                                <Select placeholder="请选择拍牌周期" onChange={() => {
                                    pageChange(1)
                                }}>
                                    <Option value="all">全部</Option>
                                    <Option value="周期一">周期一</Option>
                                    <Option value="周期二">周期二</Option>
                                    <Option value="周期三">周期三</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="payStatus"
                                label="状态"
                                initialValue="all"
                            >
                                <Select placeholder="请选择赔付状态" onChange={() => {
                                    pageChange(1)
                                }}>
                                    <Option value="all">全部</Option>
                                    <Option value="00">待申请</Option>
                                    <Option value="01">已申请</Option>
                                    <Option value="02">已赔付</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="orderId"
                                label="订单ID"
                            >
                                <Input placeholder="输入订单ID" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="piId"
                                label="赔付ID"
                            >
                                <Input placeholder="输入赔付ID" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="saleId"
                                label="销售"
                                initialValue="all"
                            >
                                <Select
                                    placeholder="请选择所属销售"
                                    allowClear
                                    onChange={() => {
                                        pageChange(1)
                                    }}>
                                    <Option value="all">全部</Option>
                                    {
                                        marketerList.map(marketer => <Option key={marketer.userId} value={marketer.userId}>
                                            <div className="flex justify-between" style={{ maxWidth: 180 }}>
                                                {marketer.realName} <span className="text-primary">{marketer.userName}</span>
                                            </div>
                                        </Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col md={8} lg={6} >
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                            <Button
                                style={{ marginLeft: 8 }}
                                onClick={() => {
                                    form.resetFields();
                                    // getBidList({
                                    //     pageIndex: 1,
                                    // });
                                }}
                            >
                                重置
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div style={{ marginTop: 10 }}>
                <Table
                    // loading={true}
                    pagination={{
                        defaultCurrent: 1,
                        current: pageIndex,
                        total,
                        showSizeChanger: false,
                        pageSize: 20,
                        onChange: pageChange,
                    }}
                    dataSource={tableData}
                // scroll={{ x: 1600 }}
                >
                    <Column
                        title="记录ID"
                        dataIndex="piId"
                        key="piId"
                    />
                    <Column title="订单ID" dataIndex="orderId" key="orderId" />
                    <Column title="金额" dataIndex="money" key="money" />
                    <Column title="客户昵称" dataIndex="nickName" key="nickName" />
                    {/*<Column title="账户" dataIndex="nickName" key="nickName" />*/}
                    <Column title="状态" dataIndex="payStatus" key="payStatus" render={(val, record) => <div>{['待申请', '已申请', '已赔付'][parseInt(record.payStatus)]}</div>} />
                    <Column title="申请时间" dataIndex="applyTime" key="applyTime" />
                    <Column title="账号类型" dataIndex="accountType" key="accountType" render={(val) => ({
                        alipay: '支付宝',
                        wechat: '微信',
                        bank: '银行卡',
                    }[val])} />
                    <Column title="账号" dataIndex="accountNo" key="accountNo" />
                    <Column title="销售" dataIndex="saleId" key="saleId" />
                    <Column title="支付时间" dataIndex="payTime" key="payTime" />
                    <Column title="创建时间" dataIndex="createTime" key="createTime" />
                    <Column
                        title="操作"
                        key="action"
                        width={180}
                        render={(text, record) => (
                            <Space size="middle">
                                {
                                    record.payStatus == '01' ? <Button type="primary" onClick={handlePayConfirm(record.piId)}>赔付确认</Button> : ''
                                }
                                {
                                    record.payStatus == '02' ? <span className="text-success" >已确认</span> : ''
                                }
                            </Space>
                        )}
                    />
                </Table>
            </div>
        </div>

    </div>;
};

export default PayList;
