
import React, { useEffect, useState } from 'react';

import './index.less';
import Header from "../../components/Header";
import { HomeOutlined, UserOutlined, HistoryOutlined, TeamOutlined, ProjectOutlined } from '@ant-design/icons';


import {Breadcrumb, Menu} from "antd";
import {usePageViews} from "../../App";

const Personal = () => {
    usePageViews();

    return <div className="page-home">
        <Header>
            <Breadcrumb>
                <Breadcrumb.Item href="/#/">
                    <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item href="">
                    <UserOutlined />
                    <span>个人中心页</span>
                </Breadcrumb.Item>
            </Breadcrumb>
        </Header>

        <div className="content-panel-common">
            个人中心页
        </div>
    </div>;
};

export default Personal;