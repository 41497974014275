// 系统设置接口
import $fetch from "./fetch";

// 新增标签
export const apiTagAdd = (
    {
        code,
        name,
    }
) => {
    return $fetch('/api/cms/tags', {
        method: 'POST',
        params: {
            code,
            name,
        }
    });
}


// 更新标签
export const apiTagUpdate = (
    {
        id,
        code,
        name,
    }
) => {
    return $fetch( `/api/cms/tags/${id}`, {
        method: 'PUT',
        params: {
            code,
            name,
        }
    });
}

// 删除标签
export const apiTagDel = (
    {
        id,
    }
) => {
    return $fetch( `/api/cms/tags/${id}`, {
        method: 'DELETE',
    });
}

// 查询标签列表
export const apiTagList = (
    {
        code,
        name,
        tagId,
    }
) => {
    return $fetch( '/api/cms/tags/page', {
        method: 'GET',
        params: {
            code,
            name,
            tagId,
            pageIndex: 1,
            pageSize: 100,
        },
        noMessage: true
    });
}
