export const SET_IS_TEST = 'SET_IS_TEST';

const INITIAL_STATE = false;

export default function isTest (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_IS_TEST:
      return action.payload;
    default:
      return state;
  }
}
