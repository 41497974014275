
import React, {useEffect, useState, Fragment} from 'react';

import {Button, Col, Switch, Form, DatePicker, Input, Layout, message, Modal, Row, Select, Space, Table} from 'antd';

import { withRouter } from "react-router";

import { Link, useLocation } from 'react-router-dom';

import moment from 'moment';

import 'moment/locale/zh-cn';

import locale from 'antd/es/date-picker/locale/zh_CN';

import { Breadcrumb, Typography } from 'antd';
import { HomeOutlined, CommentOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import SiderMenu from "../../components/SiderMenu";

import Header from "../../components/Header";

import logo from '../../logo.svg';

import './index.less';

import {usePageViews} from "../../App";

import imgCircle from '../../assets/imgs/circle.svg';
import imgCircleSpec from '../../assets/imgs/circle-spec.svg';
import {apiOverview} from "../../api";
import {apiRoleAdd, apiRoleDel, apiRoleUpdate} from "../../api/role";
import {apiQaAdd, apiQaDel, apiQaList, apiQaUpdate} from "../../api/qa";
import {apiMachineList} from "../../api/machine";
import {apiCommentDel, apiCommentList, apiCommentUpdate} from "../../api/comment";

const { Title } = Typography;

const { Column, ColumnGroup } = Table;

const { Option } = Select;

const CommentList = (props) => {

    const [form] = Form.useForm();

    const [formNew] = Form.useForm();

    const [pageIndex, setPageIndex] = useState(1);

    const [total, setTotal] = useState(0);

    const [tableData, setTableData] = useState([]);

    const [editId, setEditId] = useState('');

    const [modalVisible, setModalVisible] = useState(false);

    const [modalConfirmLoading, setModalConfirmLoading] = useState(false);

    // const [editId, setEditId] = useState('');

    useEffect(() => {
        getCommentList({
            pageIndex: 1,
        });
    }, []);

    useEffect(() => {
        if (!editId) {
            return;
        }

        const {orderNum, hot, content, star, createTime} = tableData.find(item => item.ocId == editId);

        formNew.setFieldsValue({
            orderNum,
            hot,
            content,
            star,
            createTime: moment(createTime, 'YYYY-MM-DD HH:mm:ss')
        });

    }, [editId]);

    const onFinish = ({ }) => {
        getCommentList({
            pageIndex: 1,
        });
    };

    const pageChange = (page) => {

        getCommentList({
            pageIndex: page,
        });
    };

    const getCommentList = async (
        {
            pageIndex = 1,
        }
    ) => {
        const res = await apiCommentList({
            pageIndex,
        });

        console.log(res);

        setPageIndex(res.pageIndex);
        setTotal(res.total);

        setTableData(res.records.map((item, index) => ({...item, key: index})));
    };

    const handleModalOk = async () => {
        try {
            const {
                content,
                createTime,
                hot,
                orderNum,
                star,
            } = await formNew.validateFields();

            setModalConfirmLoading(true);

            await apiCommentUpdate({
                id: editId,
                content,
                createTime: createTime.format('YYYY-MM-DD HH:mm:ss'),
                hot,
                orderNum,
                star,
            });

            pageChange(1);

            formNew.resetFields();
            setModalVisible(false);
            setModalConfirmLoading(false);

            message.success(`评论更新成功`);

            setEditId(null);

        } catch (e) {
            message.error(e.message);
            setModalConfirmLoading(false);
        }
    };

    const handleModalCancel = () => {
        setEditId(null);
        setModalVisible(false);
        formNew.resetFields();
    };

    const handleEditBtnClick = (id) => async () => {
        console.log(id);

        setEditId(id);

        setModalVisible(true);
    };

    const handleDelBtnClick = (id) => async () => {

        Modal.confirm({
            title: '删除评论',
            icon: <ExclamationCircleOutlined />,
            content: '是否要删除该评论?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                await apiCommentDel({id});

                message.success("评论已删除");

                pageChange(pageIndex);
            },
            // onCancel() {
            // },
        });

    };

    return <div className="page-coupon-settings">
        <Header>
            <div className="flex items-center justify-between">
                <Breadcrumb>
                    <Breadcrumb.Item href="/#/">
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/#/">
                        <CommentOutlined />
                        <span style={{marginLeft: 8}}>评论管理</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>

        </Header>

        <div className="content-panel-common">
            <div style={{marginTop: 10}}>
                <Table
                    // loading={true}
                    pagination={{
                        defaultCurrent: 1,
                        current: pageIndex,
                        total,
                        showSizeChanger: false,
                        pageSize: 20,
                        onChange: pageChange,
                    }}
                    dataSource={tableData}
                    // scroll={{ x: 1600 }}
                >
                    <Column
                        title="内容"
                        dataIndex="content"
                        key="content"
                        width={220}
                    />
                    <Column width={120} title="昵称" dataIndex="nickName" key="nickName" />
                    <Column title="打星" width={60} dataIndex="star" key="star" />
                    <Column title="热门" width={60} dataIndex="hot" key="hot" render={(hot) => hot ? <span className="text-error">hot</span> : ''} />
                    <Column title="排序" width={60} dataIndex="orderNum" key="orderNum" />
                    <Column title="创建时间" width={180} dataIndex="createTime" key="createTime" />
                    <Column
                        title="操作"
                        key="action"
                        width={160}
                        render={(text, record) => (
                            <Space size="middle">
                                <span className="text-primary pointer" onClick={handleEditBtnClick(record.ocId)}>编辑</span>
                                <span className="text-error pointer" onClick={handleDelBtnClick(record.ocId)}>删除</span>
                            </Space>
                        )}
                    />
                </Table>
            </div>
        </div>


        <Modal
            title="评论编辑"
            visible={modalVisible}
            onOk={handleModalOk}
            confirmLoading={modalConfirmLoading}
            onCancel={handleModalCancel}
            okText="确定"
            cancelText="取消"
            maskClosable={false}
        >
            <Form
                form={formNew}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 17 }}
                name="basic"
            >
                <Form.Item
                    label="内容"
                    name="content"
                    rules={[{ required: true, message: '请输入评论内容' }]}
                >
                    <Input.TextArea rows={3} maxLength={50} placeholder="请输入评论内容" />
                </Form.Item>

                <Form.Item
                    label="打星"
                    name="star"
                    rules={[{ required: true, message: '请选择星数' }]}
                >
                    <Select placeholder="请选择星数" onChange={() => {
                    }}>
                        <Option value={1}>1星</Option>
                        <Option value={2}>2星</Option>
                        <Option value={3}>3星</Option>
                        <Option value={4}>4星</Option>
                        <Option value={5}>5星</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="热门"
                    name="hot"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>

                <Form.Item
                    label="排序"
                    name="orderNum"
                    rules={[{ required: true, message: '请输入排序数字' }]}
                >
                    <Input type="number" maxLength={6} placeholder="请输入排序数字" />
                </Form.Item>

                <Form.Item
                    label="创建时间"
                    name="createTime"
                    rules={[{ required: true, message: '请选择时间' }]}
                >
                    <DatePicker locale={locale} showTime placeholder="请选择时间" />
                </Form.Item>
            </Form>
        </Modal>

    </div>;
};

export default CommentList;
