import React, {useEffect, useState, Fragment} from 'react';

import { Layout } from 'antd';

import { withRouter } from "react-router";

import { Link, useLocation } from 'react-router-dom';

import { Breadcrumb, Typography } from 'antd';
import { HomeOutlined, WindowsOutlined, NodeExpandOutlined, FileTextOutlined } from '@ant-design/icons';

import SiderMenu from "../../components/SiderMenu";

import Header from "../../components/Header";

import logo from '../../logo.svg';

import './index.less';

import {usePageViews} from "../../App";

import imgCircle from '../../assets/imgs/circle.svg';
import imgCircleSpec from '../../assets/imgs/circle-spec.svg';
import {apiOverview} from "../../api";

const { Title } = Typography;

// bidAssignedSize: 3               绑定机器的标书
// bidTotalSize: 3                  标书总数
// machineTotalCapacity: 16         机器总容量
// machineTotalSize: 4              机器的数量
// proxyAssignedCapacity: 3         代理使用量
// proxyTotalCapacity: 4            代理总容量
// proxyTotalSize: 4                代理总数量

const Home = (props) => {

    // 未登录
    useEffect(() => {
        if (!localStorage.getItem('userInfo')) {
            props.history.push('/login');
        }
    }, []);

    return <div className="page-home">
        <Header>
            <Breadcrumb>
                <Breadcrumb.Item href="/#/">
                    <HomeOutlined />
                    <span style={{marginLeft: 8}}>首页</span>
                </Breadcrumb.Item>
                {/*<Breadcrumb.Item href="">*/}
                {/*    <UserOutlined />*/}
                {/*    <span>Application List</span>*/}
                {/*</Breadcrumb.Item>*/}
                {/*<Breadcrumb.Item>Application</Breadcrumb.Item>*/}
            </Breadcrumb>
        </Header>

        <div className="content-panel-common">
            首页
            <a className="test" href="http://www.baidu.com"></a>
        </div>
    </div>;
};

export default withRouter(Home);
