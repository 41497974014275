// QA管理接口
import $fetch from "./fetch";

// 新增 QA
export const apiQaAdd = (
    {
        answer,
        orderNum,
        qaType,
        question,
    }
) => {
    return $fetch('/api/cms/qa', {
        method: 'POST',
        params: {
            answer,
            orderNum: orderNum || 0,
            qaType,
            question,
        }
    });
}

// 更新 QA
export const apiQaUpdate = (
    {
        id,
        answer,
        orderNum,
        qaType,
        question,
    }
) => {
    return $fetch(`/api/cms/qa/${id}`, {
        method: 'PUT',
        params: {
            answer,
            orderNum: orderNum || 0,
            qaType,
            question,
        }
    });
}

// 删除 QA
export const apiQaDel = (
    {
        id,
    }
) => {
    return $fetch(`/api/cms/qa/${id}`, {
        method: 'DELETE',
    });
}

// QA 列表
export const apiQaList = (
    {
        answer,
        type,
        question,
        pageIndex,
        pageSize = 10,
    } = {
        pageIndex: 1,
        pageSize: 10,
    }
) => {
    return $fetch('/api/cms/qa/page', {
        method: 'GET',
        params: {
            answer,
            type,
            question,
            pageIndex,
            pageSize: pageSize || 10,
        }
    });
}