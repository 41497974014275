
import React, { useEffect, useState } from 'react';

import { Form, Row, Col, Input, Button, Table, Spin, Tag, Switch, Space, Radio, Checkbox, Breadcrumb, Menu, Modal, Upload, Select, message } from 'antd';

import './index.less';
import Header from "../../components/Header";
import { HomeOutlined, UserOutlined, PlusOutlined, ExclamationCircleOutlined, SendOutlined, } from '@ant-design/icons';
import { usePageViews } from "../../App";
import { apiPartnerAdd, apiPartnerDel, apiPartnerList, apiPartnerUpdate } from "../../api/partner";
import { apiBidAdd, apiBidDel, apiBidDELETE, apiBidUpdate } from "../../api/bid";
import { Link } from "react-router-dom";
import { apiUserAdd, apiUserList, apiUserUpdate } from "../../api/user";
import {
    apiCustomerAssDel,
    apiCustomerAssLc,
    apiCustomerAssociation,
    apiCustomerLatentSearch,
    apiCustomerList,
    apiCustomerSaleAdd,
    apiCustomerSaleDel, apiCustomerSupply,

} from "../../api/customer";
import { apiComboSpecial } from "../../api/combo"
import { apiOrdersEmployee } from "../../api/order";
import user from "../../store/reducers/user";
// import {apiWorkerList} from "../../api";

const { Column, ColumnGroup } = Table;

const { Option } = Select;

const CustomerList = () => {

    usePageViews();

    const [tableData, setTableData] = useState([]);

    const [expand, setExpand] = useState(false);

    const [form] = Form.useForm();

    const [formCookie] = Form.useForm();

    const [formNew] = Form.useForm();

    const [editId, setEditId] = useState('');

    const [pageIndex, setPageIndex] = useState(1);

    const [total, setTotal] = useState(0);

    const [modalVisible, setModalVisible] = useState(false);

    const [modalConfirmLoading, setModalConfirmLoading] = useState(false);

    const [customerId, setCustomerId] = useState(null);
    
    const [specialCustomerId, setSpecialCustomerId] = useState(null);

    const [selectList, setSelectList] = useState([]);
    const [selectId, setSelectId] = useState('');
    const [fetching, setFetching] = useState(false);

    const [customerSpecId, setCustomerSpecId] = useState(null);

    const [marketerList, setMarketerList] = useState([]);

    const [userModalShow, setUserModalShow] = useState(false);

    const [cookieText, setCookieText] = useState('');

    const [employeeList, setEmployeeList] = useState([]);

    useEffect(() => {
        apiOrdersEmployee().then(data => setEmployeeList(data));

        getCustomerList({
            name: '',
            pageIndex: 1,
            customerId: window.localStorage.getItem('customerId') || undefined,
        });

        getMarketerList();
    }, []);

    useEffect(() => {

        if (!editId) {
            return;
        }

        const { customerName, realName, mobile } = tableData.find(item => item.customerId == editId);

        formNew.setFieldsValue({
            name: realName,
            alias: customerName,
            phone: mobile
        });

    }, [editId]);

    useEffect(() => {

        setSelectId('');

        if (customerId) {
            (async () => {
                const res = await apiCustomerAssociation({ id: customerId });

                // console.log(res);

                const resFinal = res.filter(item => !!item);

                setSelectList(resFinal || []);

            })();
        } else {
            setSelectList([]);
        }
    }, [customerId]);

    const getCustomerList = async ({
        customerId,
        customerName,
        saleName,
        onlyUnbind,
        pageIndex = 1,
    }) => {
        const res = await apiCustomerList({
            customerId,
            customerName,
            saleName,
            onlyUnbind,
            pageIndex,
            pageSize: 20
        });

        window.localStorage.removeItem('customerId');

        setPageIndex(res.pageIndex);
        setTotal(res.total);

        setTableData(res.records.map((item, index) => ({ ...item, key: index })));
    };

    const getMarketerList = async () => {
        const res = await apiUserList({ roleName: 'sale', pageSize: 100 });

        // .filter(item => item.enabled == '1')
        setMarketerList(res.records);
    };

    const onFinish = ({ saleName = '', customerName = '', onlyUnbind = false }) => {
        console.log(onlyUnbind);
        getCustomerList({
            customerName: customerName.trim(),
            saleName: saleName.trim(),
            onlyUnbind,
            pageIndex: 1,
        });
    };

    const pageChange = (page) => {
        const customerName = form.getFieldValue('customerName') || '';
        const saleName = form.getFieldValue('saleName') || '';

        const onlyUnbind = form.getFieldValue('onlyUnbind') || false;

        getCustomerList({
            customerName: customerName.trim(),
            saleName: saleName.trim(),
            onlyUnbind,
            pageIndex: page,
        });
    };

    const handleModalOk = async () => {
        try {
            const { name, alias, phone } = await formNew.validateFields();

            setModalConfirmLoading(true);

            if (editId) {
                await apiUserUpdate({
                    id: editId,
                    mobile: phone,
                    realName: name,
                    customerName: alias,
                });
            } else {
                await apiUserAdd({
                    mobile: phone,
                    realName: name,
                    customerName: alias,
                });
            }

            formNew.resetFields();
            setModalVisible(false);
            setModalConfirmLoading(false);

            message.success(`用户${editId ? '更新' : '添加'}成功`);

            setEditId(null);

            getCustomerList({
                pageIndex: 1,
            });

        } catch (e) {
            message.error(e.message);
            setModalConfirmLoading(false);
        }
    };

    const handleModalCancel = () => {
        setEditId(null);
        setModalVisible(false);
        formNew.resetFields();
    };

    const handleBtnNewClick = () => {
        setModalVisible(true);
    };

    const handleEditBtnClick = (id) => async () => {
        console.log(id);

        // const res = await apiBidUpdate()
        //

        setEditId(id);

        setModalVisible(true);
    };

    const handleDelBtnClick = (id) => async () => {

        Modal.confirm({
            title: '删除友商',
            icon: <ExclamationCircleOutlined />,
            content: '是否要删除该友商?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                await apiPartnerDel({ id });

                message.success("友商已删除");

                pageChange(pageIndex);
            },
            // onCancel() {
            // },
        });

    };
    
    const handleCustomerSpecial = async () => {
        try {
            const {
                packageId,
                discount,
                payoff,
            } = await formNew.validateFields();

            setModalConfirmLoading(true);

            await apiComboSpecial({
                customerId: specialCustomerId,
                packageId,
                discount,
                payoff,
            });

            formNew.resetFields();
            
            setModalConfirmLoading(false);

            message.success(`定制套餐成功`);
            setSpecialCustomerId('');
            
            pageChange(pageIndex);
        } catch (e) {
            message.error(e.message);
            setModalConfirmLoading(false);
        }
    };

    const handleCustomerRelate = async () => {
        console.log(selectId);

        if (selectId) {
            setModalConfirmLoading(true);

            try {
                await apiCustomerAssLc({
                    customerId,
                    lcId: selectId,
                });

                setModalConfirmLoading(false);

                message.success('潜在客户关联成功');

                setCustomerId('');
                setSelectId('');

                pageChange(pageIndex);
            } catch (e) {
                setModalConfirmLoading(false);
            }

        } else {
            message.error('请选择关联的潜在客户');
        }
    };

    const fetchLatent = async (val) => {
        setSelectList([]);
        setFetching(true);

        try {
            const res = await apiCustomerLatentSearch({
                pageIndex: 1,
                condition: val,
            });

            setSelectList(res.records);

            setFetching(false);
        } catch (e) {
            setFetching(false);
        }

    };

    const handleSaleSelectChange = (id) => async (salerId) => {

        const res = await apiCustomerSaleAdd({
            id,
            salerId,
        });

        message.success('售后关联成功');

        const index = tableData.findIndex(item => item.customerId === id);

        setTableData(prevState => {
            const arr = [...prevState];

            arr.splice(index, 1, {
                ...prevState[index],
                salerId,
            });

            return arr;
        });

    };

    const handleSaleDel = (id) => async () => {
        await apiCustomerSaleDel({ id });

        message.success('售后已删除');

        const index = tableData.findIndex(item => item.customerId === id);

        setTableData(prevState => {
            const arr = [...prevState];

            arr.splice(index, 1, {
                ...prevState[index],
                salerId: null,
                maintained: false,
            });

            return arr;
        });
    };

    const handleCustomerSupply = () => {

        formCookie.validateFields().then(async ({ token, cookie }) => {

            const res = await apiCustomerSupply({
                token: token.trim(),
                cookie: cookie.trim(),
            });

            if (res) {
                message.success(`登录凭证更新成功`, 3);

                setUserModalShow(false);

                formCookie.resetFields();

                getCustomerList({ pageIndex: 1 });
            } else {
                message.error('登录凭证设置无效');
            }
        });

    };

    const handleAddToMantainer = (id, salerId) => async () => {
        console.log(id, salerId);

        const res = await apiCustomerSaleAdd({
            id,
            salerId,
            maintained: true,
        });

        message.success('售后关联成功');

        const index = tableData.findIndex(item => item.customerId === id);

        console.log(tableData);

        setTableData(prevState => {
            const arr = [...prevState];

            arr.splice(index, 1, {
                ...prevState[index],
                salerId,
                maintained: true,
            });

            console.log(arr);

            return arr;
        });


    };

    const handleSwitchStatus = (id) => async (maintained) => {
        console.log(id, maintained);

        const res = await apiCustomerSaleAdd({
            id,
            maintained,
        });

        message.success('状态切换成功');

        const index = tableData.findIndex(item => item.customerId === id);

        setTableData(prevState => {
            const arr = [...prevState];

            arr.splice(index, 1, {
                ...prevState[index],
                maintained,
            });

            return arr;
        });


    }

    return <div className="page-customer-list">
        <Header>
            <div className="flex items-center justify-between">
                <Breadcrumb>
                    <Breadcrumb.Item href="/#/">
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="">
                        <UserOutlined />
                        <span>客户管理</span>
                    </Breadcrumb.Item>
                </Breadcrumb>

                <Button
                    type="primary"
                    icon={<SendOutlined />}
                    onClick={() => {
                        setUserModalShow(true)
                    }}
                >上传登录凭证</Button>
            </div>

        </Header>

        <Modal
            visible={userModalShow}
            okText="提交"
            cancelText="取消"
            title="上传登录凭证"
            onOk={handleCustomerSupply}
            onCancel={
                () => {
                    setUserModalShow(false);
                    setCookieText('');
                }
            }
        >
            <Form
                form={formCookie}
                name="advanced_search"
                className="ant-advanced-search-form"
                labelCol={{ span: 4 }}
                autoComplete="off"
            >
                <Form.Item
                    name="token"
                    label="token"
                    rules={[{ required: true, message: '请输入 token' }]}
                >
                    <Input type="text" placeholder='粘贴 token 信息' />
                </Form.Item>

                <Form.Item
                    name="cookie"
                    label="cookie"
                    rules={[{ required: true, message: '请输入 cookie' }]}
                >
                    <Input.TextArea rows={6} placeholder='粘贴 cookie 信息' />
                </Form.Item>
            </Form>
        </Modal>

        <div className="content-panel-common">
            <div className="filter-pannel-common">
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                name="customerName"
                                label="客户昵称"
                            >
                                <Input placeholder="输入客户昵称" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            {/* <Form.Item
                                name="saleName"
                                label="所属销售"
                            >
                                <Input placeholder="所属销售" />
                            </Form.Item> */}
                            <Form.Item
                                name="saleName"
                                label="所属销售"
                            >
                                <Select className="w-100" placeholder="选择销售" allowClear={true}>
                                    {
                                        employeeList.map(it => <Option key={it} value={it} >{it}</Option>)
                                    }

                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                name="onlyUnbind"
                                valuePropName="checked"
                            >
                                <Checkbox>仅显示未关联客户</Checkbox>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                            <Button
                                style={{ marginLeft: 8 }}
                                onClick={() => {
                                    form.resetFields();
                                    getCustomerList({ pageIndex: 1 });
                                }}
                            >
                                重置
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div >
                <Table
                    // loading={true}
                    pagination={{
                        defaultCurrent: 1,
                        current: pageIndex,
                        total,
                        showSizeChanger: false,
                        pageSize: 20,
                        onChange: pageChange,
                    }}
                    dataSource={tableData}
                    scroll={{ x: 1300 }}
                >
                    <Column title="客户ID" width={160} dataIndex="customerId" key="customerId" />
                    <Column title="昵称" width={90} dataIndex="nickName" key="nickName" />
                    <Column title="头像" width={60} dataIndex="avatarUrl" key="avatarUrl" render={(avatarUrl) => <div
                        className="avatar"
                        style={{ backgroundImage: `url('${avatarUrl}')` }}
                    ></div>
                    } />

                    {/*<Column width={140} title="openId" dataIndex="openId" key="openId" />*/}
                    {/*<Column*/}
                    {/*    title="用户头像"*/}
                    {/*    dataIndex="avatarUrl"*/}
                    {/*    render={*/}
                    {/*        (text, record) => (<img src={text} width={80} />)*/}
                    {/*    }*/}
                    {/*/>*/}

                    <Column width={140} title="潜在客户" dataIndex="latentPhoneCode" key="latentPhoneCode" render={
                        (val, record) => val ? <div>
                            <div>电话编号：{record.latentPhoneCode}</div>
                            <div>昵称：{record.latentNickName}</div>
                            <div>姓名：{record.latentName}</div>
                            <div>微信号：{record.latentWechatNo}</div>
                            <div
                                className="text pointer text-error"
                                style={{ width: 'fit-content' }}
                                onClick={
                                    async () => {
                                        await apiCustomerAssDel({ id: record.customerId });
                                        message.success('关联已删除');
                                        pageChange(pageIndex);
                                    }
                                }
                            >删除关联</div>
                        </div> : <Button size="small" onClick={
                            () => {
                                setCustomerId(record.customerId)
                            }
                        }>关联</Button>
                    }
                    />
                    <Column title="特殊套餐" width={100} dataIndex="specialPackage" key="specialPackage" render={
                        (val, record) => <div>
                            {val.split('\n').map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                            <Button size="small" onClick={
                                () => {
                                    setSpecialCustomerId(record.customerId)
                                }
                            }>定制特殊套餐</Button>
                        </div>
                    }
                    />
                    <Column width={100} title="所属销售" dataIndex="realName" key="realName" render={(text, record) => (marketerList.find(_marketer => _marketer.realName === text) || {}).maintainerId > 0 ? <div><del className="text-error">{text}{record.userName ? `(${record.userName})` : ''}</del>{record.salerId ? '' : <div className="pointer text-primary" style={{ width: 'fit-content' }} onClick={handleAddToMantainer(record.customerId, (marketerList.find(_marketer => _marketer.realName === text) || {}).maintainerId)}>让【{(marketerList.find(_marketer => _marketer.realName === text) || {}).maintainerName}】维护</div>}</div> : <>
                    {text}<div className='text-primary'>{record.userName || null}</div>
                    </>} />

                    <Column width={120} title="售后服务" dataIndex="salerId" key="salerId" render={(val, record) =>
                        <>
                            <Select placeholder="关联售后" value={record.salerId} style={{ width: 200 }}
                                onChange={handleSaleSelectChange(record.customerId)}>
                                {
                                    marketerList.filter(item => item.enabled == '1').map(marketer => <Option key={marketer.userId}
                                        value={marketer.userId}><div className="flex justify-between">{marketer.realName || marketer.userName}<span className="text-primary">{marketer.userName ? marketer.userName : ''}</span></div></Option>)
                                }
                            </Select>

                            {
                                val ? <div className="text-error pointer" style={{ marginTop: 10, width: 'fit-content' }} onClick={handleSaleDel(record.customerId)} >删除关联</div> : ''
                            }
                        </>
                    }
                    />

                    <Column width={180} title="创建时间" dataIndex="createTime" key="createTime" />

                    {/*<Column title="中标数" dataIndex="totalHit" key="totalHit" />*/}

                    {/*<Column*/}
                    {/*    title="操作"*/}
                    {/*    key="action"*/}
                    {/*    fixed="right"*/}
                    {/*    width={130}*/}
                    {/*    render={(text, record) => (*/}
                    {/*        <Space size="middle">*/}
                    {/*            /!*<p className="pointer" onClick={handleEditBtnClick(record.id)}>沟通记录</p>*!/*/}
                    {/*            /!*<p className="text-primary pointer" onClick={handleEditBtnClick(record.customerId)}>编辑</p>*!/*/}
                    {/*            /!*<p className="text-error pointer" >禁用</p>*!/*/}
                    {/*            /!*<p className="text-error pointer" onClick={handleDelBtnClick(record.id)}>删除</p>*!/*/}
                    {/*        </Space>*/}
                    {/*    )}*/}
                    {/*/>*/}
                </Table>
            </div>
        </div>


        <Modal
            title="关联潜在客户"
            visible={!!customerId}
            okText="确定"
            cancelText="取消"
            maskClosable={false}
            onOk={handleCustomerRelate}
            confirmLoading={modalConfirmLoading}
            onCancel={() => {
                setCustomerId('')
            }}
        >
            <Select
                style={{ width: '100%' }}
                value={selectId}
                showSearch={true}
                placeholder="可输入昵称或phoneCode检索"
                notFoundContent={fetching ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={fetchLatent}
                onChange={(val) => {
                    setSelectId(val);
                    setSelectList([]);
                    setFetching(false);
                }}
            >
                {
                    selectList.map((item, index) => <Option key={index} value={item.lcId}>
                        ID: {item.lcId} 编号: {item.phoneCode} 昵称: {item.nickName || '--'}
                    </Option>)
                }
            </Select>
        </Modal>

        <Modal
            title="定制特殊套餐"
            visible={!!specialCustomerId}
            okText="定制"
            cancelText="取消"
            maskClosable={false}
            onOk={handleCustomerSpecial}
            confirmLoading={modalConfirmLoading}
            onCancel={() => {
                setSpecialCustomerId('')
            }}
        >
            <Form
                form={formNew}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 17 }}
                name="basic"
            >
                <Form.Item
                    label="套餐编号"
                    name="packageId"
                    rules={[{ required: true, message: '请输入套餐编号' }]}
                >
                    <Input type="number" maxLength={50} placeholder="请输入套餐编号" />
                </Form.Item>

                <Form.Item
                    label="折扣力度"
                    name="discount"
                    rules={[{ required: true, message: '请输入折扣力度' }]}
                >
                    <Input type="number" maxLength={50} placeholder="请输入折扣力度" />
                </Form.Item>

                <Form.Item
                    label="额外赔付"
                    name="payoff"
                    rules={[{ required: true, message: '请输入额外赔付' }]}
                >
                    <Input type="number" maxLength={50} placeholder="请输入额外赔付" />
                </Form.Item>
            </Form>
        </Modal>

    </div>;
};

export default CustomerList;