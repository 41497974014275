
import React, { useEffect, useState } from 'react';

import './index.less';
import Header from "../../components/Header";
import { HomeOutlined, PlusOutlined, SettingOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {Button, Input, Modal} from 'antd';

import {
    Breadcrumb,
    Form, message,
    Switch,
} from "antd";

import {usePageViews} from "../../App";
import {apiUserAdd, apiUserUpdate} from "../../api/user";
import {apiTagAdd, apiTagDel, apiTagList, apiTagUpdate} from "../../api/settings";
import {apiPartnerDel} from "../../api/partner";
// import {apiGetAutoDistribute, apiSetAutoDistribute} from "../../api";

const Settings = () => {
    usePageViews();

    const [editTag, setEditTag] = useState(null);

    const [modalVisible, setModalVisible] = useState(false);

    const [isTestMode, setIsTestMode] = useState(false);

    const [formNew] = Form.useForm();

    const [modalConfirmLoading, setModalConfirmLoading] = useState(false);

    const [tagList, setTagList] = useState([]);

    const [userTagList, setUserTagList] = useState([]);

    const [editUserTag, setEditUserTag] = useState(null);

    const [isUser, setIsUser] = useState(false);

    useEffect(() => {
        getTags();
        getTags(true);
        getSettings();
    }, []);

    useEffect(() => {

        if (!editTag && !editUserTag) {
            return;
        }

        formNew.setFieldsValue({
            name: (editTag || editUserTag).name,
        });

    }, [editTag, editUserTag]);

    const getTags = async (isUser) => {
        const res = await apiTagList({code: isUser ? 'phone' : 'latent'});

        console.log(res);

        isUser ? setUserTagList(res.records) : setTagList(res.records);
    };

    const getSettings = async () => {
        // const res = await apiGetAutoDistribute();
        // setIsTestMode(!!res.data.state);
    }

    const handleTestModeToggle = async (status) => {
        // const res = await apiSetAutoDistribute(status ? 1 : 0);
        //
        // message.success(`已${status ? '开启' : '关闭'}测试模式`);
        //
        // setIsTestMode(status);
    };

    const handleModalOk = async () => {
        try {
            const { name } = await formNew.validateFields();

            setModalConfirmLoading(true);

            if (isUser) {
                if (editUserTag) {
                    await apiTagUpdate({
                        id: editUserTag.tagId,
                        name,
                        code: 'phone',
                    });
                } else {
                    await apiTagAdd({
                        name,
                        code: 'phone',
                    });
                }
            } else {
                if (editTag) {
                    await apiTagUpdate({
                        id: editTag.tagId,
                        name,
                        code: 'latent',
                    });
                } else {
                    await apiTagAdd({
                        name,
                        code: 'latent',
                    });
                }
            }

            formNew.resetFields();
            setModalVisible(false);
            setModalConfirmLoading(false);

            message.success(`${editTag || editUserTag ? '更新' : '添加'}成功`);

            setEditTag(null);

            setEditUserTag(null);

            // 获取标签列表
            getTags(isUser);

        } catch (e) {
            message.error(e.message);
            setModalConfirmLoading(false);
        }
    };

    const handleModalCancel = () => {
        setEditTag(null);

        setEditUserTag(null);

        setIsUser(false);

        setModalVisible(false);
        formNew.resetFields();
    };

    return <div className="page-settings">
        <Header>
            <Breadcrumb>
                <Breadcrumb.Item href="/#/">
                    <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item href="">
                    <SettingOutlined />
                    <span>系统配置</span>
                </Breadcrumb.Item>
            </Breadcrumb>
        </Header>

        <div className="content-panel-common">

            {/*code: "latent"*/}
            {/*createTime: "2020-11-12 14:14:31"*/}
            {/*name: "标签测试"*/}
            {/*tagId: "1326770329675714562"*/}
            {/*updateTime: "2020-11-12 14:14:31"*/}

            <div className="title">标签配置</div>

            <div className="flex flex-wrap" style={{padding: 10}}>
                <Button type="primary" className="btn-add-tag" onClick={() => {
                    setModalVisible(true);
                    setEditTag(null);
                }}><PlusOutlined />新增标签</Button>

                {
                    tagList.map(tagItem => <div key={tagItem.tagId} className="tag" onClick={() => {
                        setEditTag(tagItem);
                        setModalVisible(true);
                    }}>{tagItem.name}<CloseOutlined className="btn-close" onClick={(e) => {
                        e.stopPropagation();

                        Modal.confirm({
                            title: '删除标签',
                            icon: <ExclamationCircleOutlined />,
                            content: '是否要删除该标签?',
                            okText: '确定',
                            okType: 'danger',
                            cancelText: '取消',
                            async onOk() {
                                await apiTagDel({id: tagItem.tagId});

                                message.success("标签已删除");

                                getTags();
                            },
                            // onCancel() {
                            // },
                        });

                    }} /></div>)
                }

            </div>

            <div className="title">潜在客户来源配置</div>

            <div className="flex flex-wrap" style={{padding: 10}}>
                <Button type="primary" className="btn-add-tag" onClick={() => {
                    setModalVisible(true);
                    setEditUserTag(null);
                    setIsUser(true);
                }}><PlusOutlined />新增来源</Button>

                {
                    userTagList.map(tagItem => <div key={tagItem.tagId} className="tag" onClick={() => {
                        setEditUserTag(tagItem);
                        setModalVisible(true);

                        setIsUser(true);
                    }}>{tagItem.name}<CloseOutlined className="btn-close" onClick={(e) => {
                        e.stopPropagation();

                        Modal.confirm({
                            title: '删除来源',
                            icon: <ExclamationCircleOutlined />,
                            content: '是否要删除该来源?',
                            okText: '确定',
                            okType: 'danger',
                            cancelText: '取消',
                            async onOk() {
                                await apiTagDel({id: tagItem.tagId});

                                message.success("来源已删除");

                                getTags(true);
                            },
                            // onCancel() {
                            // },
                        });

                    }} /></div>)
                }

            </div>
        </div>

        <Modal
            title={editTag || editUserTag ? (editTag ? '标签编辑' : '来源编辑') : '新增标签'}
            visible={modalVisible}
            onOk={handleModalOk}
            confirmLoading={modalConfirmLoading}
            onCancel={handleModalCancel}
            okText="确定"
            cancelText="取消"
            maskClosable={false}
        >
            <Form
                form={formNew}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 17 }}
                name="basic"
                // onFinish={onFinish}
            >
                <Form.Item
                    label={editTag ? "标签名" : '来源'}
                    name="name"
                    rules={[{ required: true, message: `请输入${editTag ? "标签名" : '来源'}` }]}
                >
                    <Input maxLength={512} placeholder={`请输入${editTag ? "标签名" : '来源'}`} autoComplete='off' />
                </Form.Item>

                {/*<Form.Item*/}
                {/*    label="备注"*/}
                {/*    name="note"*/}
                {/*>*/}
                {/*    <Input maxLength={20} placeholder="请输入标签备注" />*/}
                {/*</Form.Item>*/}

            </Form>
        </Modal>

    </div>;
};

export default Settings;