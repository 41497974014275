import { combineReducers } from 'redux';
import token from './token';
import user from './user';
import isTest from './isTest';
import loading from './loading';

export default combineReducers({
  token,
  user,
  isTest,
  loading,
});
