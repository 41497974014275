// 赔付金管理
import $fetch from "./fetch";

// 赔付金记录列表
export const apiPayList = (
    {
        bidPeriod,
        orderId,
        payStatus,
        piId,
        saleId,
        pageIndex,
        pageSize = 20,
    } = {
        pageIndex: 1,
        pageSize: 20
    }
) => {
    console.log(saleId);
    return $fetch('/api/cms/paymentInst/page', {
        method: 'GET',
        params: {
            bidPeriod,
            orderId,
            payStatus,
            piId,
            saleId,
            pageIndex,
            pageSize,
        }
    });
}

// 修改赔付状态
export const apiPayUpdate = (
    {
        id,
    }
) => {
    return $fetch(`/api/cms/paymentInst/update/${id}`, {
        method: 'PUT',
    });
}
